/*
 * This file is generated by graphql-codegen. Do NOT edit directly.
 * Run `npm run graphql:caire-api` to regenerate.
 */
import * as ServiceTypes from '@/lib/graphql/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = { ssr: false, fetchPolicy: 'network-only' } as const;
export type CareJourneyTemplateSummaryFragment = {
  __typename?: 'CareJourneyTemplate';
  id: any;
  name: string;
  type: ServiceTypes.CareJourneyType;
  programId: any;
  updatedAt: any;
  draftVersion: {
    __typename?: 'CareJourneyTemplateVersion';
    id: any;
    touchpointTemplates: Array<{
      __typename?: 'SequentialTouchpointTemplate';
      id: any;
      updatedAt: any;
      name: string;
      position?: number | null;
      type?: ServiceTypes.TouchpointType | null;
      category: ServiceTypes.TouchpointCategory;
      openUponEnrollment: boolean;
      touchpointFrequency?: {
        __typename?: 'TouchpointCadence';
        numberOfRepeats?: number | null;
        frequency: {
          __typename?: 'Frequency';
          duration: ServiceTypes.FrequencyDuration;
          value: number;
        };
      } | null;
      taskFrequency?: {
        __typename?: 'TaskCadence';
        numberOfRepeats?: number | null;
        frequency: {
          __typename?: 'Frequency';
          duration: ServiceTypes.FrequencyDuration;
          value: number;
        };
      } | null;
      notificationConfig?: Array<{
        __typename?: 'TouchpointNotificationConfig';
        delay?: {
          __typename?: 'TouchpointNotificationDuration';
          value: number;
        } | null;
      }> | null;
    }>;
  };
};

export type EditableTouchpointDetailFragment = {
  __typename?: 'SequentialTouchpointTemplate';
  description?: string | null;
  reopenOnEventDateUpdate: boolean;
  confirmedMessage?: string | null;
  declinedMessage?: string | null;
  anchor?: ServiceTypes.AnchorType | null;
  anchorOffset?: number | null;
  anchorTouchpointTemplateId?: any | null;
  id: any;
  updatedAt: any;
  name: string;
  position?: number | null;
  type?: ServiceTypes.TouchpointType | null;
  category: ServiceTypes.TouchpointCategory;
  openUponEnrollment: boolean;
  touchpointFrequency?: {
    __typename?: 'TouchpointCadence';
    numberOfRepeats?: number | null;
    frequency: {
      __typename?: 'Frequency';
      duration: ServiceTypes.FrequencyDuration;
      value: number;
    };
  } | null;
  taskFrequency?: {
    __typename?: 'TaskCadence';
    numberOfRepeats?: number | null;
    frequency: {
      __typename?: 'Frequency';
      duration: ServiceTypes.FrequencyDuration;
      value: number;
    };
  } | null;
  taskConfig?: {
    __typename?: 'TouchpointTemplateTaskConfig';
    relatedObjectId?: string | null;
    taskDueDuration?: number | null;
    taskExpirationDuration?: number | null;
    eventDueDuration?: number | null;
  } | null;
  notificationConfig?: Array<{
    __typename?: 'TouchpointNotificationConfig';
    plainTextBody: string;
    plainTextRecurrenceBody?: string | null;
    delay?: {
      __typename?: 'TouchpointNotificationDuration';
      value: number;
      unit: ServiceTypes.DurationUnit;
    } | null;
  }> | null;
  rules?:
    | {
        __typename?: 'CompoundCondition';
        type: ServiceTypes.ConditionType;
        compound: ServiceTypes.CompoundOperator;
        operands: Array<
          | {
              __typename?: 'CompoundCondition';
              type: ServiceTypes.ConditionType;
              compound: ServiceTypes.CompoundOperator;
              operands: Array<
                | { __typename?: 'CompoundCondition' }
                | {
                    __typename?: 'SimpleCondition';
                    type: ServiceTypes.ConditionType;
                    simpleOperands: Array<any>;
                    simpleOperator: ServiceTypes.SimpleOperator;
                  }
              >;
            }
          | {
              __typename?: 'SimpleCondition';
              type: ServiceTypes.ConditionType;
              simpleOperands: Array<any>;
              simpleOperator: ServiceTypes.SimpleOperator;
            }
        >;
      }
    | {
        __typename?: 'SimpleCondition';
        type: ServiceTypes.ConditionType;
        simpleOperands: Array<any>;
        simpleOperator: ServiceTypes.SimpleOperator;
      }
    | null;
};

export type EditableTouchpointSummaryFragment = {
  __typename?: 'SequentialTouchpointTemplate';
  id: any;
  updatedAt: any;
  name: string;
  position?: number | null;
  type?: ServiceTypes.TouchpointType | null;
  category: ServiceTypes.TouchpointCategory;
  openUponEnrollment: boolean;
  touchpointFrequency?: {
    __typename?: 'TouchpointCadence';
    numberOfRepeats?: number | null;
    frequency: {
      __typename?: 'Frequency';
      duration: ServiceTypes.FrequencyDuration;
      value: number;
    };
  } | null;
  taskFrequency?: {
    __typename?: 'TaskCadence';
    numberOfRepeats?: number | null;
    frequency: {
      __typename?: 'Frequency';
      duration: ServiceTypes.FrequencyDuration;
      value: number;
    };
  } | null;
  notificationConfig?: Array<{
    __typename?: 'TouchpointNotificationConfig';
    delay?: {
      __typename?: 'TouchpointNotificationDuration';
      value: number;
    } | null;
  }> | null;
};

export type NotificationTemplateFragment = {
  __typename?: 'TouchpointNotificationConfig';
  plainTextBody: string;
  plainTextRecurrenceBody?: string | null;
  delay?: {
    __typename?: 'TouchpointNotificationDuration';
    unit: ServiceTypes.DurationUnit;
    value: number;
  } | null;
};

export type SimpleRuleFragment = {
  __typename?: 'SimpleCondition';
  type: ServiceTypes.ConditionType;
  simpleOperands: Array<any>;
  simpleOperator: ServiceTypes.SimpleOperator;
};

export type FinalCompoundRuleFragment = {
  __typename?: 'CompoundCondition';
  type: ServiceTypes.ConditionType;
  compound: ServiceTypes.CompoundOperator;
  operands: Array<
    | { __typename?: 'CompoundCondition' }
    | {
        __typename?: 'SimpleCondition';
        type: ServiceTypes.ConditionType;
        simpleOperands: Array<any>;
        simpleOperator: ServiceTypes.SimpleOperator;
      }
  >;
};

export type CompoundRuleFragment = {
  __typename?: 'CompoundCondition';
  type: ServiceTypes.ConditionType;
  compound: ServiceTypes.CompoundOperator;
  operands: Array<
    | {
        __typename?: 'CompoundCondition';
        type: ServiceTypes.ConditionType;
        compound: ServiceTypes.CompoundOperator;
        operands: Array<
          | { __typename?: 'CompoundCondition' }
          | {
              __typename?: 'SimpleCondition';
              type: ServiceTypes.ConditionType;
              simpleOperands: Array<any>;
              simpleOperator: ServiceTypes.SimpleOperator;
            }
        >;
      }
    | {
        __typename?: 'SimpleCondition';
        type: ServiceTypes.ConditionType;
        simpleOperands: Array<any>;
        simpleOperator: ServiceTypes.SimpleOperator;
      }
  >;
};

export type Icd10CodeFragment = {
  __typename?: 'ICD10Code';
  code?: string | null;
  name?: string | null;
};

export type IntegerObservationFragment = {
  __typename?: 'Observation';
  id: any;
  resource: {
    __typename?: 'ObservationResource';
    component?: Array<{
      __typename?: 'ObservationComponent';
      value?: { __typename?: 'Value'; valueInteger?: number | null } | null;
    }> | null;
  };
};

export type StringObservationFragment = {
  __typename?: 'Observation';
  id: any;
  resource: {
    __typename?: 'ObservationResource';
    component?: Array<{
      __typename?: 'ObservationComponent';
      value?: { __typename?: 'Value'; valueString?: string | null } | null;
    }> | null;
  };
};

export type CommonPatientFieldsFragment = {
  __typename?: 'Patient';
  id: any;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  birthDate: any;
  addresses?: Array<{
    __typename?: 'Address';
    city: string;
    state: ServiceTypes.StateCode;
    line1: string;
    line2?: string | null;
    postalCode: string;
  }> | null;
  phoneNumbers?: Array<{
    __typename?: 'PhoneNumber';
    use: ServiceTypes.ContactUse;
    value: string;
  }> | null;
  emailAddresses?: Array<{
    __typename?: 'EmailAddress';
    use: ServiceTypes.ContactUse;
    value: string;
  }> | null;
};

export type StaffNameFragment = {
  __typename?: 'Staff';
  id: any;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  role: ServiceTypes.StaffRole;
};

export type TouchpointDetailFragment = {
  __typename?: 'SequentialTouchpoint';
  id: any;
  labelDate?: any | null;
  dueAt?: any | null;
  completedAt?: any | null;
  updatedAt: any;
  position: number;
  note?: string | null;
  status: ServiceTypes.TouchpointStatus;
  endStateDate?: any | null;
  tasks?: Array<{
    __typename?: 'Task';
    resultObjectId?: any | null;
    taskDueAt: any;
  }> | null;
  sequentialTouchpointTemplate?: {
    __typename?: 'SequentialTouchpointTemplate';
    category: ServiceTypes.TouchpointCategory;
    type?: ServiceTypes.TouchpointType | null;
    name: string;
  } | null;
};

export type EncounterDetailFragment = {
  __typename?: 'Encounter';
  id: any;
  type: ServiceTypes.EncounterType;
  status: ServiceTypes.EncounterStatus;
  startedAt: any;
  updatedAt: any;
  clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
  staff: {
    __typename?: 'Staff';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    role: ServiceTypes.StaffRole;
  };
  appointment?: {
    __typename?: 'Appointment';
    id: any;
    appointmentTypeId: any;
    patientId: any;
    summary: string;
    description: string;
    startTime: any;
    endTime: any;
    status: ServiceTypes.AppointmentStatus;
    videoConferenceUrl?: string | null;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      name: string;
      slug: string;
      displayName: string;
      duration: number;
      allowedRole: ServiceTypes.StaffRole;
    };
    staff: {
      __typename?: 'Staff';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      role: ServiceTypes.StaffRole;
    };
  } | null;
  summary?: {
    __typename?: 'EncounterSummary';
    note?: string | null;
    goals?: string | null;
    nextSteps?: string | null;
  } | null;
  clinicalNote: {
    __typename?: 'ClinicalNote';
    body?:
      | {
          __typename?: 'HealthCoachNote';
          asyncNote?: string | null;
          subjectiveObjective?: {
            __typename?: 'HealthCoachSubjectiveObjectiveNote';
            generalSessionNotes?: string | null;
            healthCoachNotes?: string | null;
            preSessionNotes?: string | null;
          } | null;
          plan?: {
            __typename?: 'HealthCoachPlanNote';
            sessionNumber?: string | null;
            postVisitSummary?: string | null;
          } | null;
        }
      | {
          __typename?: 'PrescriberNote';
          asyncNote?: string | null;
          subjectiveObjective?: {
            __typename?: 'PrescriberSubjectiveObjectiveNote';
            historyOfPresentIllness?: string | null;
            reviewOfSystems?: string | null;
            medicalHistory?: string | null;
            objective?: string | null;
          } | null;
          assessment?: {
            __typename?: 'PrescriberNoteAssessmentNote';
            notes?: string | null;
          } | null;
          plan?: {
            __typename?: 'PrescriberPlanNote';
            notes?: string | null;
            prescriptions?: string | null;
            labs?: string | null;
            referrals?: string | null;
            healthScreeningCounselling?: string | null;
            postVisitSummary?: string | null;
          } | null;
        }
      | {
          __typename?: 'SOAPNote';
          subjectiveObjective?: {
            __typename?: 'SubjectiveObjectiveNote';
            historyOfPresentIllness?: string | null;
            reviewOfSystems?: string | null;
            medicalHistory?: string | null;
            progress?: string | null;
            mentalStatus?: string | null;
          } | null;
          SOAPAssessment?: {
            __typename?: 'AssessmentNote';
            note?: string | null;
            icd10Codes?: Array<{
              __typename?: 'ICD10Code';
              code?: string | null;
              name?: string | null;
            }> | null;
          } | null;
          plan?: {
            __typename?: 'PlanNote';
            note?: string | null;
            prescriptions?: string | null;
            labs?: string | null;
            referrals?: string | null;
          } | null;
        }
      | null;
  };
};

export type EncounterIndexItemFragment = {
  __typename?: 'Encounter';
  id: any;
  startedAt: any;
  type: ServiceTypes.EncounterType;
  clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
  status: ServiceTypes.EncounterStatus;
  staff: {
    __typename?: 'Staff';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
  };
  appointment?: {
    __typename?: 'Appointment';
    id: any;
    status: ServiceTypes.AppointmentStatus;
    startTime: any;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      displayName: string;
    };
  } | null;
};

export type HealthCoachClinicalNoteFragment = {
  __typename?: 'HealthCoachNote';
  asyncNote?: string | null;
  subjectiveObjective?: {
    __typename?: 'HealthCoachSubjectiveObjectiveNote';
    generalSessionNotes?: string | null;
    healthCoachNotes?: string | null;
    preSessionNotes?: string | null;
  } | null;
  plan?: {
    __typename?: 'HealthCoachPlanNote';
    sessionNumber?: string | null;
    postVisitSummary?: string | null;
  } | null;
};

export type HistoricalClinicalNoteFragment = {
  __typename?: 'SOAPNote';
  subjectiveObjective?: {
    __typename?: 'SubjectiveObjectiveNote';
    historyOfPresentIllness?: string | null;
    reviewOfSystems?: string | null;
    medicalHistory?: string | null;
    progress?: string | null;
    mentalStatus?: string | null;
  } | null;
  SOAPAssessment?: {
    __typename?: 'AssessmentNote';
    note?: string | null;
    icd10Codes?: Array<{
      __typename?: 'ICD10Code';
      code?: string | null;
      name?: string | null;
    }> | null;
  } | null;
  plan?: {
    __typename?: 'PlanNote';
    note?: string | null;
    prescriptions?: string | null;
    labs?: string | null;
    referrals?: string | null;
  } | null;
};

export type PrescriberClinicalNoteFragment = {
  __typename?: 'PrescriberNote';
  asyncNote?: string | null;
  subjectiveObjective?: {
    __typename?: 'PrescriberSubjectiveObjectiveNote';
    historyOfPresentIllness?: string | null;
    reviewOfSystems?: string | null;
    medicalHistory?: string | null;
    objective?: string | null;
  } | null;
  assessment?: {
    __typename?: 'PrescriberNoteAssessmentNote';
    notes?: string | null;
  } | null;
  plan?: {
    __typename?: 'PrescriberPlanNote';
    notes?: string | null;
    prescriptions?: string | null;
    labs?: string | null;
    referrals?: string | null;
    healthScreeningCounselling?: string | null;
    postVisitSummary?: string | null;
  } | null;
};

export type VisitSummaryFragment = {
  __typename?: 'Appointment';
  id: any;
  appointmentTypeId: any;
  patientId: any;
  summary: string;
  description: string;
  startTime: any;
  endTime: any;
  status: ServiceTypes.AppointmentStatus;
  videoConferenceUrl?: string | null;
  appointmentType: {
    __typename?: 'AppointmentType';
    id: any;
    name: string;
    slug: string;
    displayName: string;
    duration: number;
    allowedRole: ServiceTypes.StaffRole;
  };
  staff: {
    __typename?: 'Staff';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    role: ServiceTypes.StaffRole;
  };
};

export type VisitSummaryWithEncounterFragment = {
  __typename?: 'Appointment';
  id: any;
  appointmentTypeId: any;
  patientId: any;
  summary: string;
  description: string;
  startTime: any;
  endTime: any;
  status: ServiceTypes.AppointmentStatus;
  videoConferenceUrl?: string | null;
  encounter?: {
    __typename?: 'Encounter';
    id: any;
    status: ServiceTypes.EncounterStatus;
  } | null;
  appointmentType: {
    __typename?: 'AppointmentType';
    id: any;
    name: string;
    slug: string;
    displayName: string;
    duration: number;
    allowedRole: ServiceTypes.StaffRole;
  };
  staff: {
    __typename?: 'Staff';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    role: ServiceTypes.StaffRole;
  };
};

export type ArchiveMonitorMutationVariables = ServiceTypes.Exact<{
  archiveMonitorId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type ArchiveMonitorMutation = {
  __typename?: 'Mutation';
  archiveMonitor: { __typename?: 'Monitor'; id: any };
};

export type CreateMonitorMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateMonitorInput;
}>;

export type CreateMonitorMutation = {
  __typename?: 'Mutation';
  createMonitor: { __typename?: 'Monitor'; id: any };
};

export type UpdateMonitorMutationVariables = ServiceTypes.Exact<{
  updateMonitorId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdateMonitorInput;
}>;

export type UpdateMonitorMutation = {
  __typename?: 'Mutation';
  updateMonitor: { __typename?: 'Monitor'; id: any };
};

export type CreateCareJourneyMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateCareJourneyInput;
}>;

export type CreateCareJourneyMutation = {
  __typename?: 'Mutation';
  createCareJourney: { __typename?: 'CreateCareJourney'; id: any };
};

export type CreateCareJourneyTemplateMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateCareJourneyTemplateInput;
}>;

export type CreateCareJourneyTemplateMutation = {
  __typename?: 'Mutation';
  createCareJourneyTemplate: { __typename?: 'CareJourneyTemplate'; id: any };
};

export type CreateStubTouchpointTemplateMutationVariables = ServiceTypes.Exact<{
  name: ServiceTypes.Scalars['String']['input'];
  position: ServiceTypes.Scalars['Int']['input'];
  templateVersionId: ServiceTypes.Scalars['UUID']['input'];
  category: ServiceTypes.TouchpointCategory;
  type?: ServiceTypes.InputMaybe<ServiceTypes.TouchpointType>;
  programId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CreateStubTouchpointTemplateMutation = {
  __typename?: 'Mutation';
  createSequentialTouchpointTemplate: {
    __typename?: 'SequentialTouchpointTemplate';
    id: any;
    updatedAt: any;
    name: string;
    position?: number | null;
    type?: ServiceTypes.TouchpointType | null;
    category: ServiceTypes.TouchpointCategory;
    openUponEnrollment: boolean;
    touchpointFrequency?: {
      __typename?: 'TouchpointCadence';
      numberOfRepeats?: number | null;
      frequency: {
        __typename?: 'Frequency';
        duration: ServiceTypes.FrequencyDuration;
        value: number;
      };
    } | null;
    taskFrequency?: {
      __typename?: 'TaskCadence';
      numberOfRepeats?: number | null;
      frequency: {
        __typename?: 'Frequency';
        duration: ServiceTypes.FrequencyDuration;
        value: number;
      };
    } | null;
    notificationConfig?: Array<{
      __typename?: 'TouchpointNotificationConfig';
      delay?: {
        __typename?: 'TouchpointNotificationDuration';
        value: number;
      } | null;
    }> | null;
  };
};

export type DeleteSequentialTouchpointTemplateMutationVariables =
  ServiceTypes.Exact<{
    id: ServiceTypes.Scalars['UUID']['input'];
  }>;

export type DeleteSequentialTouchpointTemplateMutation = {
  __typename?: 'Mutation';
  deleteSequentialTouchpointTemplate: {
    __typename?: 'SequentialTouchpointTemplate';
    id: any;
  };
};

export type PublishCareJourneyTemplateMutationVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type PublishCareJourneyTemplateMutation = {
  __typename?: 'Mutation';
  publishCareJourneyTemplate: { __typename?: 'CareJourneyTemplate'; id: any };
};

export type ReorderTouchpointsMutationVariables = ServiceTypes.Exact<{
  careJourneyTemplateId: ServiceTypes.Scalars['UUID']['input'];
  touchpoints: Array<ServiceTypes.Scalars['UUID']['input']>;
}>;

export type ReorderTouchpointsMutation = {
  __typename?: 'Mutation';
  updateCareJourneyTemplate: {
    __typename?: 'CareJourneyTemplate';
    id: any;
    name: string;
    type: ServiceTypes.CareJourneyType;
    programId: any;
    updatedAt: any;
    draftVersion: {
      __typename?: 'CareJourneyTemplateVersion';
      id: any;
      touchpointTemplates: Array<{
        __typename?: 'SequentialTouchpointTemplate';
        id: any;
        updatedAt: any;
        name: string;
        position?: number | null;
        type?: ServiceTypes.TouchpointType | null;
        category: ServiceTypes.TouchpointCategory;
        openUponEnrollment: boolean;
        touchpointFrequency?: {
          __typename?: 'TouchpointCadence';
          numberOfRepeats?: number | null;
          frequency: {
            __typename?: 'Frequency';
            duration: ServiceTypes.FrequencyDuration;
            value: number;
          };
        } | null;
        taskFrequency?: {
          __typename?: 'TaskCadence';
          numberOfRepeats?: number | null;
          frequency: {
            __typename?: 'Frequency';
            duration: ServiceTypes.FrequencyDuration;
            value: number;
          };
        } | null;
        notificationConfig?: Array<{
          __typename?: 'TouchpointNotificationConfig';
          delay?: {
            __typename?: 'TouchpointNotificationDuration';
            value: number;
          } | null;
        }> | null;
      }>;
    };
  };
};

export type UpdateTouchpointTemplateMutationVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
  name?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['String']['input']>;
  relatedObjectId?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['String']['input']
  >;
  taskDueDuration?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['Int']['input']
  >;
  taskExpirationDuration?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['Int']['input']
  >;
  notifications?: ServiceTypes.InputMaybe<
    Array<ServiceTypes.TouchpointNotificationConfigInput>
  >;
  eventDueDuration?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['Int']['input']
  >;
  description?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['String']['input']
  >;
  touchpointFrequency?: ServiceTypes.InputMaybe<ServiceTypes.TouchpointCadenceInput>;
  confirmedMessage?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['String']['input']
  >;
  declinedMessage?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['String']['input']
  >;
  taskFrequency?: ServiceTypes.InputMaybe<ServiceTypes.TaskCadenceInput>;
  anchor?: ServiceTypes.InputMaybe<ServiceTypes.AnchorType>;
  anchorOffset?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['Int']['input']>;
  anchorTouchpointTemplateId?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['UUID']['input']
  >;
  reopenOnEventDateUpdate?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['Boolean']['input']
  >;
  rules?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['JSON']['input']>;
}>;

export type UpdateTouchpointTemplateMutation = {
  __typename?: 'Mutation';
  updateSequentialTouchpointTemplate: {
    __typename?: 'SequentialTouchpointTemplate';
    id: any;
  };
};

export type CreateSequentialTouchpointMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateSequentialTouchpointInput;
}>;

export type CreateSequentialTouchpointMutation = {
  __typename?: 'Mutation';
  createSequentialTouchpoint: {
    __typename?: 'UpdatedSequentialTouchpoint';
    id: any;
    dueAt?: any | null;
    completedAt?: any | null;
    position: number;
    note?: string | null;
    status: ServiceTypes.TouchpointStatus;
  };
};

export type DuplicateCareJourneyTemplateMutationVariables = ServiceTypes.Exact<{
  duplicateCareJourneyTemplateId: ServiceTypes.Scalars['UUID']['input'];
  input?: ServiceTypes.InputMaybe<ServiceTypes.DuplicateCareJourneyTemplateParams>;
}>;

export type DuplicateCareJourneyTemplateMutation = {
  __typename?: 'Mutation';
  duplicateCareJourneyTemplate: { __typename?: 'CareJourneyTemplate'; id: any };
};

export type UpdateSequentialTouchpointMutationVariables = ServiceTypes.Exact<{
  updateSequentialTouchpointId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdateSequentialTouchpointInput;
}>;

export type UpdateSequentialTouchpointMutation = {
  __typename?: 'Mutation';
  updateSequentialTouchpoint: {
    __typename?: 'UpdatedSequentialTouchpoint';
    id: any;
    status: ServiceTypes.TouchpointStatus;
    note?: string | null;
    completedAt?: any | null;
  };
};

export type CreateAppointmentMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateAppointmentInput;
}>;

export type CreateAppointmentMutation = {
  __typename?: 'Mutation';
  createAppointment: { __typename?: 'Appointment'; id: any };
};

export type CreateFormMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateFormInput;
}>;

export type CreateFormMutation = {
  __typename?: 'Mutation';
  createForm: {
    __typename?: 'Form';
    id: any;
    programId: any;
    slug: string;
    name: string;
    description?: string | null;
    archivedAt?: any | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateObservationDefinitionMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateObservationDefinitionInput;
}>;

export type CreateObservationDefinitionMutation = {
  __typename?: 'Mutation';
  createObservationDefinition: {
    __typename?: 'ObservationDefinition';
    id: any;
    slug: string;
    resource: {
      __typename?: 'ObservationDefinitionResource';
      dataConfig: any;
      name: string;
      title: string;
      description?: string | null;
    };
  };
};

export type DuplicateFormMutationVariables = ServiceTypes.Exact<{
  duplicateFormId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type DuplicateFormMutation = {
  __typename?: 'Mutation';
  duplicateForm: {
    __typename?: 'Form';
    id: any;
    name: string;
    archivedAt?: any | null;
    createdAt: any;
    description?: string | null;
    programId: any;
    slug: string;
    updatedAt: any;
  };
};

export type UpdateFormMutationVariables = ServiceTypes.Exact<{
  updateFormId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdateFormInput;
}>;

export type UpdateFormMutation = {
  __typename?: 'Mutation';
  updateForm: {
    __typename?: 'Form';
    id: any;
    programId: any;
    slug: string;
    name: string;
    description?: string | null;
    archivedAt?: any | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateProfileIntegerObservationMutationVariables =
  ServiceTypes.Exact<{
    patientId: ServiceTypes.Scalars['UUID']['input'];
    programId: ServiceTypes.Scalars['UUID']['input'];
    code: ServiceTypes.Scalars['String']['input'];
    number: ServiceTypes.Scalars['Int']['input'];
  }>;

export type CreateProfileIntegerObservationMutation = {
  __typename?: 'Mutation';
  createObservation: { __typename?: 'Observation'; id: any };
};

export type CreateProfileStringObservationMutationVariables =
  ServiceTypes.Exact<{
    patientId: ServiceTypes.Scalars['UUID']['input'];
    programId: ServiceTypes.Scalars['UUID']['input'];
    code: ServiceTypes.Scalars['String']['input'];
    text: ServiceTypes.Scalars['String']['input'];
  }>;

export type CreateProfileStringObservationMutation = {
  __typename?: 'Mutation';
  createObservation: { __typename?: 'Observation'; id: any };
};

export type CreateAllergyIntoleranceMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateAllergyIntoleranceInput;
}>;

export type CreateAllergyIntoleranceMutation = {
  __typename?: 'Mutation';
  createAllergyIntolerance: { __typename?: 'AllergyIntolerance'; id: any };
};

export type CreateConditionMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  code: ServiceTypes.Scalars['String']['input'];
  conditionName: ServiceTypes.Scalars['String']['input'];
}>;

export type CreateConditionMutation = {
  __typename?: 'Mutation';
  createCondition: {
    __typename?: 'Condition';
    active: boolean;
    id: any;
    resource: {
      __typename?: 'ConditionResource';
      code?: { __typename?: 'CodeableConcept'; text?: string | null } | null;
    };
  };
};

export type CreateHistoryConditionsObservationMutationVariables =
  ServiceTypes.Exact<{
    patientId: ServiceTypes.Scalars['UUID']['input'];
    programId: ServiceTypes.Scalars['UUID']['input'];
    conditions: Array<ServiceTypes.ObservationComponentInput>;
  }>;

export type CreateHistoryConditionsObservationMutation = {
  __typename?: 'Mutation';
  createObservation: { __typename?: 'Observation'; id: any };
};

export type CreateLatestPrescriptionMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  prescriptionString: ServiceTypes.Scalars['String']['input'];
}>;

export type CreateLatestPrescriptionMutation = {
  __typename?: 'Mutation';
  createObservation: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      value?: { __typename?: 'Value'; valueString?: string | null } | null;
    };
  };
};

export type CreateMedicationStatementMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateMedicationStatementInput;
}>;

export type CreateMedicationStatementMutation = {
  __typename?: 'Mutation';
  createMedicationStatement: {
    __typename?: 'MedicationStatement';
    id: any;
    patientId: any;
    programId: any;
    createdAt: any;
    updatedAt: any;
    active: boolean;
  };
};

export type DeleteObservationMutationVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type DeleteObservationMutation = {
  __typename?: 'Mutation';
  deleteObservation: { __typename?: 'Observation'; id: any };
};

export type EndMedicationForPatientMutationVariables = ServiceTypes.Exact<{
  updateMedicationStatementId: ServiceTypes.Scalars['UUID']['input'];
  endDate: ServiceTypes.Scalars['String']['input'];
}>;

export type EndMedicationForPatientMutation = {
  __typename?: 'Mutation';
  updateMedicationStatement: {
    __typename?: 'MedicationStatement';
    active: boolean;
    id: any;
  };
};

export type InactivateAllergyIntoleranceForPatientMutationVariables =
  ServiceTypes.Exact<{
    updateAllergyIntoleranceId: ServiceTypes.Scalars['UUID']['input'];
  }>;

export type InactivateAllergyIntoleranceForPatientMutation = {
  __typename?: 'Mutation';
  updateAllergyIntolerance: { __typename?: 'AllergyIntolerance'; id: any };
};

export type RemoveConditionMutationVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type RemoveConditionMutation = {
  __typename?: 'Mutation';
  updateCondition: {
    __typename?: 'Condition';
    active: boolean;
    id: any;
    resource: {
      __typename?: 'ConditionResource';
      code?: { __typename?: 'CodeableConcept'; text?: string | null } | null;
    };
  };
};

export type SupercedeHistoryConditionsObservationMutationVariables =
  ServiceTypes.Exact<{
    patientId: ServiceTypes.Scalars['UUID']['input'];
    programId: ServiceTypes.Scalars['UUID']['input'];
    conditions: Array<ServiceTypes.ObservationComponentInput>;
    id: ServiceTypes.Scalars['UUID']['input'];
  }>;

export type SupercedeHistoryConditionsObservationMutation = {
  __typename?: 'Mutation';
  supercedeObservation: { __typename?: 'Observation'; id: any };
};

export type CancelAppointmentMutationVariables = ServiceTypes.Exact<{
  cancelAppointmentId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CancelAppointmentMutation = {
  __typename?: 'Mutation';
  cancelAppointment: {
    __typename?: 'Appointment';
    id: any;
    status: ServiceTypes.AppointmentStatus;
  };
};

export type CancelAppointmentFromTouchpointMutationVariables =
  ServiceTypes.Exact<{
    cancelAppointmentId: ServiceTypes.Scalars['UUID']['input'];
  }>;

export type CancelAppointmentFromTouchpointMutation = {
  __typename?: 'Mutation';
  cancelAppointment: {
    __typename?: 'Appointment';
    id: any;
    status: ServiceTypes.AppointmentStatus;
  };
};

export type CreateStaffMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateStaffInput;
}>;

export type CreateStaffMutation = {
  __typename?: 'Mutation';
  createStaff: {
    __typename?: 'Staff';
    id: any;
    createdAt: any;
    status: ServiceTypes.StaffStatus;
    role: ServiceTypes.StaffRole;
  };
};

export type LinkStaffAccountMutationVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type LinkStaffAccountMutation = {
  __typename?: 'Mutation';
  linkStaffAccount: { __typename?: 'BasicStaff'; id: any };
};

export type UpdateStaffMutationVariables = ServiceTypes.Exact<{
  updateStaffId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdateStaffInput;
}>;

export type UpdateStaffMutation = {
  __typename?: 'Mutation';
  updateStaff: {
    __typename?: 'Staff';
    id: any;
    updatedAt: any;
    status: ServiceTypes.StaffStatus;
    role: ServiceTypes.StaffRole;
  };
};

export type CreateTaskMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: { __typename?: 'Task'; id: any };
};

export type UpdateTaskMutationVariables = ServiceTypes.Exact<{
  taskId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: any;
    assigneeId: any;
    reporterId?: any | null;
    patientId: any;
    comments?: string | null;
    taskTemplateId?: any | null;
    name: string;
    status: ServiceTypes.TaskStatus;
    programId: any;
    taskDueAt: any;
    eventDueAt?: any | null;
    taskExpireAt?: any | null;
    role?: ServiceTypes.StaffRole | null;
    category: ServiceTypes.TaskCategory;
    taskType: ServiceTypes.TaskType;
    priority: ServiceTypes.TaskPriority;
    description?: string | null;
    relatedObjectType?: ServiceTypes.TaskRelatedObject | null;
    relatedObjectId?: string | null;
    resultObjectType?: ServiceTypes.TaskResultObject | null;
    resultObjectId?: any | null;
    createdAt: any;
    updatedAt: any;
    assignee:
      | { __typename: 'Patient'; id: any; firstName: string; lastName: string }
      | { __typename: 'Staff'; id: any; firstName: string; lastName: string };
    patient: {
      __typename?: 'Patient';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      birthDate: any;
    };
    program: { __typename?: 'Program'; id: any };
    taskTemplate?: {
      __typename?: 'TaskTemplate';
      id: any;
      name: string;
    } | null;
    taskConfig?: {
      __typename?: 'TaskConfig';
      type: ServiceTypes.TaskType;
    } | null;
    legacyNotificationSchedule?: {
      __typename?: 'NotificationSchedule';
      schedules: Array<{
        __typename?: 'NotificationReminder';
        sequentialOrder: number;
        scheduledAt: any;
        eventName: string;
      }>;
    } | null;
  };
};

export type UpdateAppointmentStatusMutationVariables = ServiceTypes.Exact<{
  appointmentId: ServiceTypes.Scalars['UUID']['input'];
  status: ServiceTypes.AppointmentStatus;
}>;

export type UpdateAppointmentStatusMutation = {
  __typename?: 'Mutation';
  updateAppointmentStatus: {
    __typename?: 'Appointment';
    id: any;
    status: ServiceTypes.AppointmentStatus;
  };
};

export type UpsertCronofyProfileMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.UpsertCronofyProfileInput;
}>;

export type UpsertCronofyProfileMutation = {
  __typename?: 'Mutation';
  upsertCronofyProfile: {
    __typename?: 'CronofyProfile';
    id: any;
    staffId: any;
    externalUserId: string;
    primaryCalendarId: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type CompleteEncounterMutationVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CompleteEncounterMutation = {
  __typename?: 'Mutation';
  completeEncounter: {
    __typename?: 'Encounter';
    id: any;
    type: ServiceTypes.EncounterType;
    status: ServiceTypes.EncounterStatus;
    startedAt: any;
    updatedAt: any;
    clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
    staff: {
      __typename?: 'Staff';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      role: ServiceTypes.StaffRole;
    };
    appointment?: {
      __typename?: 'Appointment';
      id: any;
      appointmentTypeId: any;
      patientId: any;
      summary: string;
      description: string;
      startTime: any;
      endTime: any;
      status: ServiceTypes.AppointmentStatus;
      videoConferenceUrl?: string | null;
      appointmentType: {
        __typename?: 'AppointmentType';
        id: any;
        name: string;
        slug: string;
        displayName: string;
        duration: number;
        allowedRole: ServiceTypes.StaffRole;
      };
      staff: {
        __typename?: 'Staff';
        id: any;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
        role: ServiceTypes.StaffRole;
      };
    } | null;
    summary?: {
      __typename?: 'EncounterSummary';
      note?: string | null;
      goals?: string | null;
      nextSteps?: string | null;
    } | null;
    clinicalNote: {
      __typename?: 'ClinicalNote';
      body?:
        | {
            __typename?: 'HealthCoachNote';
            asyncNote?: string | null;
            subjectiveObjective?: {
              __typename?: 'HealthCoachSubjectiveObjectiveNote';
              generalSessionNotes?: string | null;
              healthCoachNotes?: string | null;
              preSessionNotes?: string | null;
            } | null;
            plan?: {
              __typename?: 'HealthCoachPlanNote';
              sessionNumber?: string | null;
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'PrescriberNote';
            asyncNote?: string | null;
            subjectiveObjective?: {
              __typename?: 'PrescriberSubjectiveObjectiveNote';
              historyOfPresentIllness?: string | null;
              reviewOfSystems?: string | null;
              medicalHistory?: string | null;
              objective?: string | null;
            } | null;
            assessment?: {
              __typename?: 'PrescriberNoteAssessmentNote';
              notes?: string | null;
            } | null;
            plan?: {
              __typename?: 'PrescriberPlanNote';
              notes?: string | null;
              prescriptions?: string | null;
              labs?: string | null;
              referrals?: string | null;
              healthScreeningCounselling?: string | null;
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'SOAPNote';
            subjectiveObjective?: {
              __typename?: 'SubjectiveObjectiveNote';
              historyOfPresentIllness?: string | null;
              reviewOfSystems?: string | null;
              medicalHistory?: string | null;
              progress?: string | null;
              mentalStatus?: string | null;
            } | null;
            SOAPAssessment?: {
              __typename?: 'AssessmentNote';
              note?: string | null;
              icd10Codes?: Array<{
                __typename?: 'ICD10Code';
                code?: string | null;
                name?: string | null;
              }> | null;
            } | null;
            plan?: {
              __typename?: 'PlanNote';
              note?: string | null;
              prescriptions?: string | null;
              labs?: string | null;
              referrals?: string | null;
            } | null;
          }
        | null;
    };
  };
};

export type CreateAsyncEncounterMutationVariables = ServiceTypes.Exact<{
  programId: ServiceTypes.Scalars['UUID']['input'];
  staffId: ServiceTypes.Scalars['UUID']['input'];
  patientId: ServiceTypes.Scalars['UUID']['input'];
  clinicalNoteType: ServiceTypes.ClinicalNoteType;
}>;

export type CreateAsyncEncounterMutation = {
  __typename?: 'Mutation';
  createEncounter: { __typename?: 'Encounter'; id: any };
};

export type CreateEncounterForAppointmentMutationVariables =
  ServiceTypes.Exact<{
    programId: ServiceTypes.Scalars['UUID']['input'];
    staffId: ServiceTypes.Scalars['UUID']['input'];
    patientId: ServiceTypes.Scalars['UUID']['input'];
    appointmentId: ServiceTypes.Scalars['UUID']['input'];
    clinicalNoteType: ServiceTypes.ClinicalNoteType;
  }>;

export type CreateEncounterForAppointmentMutation = {
  __typename?: 'Mutation';
  createEncounter: { __typename?: 'Encounter'; id: any };
};

export type UpdateHealthCoachAsyncEncounterMutationVariables =
  ServiceTypes.Exact<{
    id: ServiceTypes.Scalars['UUID']['input'];
    note: ServiceTypes.Scalars['String']['input'];
  }>;

export type UpdateHealthCoachAsyncEncounterMutation = {
  __typename?: 'Mutation';
  updateEncounter: {
    __typename?: 'Encounter';
    id: any;
    status: ServiceTypes.EncounterStatus;
  };
};

export type UpdateHealthCoachVisitEncounterMutationVariables =
  ServiceTypes.Exact<{
    id: ServiceTypes.Scalars['UUID']['input'];
    preSessionNotes: ServiceTypes.Scalars['String']['input'];
    generalSessionNotes: ServiceTypes.Scalars['String']['input'];
    healthCoachNotes: ServiceTypes.Scalars['String']['input'];
    postVisitSummary: ServiceTypes.Scalars['String']['input'];
    sessionNumber: ServiceTypes.Scalars['String']['input'];
  }>;

export type UpdateHealthCoachVisitEncounterMutation = {
  __typename?: 'Mutation';
  updateEncounter: {
    __typename?: 'Encounter';
    id: any;
    status: ServiceTypes.EncounterStatus;
  };
};

export type UpdateHistoricalVisitEncounterMutationVariables =
  ServiceTypes.Exact<{
    id: ServiceTypes.Scalars['UUID']['input'];
    summaryNote: ServiceTypes.Scalars['String']['input'];
    goals: ServiceTypes.Scalars['String']['input'];
    nextSteps: ServiceTypes.Scalars['String']['input'];
    historyOfPresentIllness: ServiceTypes.Scalars['String']['input'];
    reviewOfSystems: ServiceTypes.Scalars['String']['input'];
    medicalHistory: ServiceTypes.Scalars['String']['input'];
    progress: ServiceTypes.Scalars['String']['input'];
    mentalStatus: ServiceTypes.Scalars['String']['input'];
    assessmentNote: ServiceTypes.Scalars['String']['input'];
    icd10Codes: Array<ServiceTypes.Icd10CodeInput>;
    planNote: ServiceTypes.Scalars['String']['input'];
    prescriptions: ServiceTypes.Scalars['String']['input'];
    labs: ServiceTypes.Scalars['String']['input'];
    referrals: ServiceTypes.Scalars['String']['input'];
  }>;

export type UpdateHistoricalVisitEncounterMutation = {
  __typename?: 'Mutation';
  updateEncounter: {
    __typename?: 'Encounter';
    id: any;
    status: ServiceTypes.EncounterStatus;
  };
};

export type UpdatePrescriberAsyncEncounterMutationVariables =
  ServiceTypes.Exact<{
    id: ServiceTypes.Scalars['UUID']['input'];
    note: ServiceTypes.Scalars['String']['input'];
  }>;

export type UpdatePrescriberAsyncEncounterMutation = {
  __typename?: 'Mutation';
  updateEncounter: {
    __typename?: 'Encounter';
    id: any;
    status: ServiceTypes.EncounterStatus;
  };
};

export type UpdatePrescriberVisitEncounterMutationVariables =
  ServiceTypes.Exact<{
    id: ServiceTypes.Scalars['UUID']['input'];
    historyOfPresentIllness: ServiceTypes.Scalars['String']['input'];
    reviewOfSystems: ServiceTypes.Scalars['String']['input'];
    medicalHistory: ServiceTypes.Scalars['String']['input'];
    objective: ServiceTypes.Scalars['String']['input'];
    assessmentNote: ServiceTypes.Scalars['String']['input'];
    planNote: ServiceTypes.Scalars['String']['input'];
    prescriptions: ServiceTypes.Scalars['String']['input'];
    labs: ServiceTypes.Scalars['String']['input'];
    referrals: ServiceTypes.Scalars['String']['input'];
    healthScreeningCounselling: ServiceTypes.Scalars['String']['input'];
    postVisitSummary: ServiceTypes.Scalars['String']['input'];
  }>;

export type UpdatePrescriberVisitEncounterMutation = {
  __typename?: 'Mutation';
  updateEncounter: {
    __typename?: 'Encounter';
    id: any;
    status: ServiceTypes.EncounterStatus;
  };
};

export type ListAlertsByPatientQueryVariables = ServiceTypes.Exact<{
  filters?: ServiceTypes.InputMaybe<ServiceTypes.ListAlertFilters>;
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
}>;

export type ListAlertsByPatientQuery = {
  __typename?: 'Query';
  alerts: {
    __typename?: 'AlertList';
    items: Array<{
      __typename?: 'Alert';
      priority: ServiceTypes.MonitorPriority;
      name: string;
      createdAt: any;
      id: any;
    }>;
  };
};

export type ListMonitorsQueryVariables = ServiceTypes.Exact<{
  filters?: ServiceTypes.InputMaybe<ServiceTypes.ListMonitorFilters>;
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
  sorting?: ServiceTypes.InputMaybe<Array<ServiceTypes.SortMonitorParam>>;
}>;

export type ListMonitorsQuery = {
  __typename?: 'Query';
  monitors: {
    __typename?: 'MonitorList';
    totalCount: number;
    items: Array<{
      __typename?: 'Monitor';
      id: any;
      programId?: any | null;
      careJourneyId?: any | null;
      patientId?: any | null;
      type: ServiceTypes.MonitorType;
      scope: ServiceTypes.MonitorScope;
      name: string;
      audience: Array<ServiceTypes.MonitorAudience>;
      staffClinicalMessageTemplate: string;
      staffNonclinicalMessageTemplate: string;
      patientMessageTemplate: string;
      priority: ServiceTypes.MonitorPriority;
      muted: boolean;
    }>;
  };
};

export type MonitorQueryVariables = ServiceTypes.Exact<{
  monitorId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type MonitorQuery = {
  __typename?: 'Query';
  monitor: {
    __typename?: 'Monitor';
    id: any;
    tenantId: any;
    programId?: any | null;
    careJourneyId?: any | null;
    patientId?: any | null;
    type: ServiceTypes.MonitorType;
    scope: ServiceTypes.MonitorScope;
    name: string;
    audience: Array<ServiceTypes.MonitorAudience>;
    staffClinicalMessageTemplate: string;
    staffNonclinicalMessageTemplate: string;
    patientMessageTemplate: string;
    priority: ServiceTypes.MonitorPriority;
    muted: boolean;
    rules:
      | {
          __typename?: 'CompoundCondition';
          type: ServiceTypes.ConditionType;
          compound: ServiceTypes.CompoundOperator;
          operands: Array<
            | {
                __typename?: 'CompoundCondition';
                type: ServiceTypes.ConditionType;
                compound: ServiceTypes.CompoundOperator;
                operands: Array<
                  | { __typename?: 'CompoundCondition' }
                  | {
                      __typename?: 'SimpleCondition';
                      type: ServiceTypes.ConditionType;
                      simpleOperands: Array<any>;
                      simpleOperator: ServiceTypes.SimpleOperator;
                    }
                >;
              }
            | {
                __typename?: 'SimpleCondition';
                type: ServiceTypes.ConditionType;
                simpleOperands: Array<any>;
                simpleOperator: ServiceTypes.SimpleOperator;
              }
          >;
        }
      | {
          __typename?: 'SimpleCondition';
          type: ServiceTypes.ConditionType;
          simpleOperands: Array<any>;
          simpleOperator: ServiceTypes.SimpleOperator;
        };
  };
};

export type CareJourneyTemplateQueryVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CareJourneyTemplateQuery = {
  __typename?: 'Query';
  careJourneyTemplate: {
    __typename?: 'CareJourneyTemplate';
    id: any;
    name: string;
    type: ServiceTypes.CareJourneyType;
    programId: any;
    updatedAt: any;
    draftVersion: {
      __typename?: 'CareJourneyTemplateVersion';
      id: any;
      touchpointTemplates: Array<{
        __typename?: 'SequentialTouchpointTemplate';
        id: any;
        updatedAt: any;
        name: string;
        position?: number | null;
        type?: ServiceTypes.TouchpointType | null;
        category: ServiceTypes.TouchpointCategory;
        openUponEnrollment: boolean;
        touchpointFrequency?: {
          __typename?: 'TouchpointCadence';
          numberOfRepeats?: number | null;
          frequency: {
            __typename?: 'Frequency';
            duration: ServiceTypes.FrequencyDuration;
            value: number;
          };
        } | null;
        taskFrequency?: {
          __typename?: 'TaskCadence';
          numberOfRepeats?: number | null;
          frequency: {
            __typename?: 'Frequency';
            duration: ServiceTypes.FrequencyDuration;
            value: number;
          };
        } | null;
        notificationConfig?: Array<{
          __typename?: 'TouchpointNotificationConfig';
          delay?: {
            __typename?: 'TouchpointNotificationDuration';
            value: number;
          } | null;
        }> | null;
      }>;
    };
  };
};

export type CareJourneyTemplatesQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.CareJourneyTemplateFilters;
}>;

export type CareJourneyTemplatesQuery = {
  __typename?: 'Query';
  careJourneyTemplates: Array<{
    __typename?: 'CareJourneyTemplate';
    name: string;
    id: any;
    description?: string | null;
    type: ServiceTypes.CareJourneyType;
    modality: ServiceTypes.Modality;
  }>;
};

export type CareJourneyTemplatesTableQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type CareJourneyTemplatesTableQuery = {
  __typename?: 'Query';
  careJourneyTemplates: Array<{
    __typename?: 'CareJourneyTemplate';
    id: any;
    name: string;
    type: ServiceTypes.CareJourneyType;
    publishedVersion?: {
      __typename?: 'CareJourneyTemplateVersion';
      id: any;
    } | null;
    draftVersion: { __typename?: 'CareJourneyTemplateVersion'; id: any };
  }>;
};

export type EditableTouchpointTemplateQueryVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type EditableTouchpointTemplateQuery = {
  __typename?: 'Query';
  sequentialTouchpointTemplate: {
    __typename?: 'SequentialTouchpointTemplate';
    description?: string | null;
    reopenOnEventDateUpdate: boolean;
    confirmedMessage?: string | null;
    declinedMessage?: string | null;
    anchor?: ServiceTypes.AnchorType | null;
    anchorOffset?: number | null;
    anchorTouchpointTemplateId?: any | null;
    id: any;
    updatedAt: any;
    name: string;
    position?: number | null;
    type?: ServiceTypes.TouchpointType | null;
    category: ServiceTypes.TouchpointCategory;
    openUponEnrollment: boolean;
    touchpointFrequency?: {
      __typename?: 'TouchpointCadence';
      numberOfRepeats?: number | null;
      frequency: {
        __typename?: 'Frequency';
        duration: ServiceTypes.FrequencyDuration;
        value: number;
      };
    } | null;
    taskFrequency?: {
      __typename?: 'TaskCadence';
      numberOfRepeats?: number | null;
      frequency: {
        __typename?: 'Frequency';
        duration: ServiceTypes.FrequencyDuration;
        value: number;
      };
    } | null;
    taskConfig?: {
      __typename?: 'TouchpointTemplateTaskConfig';
      relatedObjectId?: string | null;
      taskDueDuration?: number | null;
      taskExpirationDuration?: number | null;
      eventDueDuration?: number | null;
    } | null;
    notificationConfig?: Array<{
      __typename?: 'TouchpointNotificationConfig';
      plainTextBody: string;
      plainTextRecurrenceBody?: string | null;
      delay?: {
        __typename?: 'TouchpointNotificationDuration';
        value: number;
        unit: ServiceTypes.DurationUnit;
      } | null;
    }> | null;
    rules?:
      | {
          __typename?: 'CompoundCondition';
          type: ServiceTypes.ConditionType;
          compound: ServiceTypes.CompoundOperator;
          operands: Array<
            | {
                __typename?: 'CompoundCondition';
                type: ServiceTypes.ConditionType;
                compound: ServiceTypes.CompoundOperator;
                operands: Array<
                  | { __typename?: 'CompoundCondition' }
                  | {
                      __typename?: 'SimpleCondition';
                      type: ServiceTypes.ConditionType;
                      simpleOperands: Array<any>;
                      simpleOperator: ServiceTypes.SimpleOperator;
                    }
                >;
              }
            | {
                __typename?: 'SimpleCondition';
                type: ServiceTypes.ConditionType;
                simpleOperands: Array<any>;
                simpleOperator: ServiceTypes.SimpleOperator;
              }
          >;
        }
      | {
          __typename?: 'SimpleCondition';
          type: ServiceTypes.ConditionType;
          simpleOperands: Array<any>;
          simpleOperator: ServiceTypes.SimpleOperator;
        }
      | null;
  };
};

export type Icd10AutocompleteQueryVariables = ServiceTypes.Exact<{
  query: ServiceTypes.Scalars['String']['input'];
}>;

export type Icd10AutocompleteQuery = {
  __typename?: 'Query';
  icd10Autocomplete: Array<{
    __typename?: 'ICD10AutocompleteResult';
    code: string;
    name: string;
  }>;
};

export type ObservationDefinitionAutocompleteQueryVariables =
  ServiceTypes.Exact<{
    query: ServiceTypes.Scalars['String']['input'];
    programId?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['UUID']['input']>;
  }>;

export type ObservationDefinitionAutocompleteQuery = {
  __typename?: 'Query';
  observationDefinitionAutocomplete: Array<{
    __typename?: 'ObservationDefinitionAutocompleteResult';
    slug: string;
    resource: {
      __typename?: 'ObservationDefinitionResource';
      dataConfig: any;
      title: string;
    };
  }>;
};

export type ListSpecialtiesQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type ListSpecialtiesQuery = {
  __typename?: 'Query';
  specialties: Array<{ __typename?: 'Specialty'; id: any; name: string }>;
};

export type CareJourneyQueryVariables = ServiceTypes.Exact<{
  careJourneyId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CareJourneyQuery = {
  __typename?: 'Query';
  careJourney: {
    __typename?: 'CareJourney';
    id: any;
    patientId: any;
    programId: any;
    sequentialTouchpoints?: Array<{
      __typename?: 'SequentialTouchpoint';
      id: any;
      task?: {
        __typename?: 'Task';
        id: any;
        resultObject?:
          | { __typename?: 'Appointment' }
          | { __typename?: 'ContentView' }
          | { __typename?: 'FormResponse'; id: any }
          | null;
      } | null;
      tasks?: Array<{
        __typename?: 'Task';
        id: any;
        resultObject?:
          | { __typename?: 'Appointment' }
          | { __typename?: 'ContentView' }
          | { __typename?: 'FormResponse'; id: any }
          | null;
      }> | null;
      sequentialTouchpointTemplate?: {
        __typename?: 'SequentialTouchpointTemplate';
        category: ServiceTypes.TouchpointCategory;
        taskConfig?: {
          __typename?: 'TouchpointTemplateTaskConfig';
          relatedObjectId?: string | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type CareJourneysQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FilterCareJourneyParams;
}>;

export type CareJourneysQuery = {
  __typename?: 'Query';
  careJourneys: Array<{
    __typename?: 'CareJourney';
    id: any;
    programId: any;
    status: ServiceTypes.CareJourneyStatus;
    previousCareJourneyId?: any | null;
    note?: string | null;
    offboardedAt?: any | null;
    retiredAt?: any | null;
    createdAt: any;
    careJourneyTemplateVersion: {
      __typename?: 'CareJourneyTemplateVersion';
      id: any;
      duration: number;
      careJourneyTemplate: {
        __typename?: 'CareJourneyTemplate';
        id: any;
        name: string;
        slug: string;
      };
    };
    sequentialTouchpoints?: Array<{
      __typename?: 'SequentialTouchpoint';
      id: any;
      labelDate?: any | null;
      dueAt?: any | null;
      completedAt?: any | null;
      updatedAt: any;
      position: number;
      note?: string | null;
      status: ServiceTypes.TouchpointStatus;
      endStateDate?: any | null;
      tasks?: Array<{
        __typename?: 'Task';
        resultObjectId?: any | null;
        taskDueAt: any;
      }> | null;
      sequentialTouchpointTemplate?: {
        __typename?: 'SequentialTouchpointTemplate';
        category: ServiceTypes.TouchpointCategory;
        type?: ServiceTypes.TouchpointType | null;
        name: string;
      } | null;
    }> | null;
  }>;
};

export type SequentialTouchpointTemplatesQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FilterSequentialTouchpointTemplateParams;
}>;

export type SequentialTouchpointTemplatesQuery = {
  __typename?: 'Query';
  sequentialTouchpointTemplates: Array<{
    __typename?: 'SequentialTouchpointTemplate';
    id: any;
    name: string;
    position?: number | null;
    programId: any;
    category: ServiceTypes.TouchpointCategory;
  }>;
};

export type ListFormsQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FormFilters;
}>;

export type ListFormsQuery = {
  __typename?: 'Query';
  forms: Array<{
    __typename?: 'Form';
    id: any;
    programId: any;
    slug: string;
    name: string;
    description?: string | null;
    archivedAt?: any | null;
    createdAt: any;
    updatedAt: any;
    publishedVersion?: {
      __typename?: 'FormVersion';
      id: any;
      instructions?: string | null;
      publishedAt?: any | null;
      createdAt: any;
      updatedAt: any;
      config: any;
      programId: any;
    } | null;
    draftVersion: {
      __typename?: 'FormVersion';
      id: any;
      instructions?: string | null;
      publishedAt?: any | null;
      createdAt: any;
      updatedAt: any;
      config: any;
      programId: any;
    };
  }>;
};

export type AgentConversationMessagesQueryVariables = ServiceTypes.Exact<{
  conversationId: ServiceTypes.Scalars['UUID']['input'];
  sorting?: ServiceTypes.InputMaybe<Array<ServiceTypes.SortMessageParam>>;
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
}>;

export type AgentConversationMessagesQuery = {
  __typename?: 'Query';
  agentConversationMessages: {
    __typename?: 'AgentMessagesList';
    totalCount: number;
    items: Array<{
      __typename?: 'AgentMessage';
      body: string;
      id: any;
      recipientType: ServiceTypes.ParticipantType;
      createdAt: any;
    }>;
  };
};

export type GetAgentConversationsQueryVariables = ServiceTypes.Exact<{
  filters?: ServiceTypes.InputMaybe<ServiceTypes.AgentConversationFilters>;
  sorting?: ServiceTypes.InputMaybe<Array<ServiceTypes.SortConversationParam>>;
}>;

export type GetAgentConversationsQuery = {
  __typename?: 'Query';
  agentConversations: {
    __typename?: 'AgentConversationsList';
    totalCount: number;
    items: Array<{
      __typename?: 'AgentConversation';
      id: any;
      messages: Array<{
        __typename?: 'AgentMessage';
        id: any;
        body: string;
        createdAt: any;
        conversationId: any;
        senderType: ServiceTypes.ParticipantType;
      }>;
    }>;
  };
};

export type GetConversationByIdQueryVariables = ServiceTypes.Exact<{
  conversationId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetConversationByIdQuery = {
  __typename?: 'Query';
  conversation: {
    __typename?: 'Conversation';
    id: any;
    externalId: string;
    subject: string;
  };
};

export type FormDestinationsQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FormDestinationFilters;
}>;

export type FormDestinationsQuery = {
  __typename?: 'Query';
  formDestinations: Array<{
    __typename?: 'FormDestination';
    path: string;
    title: string;
    description?: string | null;
    dataConfig: any;
    components?: Array<{
      __typename?: 'FormDestinationItem';
      name: string;
      title: string;
      description?: string | null;
      dataConfig: any;
      calculationConfig?: any | null;
    }> | null;
  }>;
};

export type FormDestinationAutocompleteQueryVariables = ServiceTypes.Exact<{
  query: ServiceTypes.Scalars['String']['input'];
  programId?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['UUID']['input']>;
}>;

export type FormDestinationAutocompleteQuery = {
  __typename?: 'Query';
  formDestinationAutocomplete: Array<{
    __typename?: 'FormDestinationSearchResult';
    dataConfig: any;
    path: string;
    title: string;
    components?: Array<{
      __typename?: 'FormDestinationItem';
      name: string;
      title: string;
      description?: string | null;
      dataConfig: any;
    }> | null;
  }>;
};

export type GetFormDraftByIdQueryVariables = ServiceTypes.Exact<{
  formId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetFormDraftByIdQuery = {
  __typename?: 'Query';
  form: {
    __typename?: 'Form';
    id: any;
    programId: any;
    slug: string;
    name: string;
    description?: string | null;
    archivedAt?: any | null;
    createdAt: any;
    updatedAt: any;
    publishedVersion?: { __typename?: 'FormVersion'; id: any } | null;
    draftVersion: { __typename?: 'FormVersion'; id: any; config: any };
  };
};

export type GetFormResponseByTaskIdQueryVariables = ServiceTypes.Exact<{
  taskId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetFormResponseByTaskIdQuery = {
  __typename?: 'Query';
  formResponseByTaskId?: {
    __typename?: 'FormResponse';
    id: any;
    formVersion: {
      __typename?: 'FormVersion';
      form: { __typename?: 'Form'; name: string };
    };
    task?: {
      __typename?: 'Task';
      name: string;
      status: ServiceTypes.TaskStatus;
    } | null;
    interpretedResponses?: {
      __typename?: 'InterpretedResponses';
      questions: Array<{
        __typename?: 'QuestionAndResponse';
        question: string;
        response?: any | null;
        displayResponse?: string | null;
        destination: string;
        validationSuccess: boolean;
        validationErrors?: Array<string> | null;
        components?: Array<{
          __typename?: 'QuestionAndResponseComponent';
          question?: string | null;
          response: any;
          displayResponse: string;
        }> | null;
      }>;
    } | null;
  } | null;
};

export type ListFormResponsesQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FormResponseFilters;
}>;

export type ListFormResponsesQuery = {
  __typename?: 'Query';
  formResponses: Array<{
    __typename?: 'FormResponse';
    id: any;
    patientId: any;
    formVersion: {
      __typename?: 'FormVersion';
      form: { __typename?: 'Form'; id: any; name: string };
    };
    task?: {
      __typename?: 'Task';
      name: string;
      status: ServiceTypes.TaskStatus;
      id: any;
      taskDueAt: any;
      relatedObjectId?: string | null;
    } | null;
  }>;
};

export type AllergyIntolerancesQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.AllergyIntoleranceFilters;
}>;

export type AllergyIntolerancesQuery = {
  __typename?: 'Query';
  allergyIntolerances: Array<{
    __typename?: 'AllergyIntolerance';
    id: any;
    resource: {
      __typename?: 'AllergyIntoleranceResource';
      code?: { __typename?: 'CodeableConcept'; text?: string | null } | null;
    };
  }>;
};

export type FormResponsesQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FormResponseFilters;
}>;

export type FormResponsesQuery = {
  __typename?: 'Query';
  formResponses: Array<{
    __typename?: 'FormResponse';
    id: any;
    submittedAt?: any | null;
    formVersion: {
      __typename?: 'FormVersion';
      form: { __typename?: 'Form'; name: string };
    };
    task?: { __typename?: 'Task'; id: any } | null;
  }>;
};

export type FullPatientProfileQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type FullPatientProfileQuery = {
  __typename?: 'Query';
  patient: {
    __typename?: 'Patient';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    birthDate: any;
    enrollments: Array<{
      __typename?: 'Enrollment';
      enrollmentStatus: ServiceTypes.EnrollmentStatus;
    }>;
    careJourneys?: Array<{
      __typename?: 'CareJourney';
      status: ServiceTypes.CareJourneyStatus;
      careJourneyTemplateVersion: {
        __typename?: 'CareJourneyTemplateVersion';
        careJourneyTemplate: {
          __typename?: 'CareJourneyTemplate';
          name: string;
        };
      };
    }> | null;
    addresses?: Array<{
      __typename?: 'Address';
      city: string;
      state: ServiceTypes.StateCode;
      line1: string;
      line2?: string | null;
      postalCode: string;
    }> | null;
    phoneNumbers?: Array<{
      __typename?: 'PhoneNumber';
      use: ServiceTypes.ContactUse;
      value: string;
    }> | null;
    emailAddresses?: Array<{
      __typename?: 'EmailAddress';
      use: ServiceTypes.ContactUse;
      value: string;
    }> | null;
  };
};

export type HistoryConditionsQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type HistoryConditionsQuery = {
  __typename?: 'Query';
  observationDefinitions: Array<{
    __typename?: 'ObservationDefinition';
    resource: { __typename?: 'ObservationDefinitionResource'; dataConfig: any };
  }>;
};

export type LatestPrescriptionQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type LatestPrescriptionQuery = {
  __typename?: 'Query';
  latestObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      value?: { __typename?: 'Value'; valueString?: string | null } | null;
    };
  } | null;
};

export type LatestProblemsObservationsQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type LatestProblemsObservationsQuery = {
  __typename?: 'Query';
  latestObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
};

export type LatestProfileObservationsQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type LatestProfileObservationsQuery = {
  __typename?: 'Query';
  pharmacyObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
  externalProvider?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
  heightObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueInteger?: number | null } | null;
      }> | null;
    };
  } | null;
  weightObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueInteger?: number | null } | null;
      }> | null;
    };
  } | null;
  menopauseRatingScaleObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
  menopauseStageObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
  providerNoteObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
};

export type ListConditionsQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type ListConditionsQuery = {
  __typename?: 'Query';
  conditions: Array<{
    __typename?: 'Condition';
    id: any;
    resource: {
      __typename?: 'ConditionResource';
      code?: { __typename?: 'CodeableConcept'; text?: string | null } | null;
    };
  }>;
};

export type ListPatientsQueryVariables = ServiceTypes.Exact<{
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
  sorting?: ServiceTypes.InputMaybe<Array<ServiceTypes.SortPatientParam>>;
  filters?: ServiceTypes.InputMaybe<ServiceTypes.FilterPatientParams>;
}>;

export type ListPatientsQuery = {
  __typename?: 'Query';
  patients: {
    __typename?: 'PatientsList';
    totalCount: number;
    items: Array<{
      __typename?: 'Patient';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      birthDate: any;
      addresses?: Array<{
        __typename?: 'Address';
        state: ServiceTypes.StateCode;
      }> | null;
      careJourneys?: Array<{
        __typename?: 'CareJourney';
        id: any;
        programId: any;
        status: ServiceTypes.CareJourneyStatus;
        careJourneyTemplateVersion: {
          __typename?: 'CareJourneyTemplateVersion';
          careJourneyTemplate: {
            __typename?: 'CareJourneyTemplate';
            name: string;
          };
        };
      }> | null;
    }>;
  };
};

export type ListPatientsWithAlertsQueryVariables = ServiceTypes.Exact<{
  pagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
  filters?: ServiceTypes.InputMaybe<ServiceTypes.ListAlertFilters>;
}>;

export type ListPatientsWithAlertsQuery = {
  __typename?: 'Query';
  alertCountsByPatient: {
    __typename?: 'AlertCountsList';
    totalCount: number;
    items: Array<{
      __typename?: 'PatientAlertCounts';
      lowCount: number;
      mediumCount: number;
      highCount: number;
      patient: {
        __typename?: 'Patient';
        birthDate: any;
        firstName: string;
        id: any;
        lastName: string;
        preferredName?: string | null;
      };
    }>;
  };
};

export type MedicationStatementsQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type MedicationStatementsQuery = {
  __typename?: 'Query';
  medicationStatements: Array<{
    __typename?: 'MedicationStatement';
    id: any;
    resource: {
      __typename?: 'MedicationStatementResource';
      medication: {
        __typename?: 'CodeableReference';
        concept: { __typename?: 'CodeableConcept'; text?: string | null };
      };
    };
  }>;
};

export type MenopauseStagesQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type MenopauseStagesQuery = {
  __typename?: 'Query';
  observationDefinitions: Array<{
    __typename?: 'ObservationDefinition';
    resource: { __typename?: 'ObservationDefinitionResource'; dataConfig: any };
  }>;
};

export type PatientQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type PatientQuery = {
  __typename?: 'Query';
  patient: {
    __typename?: 'Patient';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    birthDate: any;
    addresses?: Array<{
      __typename?: 'Address';
      city: string;
      state: ServiceTypes.StateCode;
      line1: string;
      line2?: string | null;
      postalCode: string;
    }> | null;
    phoneNumbers?: Array<{
      __typename?: 'PhoneNumber';
      use: ServiceTypes.ContactUse;
      value: string;
    }> | null;
    emailAddresses?: Array<{
      __typename?: 'EmailAddress';
      use: ServiceTypes.ContactUse;
      value: string;
    }> | null;
  };
};

export type PatientCareJourneysQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type PatientCareJourneysQuery = {
  __typename?: 'Query';
  patient: {
    __typename?: 'Patient';
    id: any;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    birthDate: any;
    careJourneys?: Array<{
      __typename?: 'CareJourney';
      id: any;
      status: ServiceTypes.CareJourneyStatus;
      careJourneyTemplateVersion: {
        __typename?: 'CareJourneyTemplateVersion';
        careJourneyTemplate: {
          __typename?: 'CareJourneyTemplate';
          id: any;
          name: string;
        };
      };
    }> | null;
  };
};

export type PatientExportQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type PatientExportQuery = {
  __typename?: 'Query';
  patient: {
    __typename?: 'Patient';
    firstName: string;
    lastName: string;
    birthDate: any;
    createdAt: any;
    careJourneys?: Array<{
      __typename?: 'CareJourney';
      status: ServiceTypes.CareJourneyStatus;
      careJourneyTemplateVersion: {
        __typename?: 'CareJourneyTemplateVersion';
        careJourneyTemplate: {
          __typename?: 'CareJourneyTemplate';
          name: string;
        };
      };
    }> | null;
    emailAddresses?: Array<{
      __typename?: 'EmailAddress';
      value: string;
    }> | null;
    phoneNumbers?: Array<{ __typename?: 'PhoneNumber'; value: string }> | null;
  };
  encounters: Array<{
    __typename?: 'Encounter';
    id: any;
    clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
    status: ServiceTypes.EncounterStatus;
    startedAt: any;
    staff: {
      __typename?: 'Staff';
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    };
    appointment?: {
      __typename?: 'Appointment';
      id: any;
      status: ServiceTypes.AppointmentStatus;
      startTime: any;
      appointmentType: {
        __typename?: 'AppointmentType';
        id: any;
        displayName: string;
      };
    } | null;
    clinicalNote: {
      __typename?: 'ClinicalNote';
      body?:
        | {
            __typename?: 'HealthCoachNote';
            asyncNote?: string | null;
            plan?: {
              __typename?: 'HealthCoachPlanNote';
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'PrescriberNote';
            asyncNote?: string | null;
            plan?: {
              __typename?: 'PrescriberPlanNote';
              postVisitSummary?: string | null;
            } | null;
          }
        | { __typename?: 'SOAPNote' }
        | null;
    };
  }>;
  formResponses: Array<{
    __typename?: 'FormResponse';
    id: any;
    createdAt: any;
    task?: { __typename?: 'Task'; name: string } | null;
    interpretedResponses?: {
      __typename?: 'InterpretedResponses';
      questions: Array<{
        __typename?: 'QuestionAndResponse';
        question: string;
        response?: any | null;
        displayResponse?: string | null;
        destination: string;
        validationSuccess: boolean;
        validationErrors?: Array<string> | null;
        components?: Array<{
          __typename?: 'QuestionAndResponseComponent';
          question?: string | null;
          response: any;
          displayResponse: string;
        }> | null;
      }>;
    } | null;
  }>;
};

export type PatientHistoryConditionsQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type PatientHistoryConditionsQuery = {
  __typename?: 'Query';
  latestObservation?: {
    __typename?: 'Observation';
    id: any;
    resource: {
      __typename?: 'ObservationResource';
      component?: Array<{
        __typename?: 'ObservationComponent';
        value?: { __typename?: 'Value'; valueString?: string | null } | null;
      }> | null;
    };
  } | null;
};

export type GetAppointmentByIdQueryVariables = ServiceTypes.Exact<{
  appointmentId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetAppointmentByIdQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'Appointment';
    id: any;
    startTime: any;
    description: string;
    timezone: string;
    patient: {
      __typename?: 'Patient';
      firstName: string;
      lastName: string;
      id: any;
      birthDate: any;
    };
    appointmentType: { __typename?: 'AppointmentType'; name: string };
    staff: {
      __typename?: 'Staff';
      firstName: string;
      lastName: string;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        type: ServiceTypes.ProfessionalLicenseType;
      }>;
    };
  };
};

export type ListAppointmentTypesQueryVariables = ServiceTypes.Exact<{
  input?: ServiceTypes.InputMaybe<ServiceTypes.ListAppointmentTypesInput>;
}>;

export type ListAppointmentTypesQuery = {
  __typename?: 'Query';
  appointmentTypes: Array<{
    __typename?: 'AppointmentType';
    displayName: string;
    id: any;
    name: string;
    slug: string;
    descriptionTemplate: string;
    programId: any;
  }>;
};

export type CronofyProfileQueryVariables = ServiceTypes.Exact<{
  staffId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CronofyProfileQuery = {
  __typename?: 'Query';
  cronofyProfile: {
    __typename?: 'CronofyProfile';
    id: any;
    staffId: any;
    externalUserId: string;
    primaryCalendarId: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type ListFilteredStaffsQueryVariables = ServiceTypes.Exact<{
  input?: ServiceTypes.InputMaybe<ServiceTypes.ListStaffsInput>;
}>;

export type ListFilteredStaffsQuery = {
  __typename?: 'Query';
  staffs: Array<{
    __typename?: 'Staff';
    id: any;
    firstName: string;
    lastName: string;
    profileImageUrl?: string | null;
    role: ServiceTypes.StaffRole;
    emailAddress?: {
      __typename?: 'EmailAddress';
      value: string;
      use: ServiceTypes.ContactUse;
    } | null;
  }>;
};

export type ListStaffWithProgramsQueryVariables = ServiceTypes.Exact<{
  [key: string]: never;
}>;

export type ListStaffWithProgramsQuery = {
  __typename?: 'Query';
  staffs: Array<{
    __typename?: 'Staff';
    id: any;
    createdAt: any;
    updatedAt: any;
    firstName: string;
    lastName: string;
    profileImageUrl?: string | null;
    role: ServiceTypes.StaffRole;
    status: ServiceTypes.StaffStatus;
    emailAddress?: {
      __typename?: 'EmailAddress';
      value: string;
      use: ServiceTypes.ContactUse;
    } | null;
    programs: Array<{ __typename?: 'StaffProgram'; programId: any }>;
  }>;
  programs: Array<{
    __typename?: 'Program';
    name: string;
    slug: string;
    id: any;
  }>;
};

export type StaffQueryVariables = ServiceTypes.Exact<{
  staffId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type StaffQuery = {
  __typename?: 'Query';
  staff: {
    __typename?: 'Staff';
    id: any;
    createdAt: any;
    updatedAt: any;
    firstName: string;
    lastName: string;
    bio?: string | null;
    preferredName?: string | null;
    profileImageUrl?: string | null;
    role: ServiceTypes.StaffRole;
    status: ServiceTypes.StaffStatus;
    npiNumber?: string | null;
    deaNumber?: string | null;
    genderIdentity?: {
      __typename?: 'GenderIdentity';
      value: ServiceTypes.GenderIdentityCode;
      text: string;
    } | null;
    pronouns?: {
      __typename?: 'Pronouns';
      value: ServiceTypes.PronounCode;
      text: string;
    } | null;
    emailAddress?: {
      __typename?: 'EmailAddress';
      value: string;
      use: ServiceTypes.ContactUse;
    } | null;
    programs: Array<{ __typename?: 'StaffProgram'; programId: any }>;
    professionalLicenses: Array<{
      __typename?: 'ProfessionalLicense';
      id: string;
      createdAt: any;
      updatedAt: any;
      type: ServiceTypes.ProfessionalLicenseType;
      licenseState: ServiceTypes.StateCode;
      licenseNumber: string;
      status: ServiceTypes.ProfessionalLicenseStatus;
      expirationDate?: any | null;
      description?: string | null;
    }>;
  };
};

export type WhoAmIQueryVariables = ServiceTypes.Exact<{ [key: string]: never }>;

export type WhoAmIQuery = {
  __typename?: 'Query';
  staffWhoAmI: {
    __typename?: 'Staff';
    id: any;
    authId?: string | null;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    profileImageUrl?: string | null;
    role: ServiceTypes.StaffRole;
    status: ServiceTypes.StaffStatus;
    emailAddress?: {
      __typename?: 'EmailAddress';
      value: string;
      use: ServiceTypes.ContactUse;
    } | null;
    programs: Array<{
      __typename?: 'StaffProgram';
      programId: any;
      program: {
        __typename?: 'Program';
        name: string;
        slug: string;
        config?: {
          __typename?: 'ProgramConfigResponse';
          roleNameMapping?: any | null;
        } | null;
      };
    }>;
  };
};

export type TaskTemplatesQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FilterTaskTemplateParams;
}>;

export type TaskTemplatesQuery = {
  __typename?: 'Query';
  taskTemplates: Array<{
    __typename?: 'TaskTemplate';
    id: any;
    name: string;
    programId: any;
    slug: string;
    role?: ServiceTypes.StaffRole | null;
    category: ServiceTypes.TaskCategory;
    priority: ServiceTypes.TaskPriority;
    description?: string | null;
    relatedObjectType?: ServiceTypes.TaskRelatedObject | null;
    relatedObjectId?: string | null;
    resultObjectType?: ServiceTypes.TaskResultObject | null;
  }>;
};

export type GetTaskQueryVariables = ServiceTypes.Exact<{
  id: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetTaskQuery = {
  __typename?: 'Query';
  task: {
    __typename?: 'Task';
    id: any;
    assigneeId: any;
    reporterId?: any | null;
    patientId: any;
    comments?: string | null;
    taskTemplateId?: any | null;
    name: string;
    status: ServiceTypes.TaskStatus;
    programId: any;
    taskDueAt: any;
    eventDueAt?: any | null;
    taskExpireAt?: any | null;
    role?: ServiceTypes.StaffRole | null;
    category: ServiceTypes.TaskCategory;
    taskType: ServiceTypes.TaskType;
    priority: ServiceTypes.TaskPriority;
    description?: string | null;
    relatedObjectType?: ServiceTypes.TaskRelatedObject | null;
    relatedObjectId?: string | null;
    resultObjectType?: ServiceTypes.TaskResultObject | null;
    resultObjectId?: any | null;
    createdAt: any;
    updatedAt: any;
    assignee:
      | { __typename: 'Patient'; id: any; firstName: string; lastName: string }
      | { __typename: 'Staff'; id: any; firstName: string; lastName: string };
    patient: {
      __typename?: 'Patient';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      birthDate: any;
    };
    program: { __typename?: 'Program'; id: any };
    taskTemplate?: {
      __typename?: 'TaskTemplate';
      id: any;
      name: string;
    } | null;
    taskConfig?: {
      __typename?: 'TaskConfig';
      type: ServiceTypes.TaskType;
    } | null;
    legacyNotificationSchedule?: {
      __typename?: 'NotificationSchedule';
      schedules: Array<{
        __typename?: 'NotificationReminder';
        sequentialOrder: number;
        scheduledAt: any;
        eventName: string;
      }>;
    } | null;
  };
};

export type TimelineTouchpointsQueryVariables = ServiceTypes.Exact<{
  pastTouchpointsFilters: ServiceTypes.FilterSequentialTouchpointParams;
  pastTouchpointsPagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
  pastTouchpointsSorting?: ServiceTypes.InputMaybe<
    Array<ServiceTypes.SortTouchpointParam>
  >;
  currentTouchpointsFilters: ServiceTypes.FilterSequentialTouchpointParams;
  currentTouchpointsPagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
  currentTouchpointsSorting?: ServiceTypes.InputMaybe<
    Array<ServiceTypes.SortTouchpointParam>
  >;
  incomingTouchpointsFilters: ServiceTypes.FilterSequentialTouchpointParams;
  incomingTouchpointsPagination?: ServiceTypes.InputMaybe<ServiceTypes.PaginationParams>;
  incomingTouchpointsSorting?: ServiceTypes.InputMaybe<
    Array<ServiceTypes.SortTouchpointParam>
  >;
}>;

export type TimelineTouchpointsQuery = {
  __typename?: 'Query';
  pastTouchpoints: {
    __typename?: 'SequentialTouchpointList';
    totalCount: number;
    items: Array<{
      __typename?: 'SequentialTouchpoint';
      id: any;
      labelDate?: any | null;
      dueAt?: any | null;
      completedAt?: any | null;
      updatedAt: any;
      position: number;
      note?: string | null;
      status: ServiceTypes.TouchpointStatus;
      endStateDate?: any | null;
      tasks?: Array<{
        __typename?: 'Task';
        resultObjectId?: any | null;
        taskDueAt: any;
      }> | null;
      sequentialTouchpointTemplate?: {
        __typename?: 'SequentialTouchpointTemplate';
        category: ServiceTypes.TouchpointCategory;
        type?: ServiceTypes.TouchpointType | null;
        name: string;
      } | null;
    }>;
  };
  currentTouchpoints: {
    __typename?: 'SequentialTouchpointList';
    totalCount: number;
    items: Array<{
      __typename?: 'SequentialTouchpoint';
      id: any;
      labelDate?: any | null;
      dueAt?: any | null;
      completedAt?: any | null;
      updatedAt: any;
      position: number;
      note?: string | null;
      status: ServiceTypes.TouchpointStatus;
      endStateDate?: any | null;
      tasks?: Array<{
        __typename?: 'Task';
        resultObjectId?: any | null;
        taskDueAt: any;
      }> | null;
      sequentialTouchpointTemplate?: {
        __typename?: 'SequentialTouchpointTemplate';
        category: ServiceTypes.TouchpointCategory;
        type?: ServiceTypes.TouchpointType | null;
        name: string;
      } | null;
    }>;
  };
  incomingTouchpoints: {
    __typename?: 'SequentialTouchpointList';
    totalCount: number;
    items: Array<{
      __typename?: 'SequentialTouchpoint';
      id: any;
      labelDate?: any | null;
      dueAt?: any | null;
      completedAt?: any | null;
      updatedAt: any;
      position: number;
      note?: string | null;
      status: ServiceTypes.TouchpointStatus;
      endStateDate?: any | null;
      tasks?: Array<{
        __typename?: 'Task';
        resultObjectId?: any | null;
        taskDueAt: any;
      }> | null;
      sequentialTouchpointTemplate?: {
        __typename?: 'SequentialTouchpointTemplate';
        category: ServiceTypes.TouchpointCategory;
        type?: ServiceTypes.TouchpointType | null;
        name: string;
      } | null;
    }>;
  };
};

export type TouchpointsTasksQueryVariables = ServiceTypes.Exact<{
  filters: ServiceTypes.FilterSequentialTouchpointParams;
}>;

export type TouchpointsTasksQuery = {
  __typename?: 'Query';
  sequentialTouchpoints: {
    __typename?: 'SequentialTouchpointList';
    items: Array<{
      __typename?: 'SequentialTouchpoint';
      tasks?: Array<{
        __typename?: 'Task';
        id: any;
        taskType: ServiceTypes.TaskType;
        status: ServiceTypes.TaskStatus;
        taskDueAt: any;
      }> | null;
    }>;
  };
};

export type EncounterDetailsQueryVariables = ServiceTypes.Exact<{
  encounterId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type EncounterDetailsQuery = {
  __typename?: 'Query';
  encounter: {
    __typename?: 'Encounter';
    id: any;
    type: ServiceTypes.EncounterType;
    status: ServiceTypes.EncounterStatus;
    startedAt: any;
    updatedAt: any;
    clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
    staff: {
      __typename?: 'Staff';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      role: ServiceTypes.StaffRole;
    };
    appointment?: {
      __typename?: 'Appointment';
      id: any;
      appointmentTypeId: any;
      patientId: any;
      summary: string;
      description: string;
      startTime: any;
      endTime: any;
      status: ServiceTypes.AppointmentStatus;
      videoConferenceUrl?: string | null;
      appointmentType: {
        __typename?: 'AppointmentType';
        id: any;
        name: string;
        slug: string;
        displayName: string;
        duration: number;
        allowedRole: ServiceTypes.StaffRole;
      };
      staff: {
        __typename?: 'Staff';
        id: any;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
        role: ServiceTypes.StaffRole;
      };
    } | null;
    summary?: {
      __typename?: 'EncounterSummary';
      note?: string | null;
      goals?: string | null;
      nextSteps?: string | null;
    } | null;
    clinicalNote: {
      __typename?: 'ClinicalNote';
      body?:
        | {
            __typename?: 'HealthCoachNote';
            asyncNote?: string | null;
            subjectiveObjective?: {
              __typename?: 'HealthCoachSubjectiveObjectiveNote';
              generalSessionNotes?: string | null;
              healthCoachNotes?: string | null;
              preSessionNotes?: string | null;
            } | null;
            plan?: {
              __typename?: 'HealthCoachPlanNote';
              sessionNumber?: string | null;
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'PrescriberNote';
            asyncNote?: string | null;
            subjectiveObjective?: {
              __typename?: 'PrescriberSubjectiveObjectiveNote';
              historyOfPresentIllness?: string | null;
              reviewOfSystems?: string | null;
              medicalHistory?: string | null;
              objective?: string | null;
            } | null;
            assessment?: {
              __typename?: 'PrescriberNoteAssessmentNote';
              notes?: string | null;
            } | null;
            plan?: {
              __typename?: 'PrescriberPlanNote';
              notes?: string | null;
              prescriptions?: string | null;
              labs?: string | null;
              referrals?: string | null;
              healthScreeningCounselling?: string | null;
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'SOAPNote';
            subjectiveObjective?: {
              __typename?: 'SubjectiveObjectiveNote';
              historyOfPresentIllness?: string | null;
              reviewOfSystems?: string | null;
              medicalHistory?: string | null;
              progress?: string | null;
              mentalStatus?: string | null;
            } | null;
            SOAPAssessment?: {
              __typename?: 'AssessmentNote';
              note?: string | null;
              icd10Codes?: Array<{
                __typename?: 'ICD10Code';
                code?: string | null;
                name?: string | null;
              }> | null;
            } | null;
            plan?: {
              __typename?: 'PlanNote';
              note?: string | null;
              prescriptions?: string | null;
              labs?: string | null;
              referrals?: string | null;
            } | null;
          }
        | null;
    };
  };
};

export type EncountersIndexQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type EncountersIndexQuery = {
  __typename?: 'Query';
  encounters: Array<{
    __typename?: 'Encounter';
    id: any;
    startedAt: any;
    type: ServiceTypes.EncounterType;
    clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
    status: ServiceTypes.EncounterStatus;
    staff: {
      __typename?: 'Staff';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    };
    appointment?: {
      __typename?: 'Appointment';
      id: any;
      status: ServiceTypes.AppointmentStatus;
      startTime: any;
      appointmentType: {
        __typename?: 'AppointmentType';
        id: any;
        displayName: string;
      };
    } | null;
  }>;
};

export type UpcomingVisitsQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type UpcomingVisitsQuery = {
  __typename?: 'Query';
  appointments: Array<{
    __typename?: 'Appointment';
    id: any;
    appointmentTypeId: any;
    patientId: any;
    summary: string;
    description: string;
    startTime: any;
    endTime: any;
    status: ServiceTypes.AppointmentStatus;
    videoConferenceUrl?: string | null;
    encounter?: {
      __typename?: 'Encounter';
      id: any;
      status: ServiceTypes.EncounterStatus;
    } | null;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      name: string;
      slug: string;
      displayName: string;
      duration: number;
      allowedRole: ServiceTypes.StaffRole;
    };
    staff: {
      __typename?: 'Staff';
      id: any;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      role: ServiceTypes.StaffRole;
    };
  }>;
};

export const EditableTouchpointSummaryFragmentDoc = gql`
  fragment EditableTouchpointSummary on SequentialTouchpointTemplate {
    id
    updatedAt
    name
    position
    type
    category
    openUponEnrollment
    touchpointFrequency {
      frequency {
        duration
        value
      }
      numberOfRepeats
    }
    taskFrequency {
      numberOfRepeats
      frequency {
        duration
        value
      }
    }
    notificationConfig {
      delay {
        value
      }
    }
  }
`;
export const CareJourneyTemplateSummaryFragmentDoc = gql`
  fragment CareJourneyTemplateSummary on CareJourneyTemplate {
    id
    name
    type
    programId
    updatedAt
    draftVersion {
      id
      touchpointTemplates {
        ...EditableTouchpointSummary
      }
    }
  }
  ${EditableTouchpointSummaryFragmentDoc}
`;
export const NotificationTemplateFragmentDoc = gql`
  fragment NotificationTemplate on TouchpointNotificationConfig {
    plainTextBody
    plainTextRecurrenceBody
    delay {
      unit
      value
    }
  }
`;
export const SimpleRuleFragmentDoc = gql`
  fragment SimpleRule on SimpleCondition {
    simpleOperands: operands
    simpleOperator: operator
    type
  }
`;
export const FinalCompoundRuleFragmentDoc = gql`
  fragment FinalCompoundRule on CompoundCondition {
    operands {
      ...SimpleRule
    }
    compound: operator
    type
  }
  ${SimpleRuleFragmentDoc}
`;
export const CompoundRuleFragmentDoc = gql`
  fragment CompoundRule on CompoundCondition {
    operands {
      ...SimpleRule
      ...FinalCompoundRule
    }
    compound: operator
    type
  }
  ${SimpleRuleFragmentDoc}
  ${FinalCompoundRuleFragmentDoc}
`;
export const EditableTouchpointDetailFragmentDoc = gql`
  fragment EditableTouchpointDetail on SequentialTouchpointTemplate {
    ...EditableTouchpointSummary
    description
    reopenOnEventDateUpdate
    touchpointFrequency {
      frequency {
        duration
        value
      }
      numberOfRepeats
    }
    taskFrequency {
      frequency {
        duration
        value
      }
      numberOfRepeats
    }
    taskConfig {
      relatedObjectId
      taskDueDuration
      taskExpirationDuration
      eventDueDuration
    }
    notificationConfig {
      ...NotificationTemplate
    }
    rules {
      ...CompoundRule
      ...SimpleRule
    }
    confirmedMessage
    declinedMessage
    anchor
    anchorOffset
    anchorTouchpointTemplateId
  }
  ${EditableTouchpointSummaryFragmentDoc}
  ${NotificationTemplateFragmentDoc}
  ${CompoundRuleFragmentDoc}
  ${SimpleRuleFragmentDoc}
`;
export const IntegerObservationFragmentDoc = gql`
  fragment IntegerObservation on Observation {
    id
    resource {
      component {
        value {
          valueInteger
        }
      }
    }
  }
`;
export const StringObservationFragmentDoc = gql`
  fragment StringObservation on Observation {
    id
    resource {
      component {
        value {
          valueString
        }
      }
    }
  }
`;
export const CommonPatientFieldsFragmentDoc = gql`
  fragment CommonPatientFields on Patient {
    id
    firstName
    lastName
    preferredName
    birthDate
    addresses {
      city
      state
      line1
      line2
      postalCode
    }
    phoneNumbers {
      use
      value
    }
    emailAddresses {
      use
      value
    }
  }
`;
export const TouchpointDetailFragmentDoc = gql`
  fragment TouchpointDetail on SequentialTouchpoint {
    id
    labelDate
    dueAt
    completedAt
    updatedAt
    position
    note
    status
    tasks {
      resultObjectId
      taskDueAt
    }
    sequentialTouchpointTemplate {
      category
      type
      name
    }
    endStateDate
  }
`;
export const StaffNameFragmentDoc = gql`
  fragment StaffName on Staff {
    id
    firstName
    lastName
    preferredName
    role
  }
`;
export const VisitSummaryFragmentDoc = gql`
  fragment VisitSummary on Appointment {
    id
    appointmentTypeId
    appointmentType {
      id
      name
      slug
      displayName
      duration
      allowedRole
    }
    patientId
    staff {
      ...StaffName
    }
    summary
    description
    startTime
    endTime
    status
    videoConferenceUrl
  }
  ${StaffNameFragmentDoc}
`;
export const Icd10CodeFragmentDoc = gql`
  fragment ICD10Code on ICD10Code {
    code
    name
  }
`;
export const HistoricalClinicalNoteFragmentDoc = gql`
  fragment HistoricalClinicalNote on SOAPNote {
    subjectiveObjective {
      historyOfPresentIllness
      reviewOfSystems
      medicalHistory
      progress
      mentalStatus
    }
    SOAPAssessment: assessment {
      note
      icd10Codes {
        ...ICD10Code
      }
    }
    plan {
      note
      prescriptions
      labs
      referrals
    }
  }
  ${Icd10CodeFragmentDoc}
`;
export const HealthCoachClinicalNoteFragmentDoc = gql`
  fragment HealthCoachClinicalNote on HealthCoachNote {
    subjectiveObjective {
      generalSessionNotes
      healthCoachNotes
      preSessionNotes
    }
    plan {
      sessionNumber
      postVisitSummary
    }
    asyncNote
  }
`;
export const PrescriberClinicalNoteFragmentDoc = gql`
  fragment PrescriberClinicalNote on PrescriberNote {
    subjectiveObjective {
      historyOfPresentIllness
      reviewOfSystems
      medicalHistory
      objective
    }
    assessment {
      notes
    }
    plan {
      notes
      prescriptions
      labs
      referrals
      healthScreeningCounselling
      postVisitSummary
    }
    asyncNote
  }
`;
export const EncounterDetailFragmentDoc = gql`
  fragment EncounterDetail on Encounter {
    id
    type
    status
    startedAt
    updatedAt
    staff {
      ...StaffName
    }
    appointment {
      ...VisitSummary
    }
    summary {
      note
      goals
      nextSteps
    }
    clinicalNoteType
    clinicalNote {
      body {
        ... on SOAPNote {
          ...HistoricalClinicalNote
        }
        ... on HealthCoachNote {
          ...HealthCoachClinicalNote
        }
        ... on PrescriberNote {
          ...PrescriberClinicalNote
        }
      }
    }
  }
  ${StaffNameFragmentDoc}
  ${VisitSummaryFragmentDoc}
  ${HistoricalClinicalNoteFragmentDoc}
  ${HealthCoachClinicalNoteFragmentDoc}
  ${PrescriberClinicalNoteFragmentDoc}
`;
export const EncounterIndexItemFragmentDoc = gql`
  fragment EncounterIndexItem on Encounter {
    id
    startedAt
    type
    staff {
      id
      firstName
      lastName
      preferredName
    }
    clinicalNoteType
    status
    appointment {
      id
      status
      appointmentType {
        id
        displayName
      }
      startTime
    }
  }
`;
export const VisitSummaryWithEncounterFragmentDoc = gql`
  fragment VisitSummaryWithEncounter on Appointment {
    ...VisitSummary
    encounter {
      id
      status
    }
  }
  ${VisitSummaryFragmentDoc}
`;
export const ArchiveMonitorDocument = gql`
  mutation ArchiveMonitor($archiveMonitorId: UUID!) {
    archiveMonitor(id: $archiveMonitorId) {
      id
    }
  }
`;
export type ArchiveMonitorMutationFn = Apollo.MutationFunction<
  ArchiveMonitorMutation,
  ArchiveMonitorMutationVariables
>;

/**
 * __useArchiveMonitorMutation__
 *
 * To run a mutation, you first call `useArchiveMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMonitorMutation, { data, loading, error }] = useArchiveMonitorMutation({
 *   variables: {
 *      archiveMonitorId: // value for 'archiveMonitorId'
 *   },
 * });
 */
export function useArchiveMonitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveMonitorMutation,
    ArchiveMonitorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveMonitorMutation,
    ArchiveMonitorMutationVariables
  >(ArchiveMonitorDocument, options);
}
export type ArchiveMonitorMutationHookResult = ReturnType<
  typeof useArchiveMonitorMutation
>;
export type ArchiveMonitorMutationResult =
  Apollo.MutationResult<ArchiveMonitorMutation>;
export type ArchiveMonitorMutationOptions = Apollo.BaseMutationOptions<
  ArchiveMonitorMutation,
  ArchiveMonitorMutationVariables
>;
export const CreateMonitorDocument = gql`
  mutation CreateMonitor($input: CreateMonitorInput!) {
    createMonitor(input: $input) {
      id
    }
  }
`;
export type CreateMonitorMutationFn = Apollo.MutationFunction<
  CreateMonitorMutation,
  CreateMonitorMutationVariables
>;

/**
 * __useCreateMonitorMutation__
 *
 * To run a mutation, you first call `useCreateMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonitorMutation, { data, loading, error }] = useCreateMonitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMonitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMonitorMutation,
    CreateMonitorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMonitorMutation,
    CreateMonitorMutationVariables
  >(CreateMonitorDocument, options);
}
export type CreateMonitorMutationHookResult = ReturnType<
  typeof useCreateMonitorMutation
>;
export type CreateMonitorMutationResult =
  Apollo.MutationResult<CreateMonitorMutation>;
export type CreateMonitorMutationOptions = Apollo.BaseMutationOptions<
  CreateMonitorMutation,
  CreateMonitorMutationVariables
>;
export const UpdateMonitorDocument = gql`
  mutation UpdateMonitor($updateMonitorId: UUID!, $input: UpdateMonitorInput!) {
    updateMonitor(id: $updateMonitorId, input: $input) {
      id
    }
  }
`;
export type UpdateMonitorMutationFn = Apollo.MutationFunction<
  UpdateMonitorMutation,
  UpdateMonitorMutationVariables
>;

/**
 * __useUpdateMonitorMutation__
 *
 * To run a mutation, you first call `useUpdateMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonitorMutation, { data, loading, error }] = useUpdateMonitorMutation({
 *   variables: {
 *      updateMonitorId: // value for 'updateMonitorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMonitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMonitorMutation,
    UpdateMonitorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMonitorMutation,
    UpdateMonitorMutationVariables
  >(UpdateMonitorDocument, options);
}
export type UpdateMonitorMutationHookResult = ReturnType<
  typeof useUpdateMonitorMutation
>;
export type UpdateMonitorMutationResult =
  Apollo.MutationResult<UpdateMonitorMutation>;
export type UpdateMonitorMutationOptions = Apollo.BaseMutationOptions<
  UpdateMonitorMutation,
  UpdateMonitorMutationVariables
>;
export const CreateCareJourneyDocument = gql`
  mutation CreateCareJourney($input: CreateCareJourneyInput!) {
    createCareJourney(input: $input) {
      id
    }
  }
`;
export type CreateCareJourneyMutationFn = Apollo.MutationFunction<
  CreateCareJourneyMutation,
  CreateCareJourneyMutationVariables
>;

/**
 * __useCreateCareJourneyMutation__
 *
 * To run a mutation, you first call `useCreateCareJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareJourneyMutation, { data, loading, error }] = useCreateCareJourneyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCareJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCareJourneyMutation,
    CreateCareJourneyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCareJourneyMutation,
    CreateCareJourneyMutationVariables
  >(CreateCareJourneyDocument, options);
}
export type CreateCareJourneyMutationHookResult = ReturnType<
  typeof useCreateCareJourneyMutation
>;
export type CreateCareJourneyMutationResult =
  Apollo.MutationResult<CreateCareJourneyMutation>;
export type CreateCareJourneyMutationOptions = Apollo.BaseMutationOptions<
  CreateCareJourneyMutation,
  CreateCareJourneyMutationVariables
>;
export const CreateCareJourneyTemplateDocument = gql`
  mutation CreateCareJourneyTemplate($input: CreateCareJourneyTemplateInput!) {
    createCareJourneyTemplate(input: $input) {
      id
    }
  }
`;
export type CreateCareJourneyTemplateMutationFn = Apollo.MutationFunction<
  CreateCareJourneyTemplateMutation,
  CreateCareJourneyTemplateMutationVariables
>;

/**
 * __useCreateCareJourneyTemplateMutation__
 *
 * To run a mutation, you first call `useCreateCareJourneyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareJourneyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareJourneyTemplateMutation, { data, loading, error }] = useCreateCareJourneyTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCareJourneyTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCareJourneyTemplateMutation,
    CreateCareJourneyTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCareJourneyTemplateMutation,
    CreateCareJourneyTemplateMutationVariables
  >(CreateCareJourneyTemplateDocument, options);
}
export type CreateCareJourneyTemplateMutationHookResult = ReturnType<
  typeof useCreateCareJourneyTemplateMutation
>;
export type CreateCareJourneyTemplateMutationResult =
  Apollo.MutationResult<CreateCareJourneyTemplateMutation>;
export type CreateCareJourneyTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCareJourneyTemplateMutation,
    CreateCareJourneyTemplateMutationVariables
  >;
export const CreateStubTouchpointTemplateDocument = gql`
  mutation CreateStubTouchpointTemplate(
    $name: String!
    $position: Int!
    $templateVersionId: UUID!
    $category: TouchpointCategory!
    $type: TouchpointType
    $programId: UUID!
  ) {
    createSequentialTouchpointTemplate(
      input: {
        name: $name
        position: $position
        programId: $programId
        careJourneyTemplateVersionId: $templateVersionId
        category: $category
        type: $type
        touchpointFrequency: null
        taskTemplateId: "00000000-0000-0000-0000-000000000000"
      }
    ) {
      ...EditableTouchpointSummary
    }
  }
  ${EditableTouchpointSummaryFragmentDoc}
`;
export type CreateStubTouchpointTemplateMutationFn = Apollo.MutationFunction<
  CreateStubTouchpointTemplateMutation,
  CreateStubTouchpointTemplateMutationVariables
>;

/**
 * __useCreateStubTouchpointTemplateMutation__
 *
 * To run a mutation, you first call `useCreateStubTouchpointTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStubTouchpointTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStubTouchpointTemplateMutation, { data, loading, error }] = useCreateStubTouchpointTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      position: // value for 'position'
 *      templateVersionId: // value for 'templateVersionId'
 *      category: // value for 'category'
 *      type: // value for 'type'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useCreateStubTouchpointTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStubTouchpointTemplateMutation,
    CreateStubTouchpointTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStubTouchpointTemplateMutation,
    CreateStubTouchpointTemplateMutationVariables
  >(CreateStubTouchpointTemplateDocument, options);
}
export type CreateStubTouchpointTemplateMutationHookResult = ReturnType<
  typeof useCreateStubTouchpointTemplateMutation
>;
export type CreateStubTouchpointTemplateMutationResult =
  Apollo.MutationResult<CreateStubTouchpointTemplateMutation>;
export type CreateStubTouchpointTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStubTouchpointTemplateMutation,
    CreateStubTouchpointTemplateMutationVariables
  >;
export const DeleteSequentialTouchpointTemplateDocument = gql`
  mutation DeleteSequentialTouchpointTemplate($id: UUID!) {
    deleteSequentialTouchpointTemplate(id: $id) {
      id
    }
  }
`;
export type DeleteSequentialTouchpointTemplateMutationFn =
  Apollo.MutationFunction<
    DeleteSequentialTouchpointTemplateMutation,
    DeleteSequentialTouchpointTemplateMutationVariables
  >;

/**
 * __useDeleteSequentialTouchpointTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSequentialTouchpointTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSequentialTouchpointTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSequentialTouchpointTemplateMutation, { data, loading, error }] = useDeleteSequentialTouchpointTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSequentialTouchpointTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSequentialTouchpointTemplateMutation,
    DeleteSequentialTouchpointTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSequentialTouchpointTemplateMutation,
    DeleteSequentialTouchpointTemplateMutationVariables
  >(DeleteSequentialTouchpointTemplateDocument, options);
}
export type DeleteSequentialTouchpointTemplateMutationHookResult = ReturnType<
  typeof useDeleteSequentialTouchpointTemplateMutation
>;
export type DeleteSequentialTouchpointTemplateMutationResult =
  Apollo.MutationResult<DeleteSequentialTouchpointTemplateMutation>;
export type DeleteSequentialTouchpointTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSequentialTouchpointTemplateMutation,
    DeleteSequentialTouchpointTemplateMutationVariables
  >;
export const PublishCareJourneyTemplateDocument = gql`
  mutation PublishCareJourneyTemplate($id: UUID!) {
    publishCareJourneyTemplate(id: $id) {
      id
    }
  }
`;
export type PublishCareJourneyTemplateMutationFn = Apollo.MutationFunction<
  PublishCareJourneyTemplateMutation,
  PublishCareJourneyTemplateMutationVariables
>;

/**
 * __usePublishCareJourneyTemplateMutation__
 *
 * To run a mutation, you first call `usePublishCareJourneyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCareJourneyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCareJourneyTemplateMutation, { data, loading, error }] = usePublishCareJourneyTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishCareJourneyTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishCareJourneyTemplateMutation,
    PublishCareJourneyTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishCareJourneyTemplateMutation,
    PublishCareJourneyTemplateMutationVariables
  >(PublishCareJourneyTemplateDocument, options);
}
export type PublishCareJourneyTemplateMutationHookResult = ReturnType<
  typeof usePublishCareJourneyTemplateMutation
>;
export type PublishCareJourneyTemplateMutationResult =
  Apollo.MutationResult<PublishCareJourneyTemplateMutation>;
export type PublishCareJourneyTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    PublishCareJourneyTemplateMutation,
    PublishCareJourneyTemplateMutationVariables
  >;
export const ReorderTouchpointsDocument = gql`
  mutation ReorderTouchpoints(
    $careJourneyTemplateId: UUID!
    $touchpoints: [UUID!]!
  ) {
    updateCareJourneyTemplate(
      id: $careJourneyTemplateId
      input: { touchpointTemplatePositions: $touchpoints }
    ) {
      ...CareJourneyTemplateSummary
    }
  }
  ${CareJourneyTemplateSummaryFragmentDoc}
`;
export type ReorderTouchpointsMutationFn = Apollo.MutationFunction<
  ReorderTouchpointsMutation,
  ReorderTouchpointsMutationVariables
>;

/**
 * __useReorderTouchpointsMutation__
 *
 * To run a mutation, you first call `useReorderTouchpointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderTouchpointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderTouchpointsMutation, { data, loading, error }] = useReorderTouchpointsMutation({
 *   variables: {
 *      careJourneyTemplateId: // value for 'careJourneyTemplateId'
 *      touchpoints: // value for 'touchpoints'
 *   },
 * });
 */
export function useReorderTouchpointsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderTouchpointsMutation,
    ReorderTouchpointsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderTouchpointsMutation,
    ReorderTouchpointsMutationVariables
  >(ReorderTouchpointsDocument, options);
}
export type ReorderTouchpointsMutationHookResult = ReturnType<
  typeof useReorderTouchpointsMutation
>;
export type ReorderTouchpointsMutationResult =
  Apollo.MutationResult<ReorderTouchpointsMutation>;
export type ReorderTouchpointsMutationOptions = Apollo.BaseMutationOptions<
  ReorderTouchpointsMutation,
  ReorderTouchpointsMutationVariables
>;
export const UpdateTouchpointTemplateDocument = gql`
  mutation UpdateTouchpointTemplate(
    $id: UUID!
    $name: String
    $relatedObjectId: String
    $taskDueDuration: Int
    $taskExpirationDuration: Int
    $notifications: [TouchpointNotificationConfigInput!]
    $eventDueDuration: Int
    $description: String
    $touchpointFrequency: TouchpointCadenceInput
    $confirmedMessage: String
    $declinedMessage: String
    $taskFrequency: TaskCadenceInput
    $anchor: AnchorType
    $anchorOffset: Int
    $anchorTouchpointTemplateId: UUID
    $reopenOnEventDateUpdate: Boolean
    $rules: JSON
  ) {
    updateSequentialTouchpointTemplate(
      id: $id
      input: {
        name: $name
        description: $description
        touchpointFrequency: $touchpointFrequency
        taskFrequency: $taskFrequency
        reopenOnEventDateUpdate: $reopenOnEventDateUpdate
        taskConfig: {
          relatedObjectId: $relatedObjectId
          taskDueDuration: $taskDueDuration
          taskExpirationDuration: $taskExpirationDuration
          eventDueDuration: $eventDueDuration
        }
        anchor: $anchor
        anchorOffset: $anchorOffset
        anchorTouchpointTemplateId: $anchorTouchpointTemplateId
        notificationConfig: $notifications
        confirmedMessage: $confirmedMessage
        declinedMessage: $declinedMessage
        rules: $rules
      }
    ) {
      id
    }
  }
`;
export type UpdateTouchpointTemplateMutationFn = Apollo.MutationFunction<
  UpdateTouchpointTemplateMutation,
  UpdateTouchpointTemplateMutationVariables
>;

/**
 * __useUpdateTouchpointTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTouchpointTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTouchpointTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTouchpointTemplateMutation, { data, loading, error }] = useUpdateTouchpointTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      relatedObjectId: // value for 'relatedObjectId'
 *      taskDueDuration: // value for 'taskDueDuration'
 *      taskExpirationDuration: // value for 'taskExpirationDuration'
 *      notifications: // value for 'notifications'
 *      eventDueDuration: // value for 'eventDueDuration'
 *      description: // value for 'description'
 *      touchpointFrequency: // value for 'touchpointFrequency'
 *      confirmedMessage: // value for 'confirmedMessage'
 *      declinedMessage: // value for 'declinedMessage'
 *      taskFrequency: // value for 'taskFrequency'
 *      anchor: // value for 'anchor'
 *      anchorOffset: // value for 'anchorOffset'
 *      anchorTouchpointTemplateId: // value for 'anchorTouchpointTemplateId'
 *      reopenOnEventDateUpdate: // value for 'reopenOnEventDateUpdate'
 *      rules: // value for 'rules'
 *   },
 * });
 */
export function useUpdateTouchpointTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTouchpointTemplateMutation,
    UpdateTouchpointTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTouchpointTemplateMutation,
    UpdateTouchpointTemplateMutationVariables
  >(UpdateTouchpointTemplateDocument, options);
}
export type UpdateTouchpointTemplateMutationHookResult = ReturnType<
  typeof useUpdateTouchpointTemplateMutation
>;
export type UpdateTouchpointTemplateMutationResult =
  Apollo.MutationResult<UpdateTouchpointTemplateMutation>;
export type UpdateTouchpointTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTouchpointTemplateMutation,
    UpdateTouchpointTemplateMutationVariables
  >;
export const CreateSequentialTouchpointDocument = gql`
  mutation CreateSequentialTouchpoint(
    $input: CreateSequentialTouchpointInput!
  ) {
    createSequentialTouchpoint(input: $input) {
      id
      dueAt
      completedAt
      position
      note
      status
    }
  }
`;
export type CreateSequentialTouchpointMutationFn = Apollo.MutationFunction<
  CreateSequentialTouchpointMutation,
  CreateSequentialTouchpointMutationVariables
>;

/**
 * __useCreateSequentialTouchpointMutation__
 *
 * To run a mutation, you first call `useCreateSequentialTouchpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSequentialTouchpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSequentialTouchpointMutation, { data, loading, error }] = useCreateSequentialTouchpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSequentialTouchpointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSequentialTouchpointMutation,
    CreateSequentialTouchpointMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSequentialTouchpointMutation,
    CreateSequentialTouchpointMutationVariables
  >(CreateSequentialTouchpointDocument, options);
}
export type CreateSequentialTouchpointMutationHookResult = ReturnType<
  typeof useCreateSequentialTouchpointMutation
>;
export type CreateSequentialTouchpointMutationResult =
  Apollo.MutationResult<CreateSequentialTouchpointMutation>;
export type CreateSequentialTouchpointMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSequentialTouchpointMutation,
    CreateSequentialTouchpointMutationVariables
  >;
export const DuplicateCareJourneyTemplateDocument = gql`
  mutation DuplicateCareJourneyTemplate(
    $duplicateCareJourneyTemplateId: UUID!
    $input: DuplicateCareJourneyTemplateParams
  ) {
    duplicateCareJourneyTemplate(
      id: $duplicateCareJourneyTemplateId
      input: $input
    ) {
      id
    }
  }
`;
export type DuplicateCareJourneyTemplateMutationFn = Apollo.MutationFunction<
  DuplicateCareJourneyTemplateMutation,
  DuplicateCareJourneyTemplateMutationVariables
>;

/**
 * __useDuplicateCareJourneyTemplateMutation__
 *
 * To run a mutation, you first call `useDuplicateCareJourneyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCareJourneyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCareJourneyTemplateMutation, { data, loading, error }] = useDuplicateCareJourneyTemplateMutation({
 *   variables: {
 *      duplicateCareJourneyTemplateId: // value for 'duplicateCareJourneyTemplateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateCareJourneyTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCareJourneyTemplateMutation,
    DuplicateCareJourneyTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateCareJourneyTemplateMutation,
    DuplicateCareJourneyTemplateMutationVariables
  >(DuplicateCareJourneyTemplateDocument, options);
}
export type DuplicateCareJourneyTemplateMutationHookResult = ReturnType<
  typeof useDuplicateCareJourneyTemplateMutation
>;
export type DuplicateCareJourneyTemplateMutationResult =
  Apollo.MutationResult<DuplicateCareJourneyTemplateMutation>;
export type DuplicateCareJourneyTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    DuplicateCareJourneyTemplateMutation,
    DuplicateCareJourneyTemplateMutationVariables
  >;
export const UpdateSequentialTouchpointDocument = gql`
  mutation UpdateSequentialTouchpoint(
    $updateSequentialTouchpointId: UUID!
    $input: UpdateSequentialTouchpointInput!
  ) {
    updateSequentialTouchpoint(
      id: $updateSequentialTouchpointId
      input: $input
    ) {
      id
      status
      note
      completedAt
    }
  }
`;
export type UpdateSequentialTouchpointMutationFn = Apollo.MutationFunction<
  UpdateSequentialTouchpointMutation,
  UpdateSequentialTouchpointMutationVariables
>;

/**
 * __useUpdateSequentialTouchpointMutation__
 *
 * To run a mutation, you first call `useUpdateSequentialTouchpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSequentialTouchpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSequentialTouchpointMutation, { data, loading, error }] = useUpdateSequentialTouchpointMutation({
 *   variables: {
 *      updateSequentialTouchpointId: // value for 'updateSequentialTouchpointId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSequentialTouchpointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSequentialTouchpointMutation,
    UpdateSequentialTouchpointMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSequentialTouchpointMutation,
    UpdateSequentialTouchpointMutationVariables
  >(UpdateSequentialTouchpointDocument, options);
}
export type UpdateSequentialTouchpointMutationHookResult = ReturnType<
  typeof useUpdateSequentialTouchpointMutation
>;
export type UpdateSequentialTouchpointMutationResult =
  Apollo.MutationResult<UpdateSequentialTouchpointMutation>;
export type UpdateSequentialTouchpointMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSequentialTouchpointMutation,
    UpdateSequentialTouchpointMutationVariables
  >;
export const CreateAppointmentDocument = gql`
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
    }
  }
`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >(CreateAppointmentDocument, options);
}
export type CreateAppointmentMutationHookResult = ReturnType<
  typeof useCreateAppointmentMutation
>;
export type CreateAppointmentMutationResult =
  Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;
export const CreateFormDocument = gql`
  mutation CreateForm($input: CreateFormInput!) {
    createForm(input: $input) {
      id
      programId
      slug
      name
      description
      archivedAt
      createdAt
      updatedAt
    }
  }
`;
export type CreateFormMutationFn = Apollo.MutationFunction<
  CreateFormMutation,
  CreateFormMutationVariables
>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormMutation,
    CreateFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(
    CreateFormDocument,
    options
  );
}
export type CreateFormMutationHookResult = ReturnType<
  typeof useCreateFormMutation
>;
export type CreateFormMutationResult =
  Apollo.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<
  CreateFormMutation,
  CreateFormMutationVariables
>;
export const CreateObservationDefinitionDocument = gql`
  mutation CreateObservationDefinition(
    $input: CreateObservationDefinitionInput!
  ) {
    createObservationDefinition(input: $input) {
      id
      slug
      resource {
        dataConfig
        name
        title
        description
      }
    }
  }
`;
export type CreateObservationDefinitionMutationFn = Apollo.MutationFunction<
  CreateObservationDefinitionMutation,
  CreateObservationDefinitionMutationVariables
>;

/**
 * __useCreateObservationDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateObservationDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateObservationDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createObservationDefinitionMutation, { data, loading, error }] = useCreateObservationDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateObservationDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateObservationDefinitionMutation,
    CreateObservationDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateObservationDefinitionMutation,
    CreateObservationDefinitionMutationVariables
  >(CreateObservationDefinitionDocument, options);
}
export type CreateObservationDefinitionMutationHookResult = ReturnType<
  typeof useCreateObservationDefinitionMutation
>;
export type CreateObservationDefinitionMutationResult =
  Apollo.MutationResult<CreateObservationDefinitionMutation>;
export type CreateObservationDefinitionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateObservationDefinitionMutation,
    CreateObservationDefinitionMutationVariables
  >;
export const DuplicateFormDocument = gql`
  mutation DuplicateForm($duplicateFormId: UUID!) {
    duplicateForm(id: $duplicateFormId) {
      id
      name
      archivedAt
      createdAt
      description
      programId
      slug
      updatedAt
    }
  }
`;
export type DuplicateFormMutationFn = Apollo.MutationFunction<
  DuplicateFormMutation,
  DuplicateFormMutationVariables
>;

/**
 * __useDuplicateFormMutation__
 *
 * To run a mutation, you first call `useDuplicateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFormMutation, { data, loading, error }] = useDuplicateFormMutation({
 *   variables: {
 *      duplicateFormId: // value for 'duplicateFormId'
 *   },
 * });
 */
export function useDuplicateFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateFormMutation,
    DuplicateFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateFormMutation,
    DuplicateFormMutationVariables
  >(DuplicateFormDocument, options);
}
export type DuplicateFormMutationHookResult = ReturnType<
  typeof useDuplicateFormMutation
>;
export type DuplicateFormMutationResult =
  Apollo.MutationResult<DuplicateFormMutation>;
export type DuplicateFormMutationOptions = Apollo.BaseMutationOptions<
  DuplicateFormMutation,
  DuplicateFormMutationVariables
>;
export const UpdateFormDocument = gql`
  mutation UpdateForm($updateFormId: UUID!, $input: UpdateFormInput!) {
    updateForm(id: $updateFormId, input: $input) {
      id
      programId
      slug
      name
      description
      archivedAt
      createdAt
      updatedAt
    }
  }
`;
export type UpdateFormMutationFn = Apollo.MutationFunction<
  UpdateFormMutation,
  UpdateFormMutationVariables
>;

/**
 * __useUpdateFormMutation__
 *
 * To run a mutation, you first call `useUpdateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormMutation, { data, loading, error }] = useUpdateFormMutation({
 *   variables: {
 *      updateFormId: // value for 'updateFormId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormMutation,
    UpdateFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFormMutation, UpdateFormMutationVariables>(
    UpdateFormDocument,
    options
  );
}
export type UpdateFormMutationHookResult = ReturnType<
  typeof useUpdateFormMutation
>;
export type UpdateFormMutationResult =
  Apollo.MutationResult<UpdateFormMutation>;
export type UpdateFormMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormMutation,
  UpdateFormMutationVariables
>;
export const CreateProfileIntegerObservationDocument = gql`
  mutation CreateProfileIntegerObservation(
    $patientId: UUID!
    $programId: UUID!
    $code: String!
    $number: Int!
  ) {
    createObservation(
      input: {
        patientId: $patientId
        programId: $programId
        resource: {
          status: FINAL
          code: {
            coding: [{ code: $code, system: "https://custom.caire.health" }]
          }
          component: [
            {
              value: { valueInteger: $number }
              code: {
                coding: [{ code: $code, system: "https://custom.caire.health" }]
              }
            }
          ]
          value: { valueInteger: $number }
        }
      }
    ) {
      id
    }
  }
`;
export type CreateProfileIntegerObservationMutationFn = Apollo.MutationFunction<
  CreateProfileIntegerObservationMutation,
  CreateProfileIntegerObservationMutationVariables
>;

/**
 * __useCreateProfileIntegerObservationMutation__
 *
 * To run a mutation, you first call `useCreateProfileIntegerObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileIntegerObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileIntegerObservationMutation, { data, loading, error }] = useCreateProfileIntegerObservationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      code: // value for 'code'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useCreateProfileIntegerObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProfileIntegerObservationMutation,
    CreateProfileIntegerObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProfileIntegerObservationMutation,
    CreateProfileIntegerObservationMutationVariables
  >(CreateProfileIntegerObservationDocument, options);
}
export type CreateProfileIntegerObservationMutationHookResult = ReturnType<
  typeof useCreateProfileIntegerObservationMutation
>;
export type CreateProfileIntegerObservationMutationResult =
  Apollo.MutationResult<CreateProfileIntegerObservationMutation>;
export type CreateProfileIntegerObservationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProfileIntegerObservationMutation,
    CreateProfileIntegerObservationMutationVariables
  >;
export const CreateProfileStringObservationDocument = gql`
  mutation CreateProfileStringObservation(
    $patientId: UUID!
    $programId: UUID!
    $code: String!
    $text: String!
  ) {
    createObservation(
      input: {
        patientId: $patientId
        programId: $programId
        resource: {
          status: FINAL
          code: {
            coding: [{ code: $code, system: "https://custom.caire.health" }]
          }
          component: [
            {
              value: { valueString: $text }
              code: {
                coding: [{ code: $code, system: "https://custom.caire.health" }]
              }
            }
          ]
          value: { valueString: $text }
        }
      }
    ) {
      id
    }
  }
`;
export type CreateProfileStringObservationMutationFn = Apollo.MutationFunction<
  CreateProfileStringObservationMutation,
  CreateProfileStringObservationMutationVariables
>;

/**
 * __useCreateProfileStringObservationMutation__
 *
 * To run a mutation, you first call `useCreateProfileStringObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileStringObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileStringObservationMutation, { data, loading, error }] = useCreateProfileStringObservationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      code: // value for 'code'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateProfileStringObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProfileStringObservationMutation,
    CreateProfileStringObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProfileStringObservationMutation,
    CreateProfileStringObservationMutationVariables
  >(CreateProfileStringObservationDocument, options);
}
export type CreateProfileStringObservationMutationHookResult = ReturnType<
  typeof useCreateProfileStringObservationMutation
>;
export type CreateProfileStringObservationMutationResult =
  Apollo.MutationResult<CreateProfileStringObservationMutation>;
export type CreateProfileStringObservationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProfileStringObservationMutation,
    CreateProfileStringObservationMutationVariables
  >;
export const CreateAllergyIntoleranceDocument = gql`
  mutation CreateAllergyIntolerance($input: CreateAllergyIntoleranceInput!) {
    createAllergyIntolerance(input: $input) {
      id
    }
  }
`;
export type CreateAllergyIntoleranceMutationFn = Apollo.MutationFunction<
  CreateAllergyIntoleranceMutation,
  CreateAllergyIntoleranceMutationVariables
>;

/**
 * __useCreateAllergyIntoleranceMutation__
 *
 * To run a mutation, you first call `useCreateAllergyIntoleranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllergyIntoleranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllergyIntoleranceMutation, { data, loading, error }] = useCreateAllergyIntoleranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllergyIntoleranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAllergyIntoleranceMutation,
    CreateAllergyIntoleranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAllergyIntoleranceMutation,
    CreateAllergyIntoleranceMutationVariables
  >(CreateAllergyIntoleranceDocument, options);
}
export type CreateAllergyIntoleranceMutationHookResult = ReturnType<
  typeof useCreateAllergyIntoleranceMutation
>;
export type CreateAllergyIntoleranceMutationResult =
  Apollo.MutationResult<CreateAllergyIntoleranceMutation>;
export type CreateAllergyIntoleranceMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAllergyIntoleranceMutation,
    CreateAllergyIntoleranceMutationVariables
  >;
export const CreateConditionDocument = gql`
  mutation CreateCondition(
    $patientId: UUID!
    $programId: UUID!
    $code: String!
    $conditionName: String!
  ) {
    createCondition(
      input: {
        patientId: $patientId
        programId: $programId
        resource: {
          code: {
            coding: [{ code: $code, system: "http://hl7.org/fhir/sid/icd-10" }]
            text: $conditionName
          }
          clinicalStatus: ACTIVE
          subject: {}
        }
      }
    ) {
      active
      id
      resource {
        code {
          text
        }
      }
    }
  }
`;
export type CreateConditionMutationFn = Apollo.MutationFunction<
  CreateConditionMutation,
  CreateConditionMutationVariables
>;

/**
 * __useCreateConditionMutation__
 *
 * To run a mutation, you first call `useCreateConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionMutation, { data, loading, error }] = useCreateConditionMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      code: // value for 'code'
 *      conditionName: // value for 'conditionName'
 *   },
 * });
 */
export function useCreateConditionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConditionMutation,
    CreateConditionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConditionMutation,
    CreateConditionMutationVariables
  >(CreateConditionDocument, options);
}
export type CreateConditionMutationHookResult = ReturnType<
  typeof useCreateConditionMutation
>;
export type CreateConditionMutationResult =
  Apollo.MutationResult<CreateConditionMutation>;
export type CreateConditionMutationOptions = Apollo.BaseMutationOptions<
  CreateConditionMutation,
  CreateConditionMutationVariables
>;
export const CreateHistoryConditionsObservationDocument = gql`
  mutation CreateHistoryConditionsObservation(
    $patientId: UUID!
    $programId: UUID!
    $conditions: [ObservationComponentInput!]!
  ) {
    createObservation(
      input: {
        patientId: $patientId
        programId: $programId
        resource: {
          code: {
            coding: [
              {
                code: "history_conditions"
                system: "https://custom.caire.health"
              }
            ]
          }
          status: FINAL
          component: $conditions
        }
      }
    ) {
      id
    }
  }
`;
export type CreateHistoryConditionsObservationMutationFn =
  Apollo.MutationFunction<
    CreateHistoryConditionsObservationMutation,
    CreateHistoryConditionsObservationMutationVariables
  >;

/**
 * __useCreateHistoryConditionsObservationMutation__
 *
 * To run a mutation, you first call `useCreateHistoryConditionsObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHistoryConditionsObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHistoryConditionsObservationMutation, { data, loading, error }] = useCreateHistoryConditionsObservationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useCreateHistoryConditionsObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHistoryConditionsObservationMutation,
    CreateHistoryConditionsObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateHistoryConditionsObservationMutation,
    CreateHistoryConditionsObservationMutationVariables
  >(CreateHistoryConditionsObservationDocument, options);
}
export type CreateHistoryConditionsObservationMutationHookResult = ReturnType<
  typeof useCreateHistoryConditionsObservationMutation
>;
export type CreateHistoryConditionsObservationMutationResult =
  Apollo.MutationResult<CreateHistoryConditionsObservationMutation>;
export type CreateHistoryConditionsObservationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateHistoryConditionsObservationMutation,
    CreateHistoryConditionsObservationMutationVariables
  >;
export const CreateLatestPrescriptionDocument = gql`
  mutation CreateLatestPrescription(
    $patientId: UUID!
    $programId: UUID!
    $prescriptionString: String!
  ) {
    createObservation(
      input: {
        patientId: $patientId
        programId: $programId
        resource: {
          status: FINAL
          value: { valueString: $prescriptionString }
          code: {
            coding: [
              { code: "prescriptions", system: "https://custom.caire.health" }
            ]
          }
        }
      }
    ) {
      id
      resource {
        value {
          valueString
        }
      }
    }
  }
`;
export type CreateLatestPrescriptionMutationFn = Apollo.MutationFunction<
  CreateLatestPrescriptionMutation,
  CreateLatestPrescriptionMutationVariables
>;

/**
 * __useCreateLatestPrescriptionMutation__
 *
 * To run a mutation, you first call `useCreateLatestPrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLatestPrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLatestPrescriptionMutation, { data, loading, error }] = useCreateLatestPrescriptionMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      prescriptionString: // value for 'prescriptionString'
 *   },
 * });
 */
export function useCreateLatestPrescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLatestPrescriptionMutation,
    CreateLatestPrescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLatestPrescriptionMutation,
    CreateLatestPrescriptionMutationVariables
  >(CreateLatestPrescriptionDocument, options);
}
export type CreateLatestPrescriptionMutationHookResult = ReturnType<
  typeof useCreateLatestPrescriptionMutation
>;
export type CreateLatestPrescriptionMutationResult =
  Apollo.MutationResult<CreateLatestPrescriptionMutation>;
export type CreateLatestPrescriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLatestPrescriptionMutation,
    CreateLatestPrescriptionMutationVariables
  >;
export const CreateMedicationStatementDocument = gql`
  mutation CreateMedicationStatement($input: CreateMedicationStatementInput!) {
    createMedicationStatement(input: $input) {
      id
      patientId
      programId
      createdAt
      updatedAt
      active
    }
  }
`;
export type CreateMedicationStatementMutationFn = Apollo.MutationFunction<
  CreateMedicationStatementMutation,
  CreateMedicationStatementMutationVariables
>;

/**
 * __useCreateMedicationStatementMutation__
 *
 * To run a mutation, you first call `useCreateMedicationStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicationStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicationStatementMutation, { data, loading, error }] = useCreateMedicationStatementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMedicationStatementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMedicationStatementMutation,
    CreateMedicationStatementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMedicationStatementMutation,
    CreateMedicationStatementMutationVariables
  >(CreateMedicationStatementDocument, options);
}
export type CreateMedicationStatementMutationHookResult = ReturnType<
  typeof useCreateMedicationStatementMutation
>;
export type CreateMedicationStatementMutationResult =
  Apollo.MutationResult<CreateMedicationStatementMutation>;
export type CreateMedicationStatementMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMedicationStatementMutation,
    CreateMedicationStatementMutationVariables
  >;
export const DeleteObservationDocument = gql`
  mutation DeleteObservation($id: UUID!) {
    deleteObservation(id: $id) {
      id
    }
  }
`;
export type DeleteObservationMutationFn = Apollo.MutationFunction<
  DeleteObservationMutation,
  DeleteObservationMutationVariables
>;

/**
 * __useDeleteObservationMutation__
 *
 * To run a mutation, you first call `useDeleteObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObservationMutation, { data, loading, error }] = useDeleteObservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteObservationMutation,
    DeleteObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteObservationMutation,
    DeleteObservationMutationVariables
  >(DeleteObservationDocument, options);
}
export type DeleteObservationMutationHookResult = ReturnType<
  typeof useDeleteObservationMutation
>;
export type DeleteObservationMutationResult =
  Apollo.MutationResult<DeleteObservationMutation>;
export type DeleteObservationMutationOptions = Apollo.BaseMutationOptions<
  DeleteObservationMutation,
  DeleteObservationMutationVariables
>;
export const EndMedicationForPatientDocument = gql`
  mutation EndMedicationForPatient(
    $updateMedicationStatementId: UUID!
    $endDate: String!
  ) {
    updateMedicationStatement(
      id: $updateMedicationStatementId
      input: {
        resource: {
          status: RECORDED
          medication: { concept: { coding: [{ code: null }] } }
          effective: { effectivePeriod: { end: $endDate } }
        }
      }
    ) {
      active
      id
    }
  }
`;
export type EndMedicationForPatientMutationFn = Apollo.MutationFunction<
  EndMedicationForPatientMutation,
  EndMedicationForPatientMutationVariables
>;

/**
 * __useEndMedicationForPatientMutation__
 *
 * To run a mutation, you first call `useEndMedicationForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndMedicationForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endMedicationForPatientMutation, { data, loading, error }] = useEndMedicationForPatientMutation({
 *   variables: {
 *      updateMedicationStatementId: // value for 'updateMedicationStatementId'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEndMedicationForPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndMedicationForPatientMutation,
    EndMedicationForPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EndMedicationForPatientMutation,
    EndMedicationForPatientMutationVariables
  >(EndMedicationForPatientDocument, options);
}
export type EndMedicationForPatientMutationHookResult = ReturnType<
  typeof useEndMedicationForPatientMutation
>;
export type EndMedicationForPatientMutationResult =
  Apollo.MutationResult<EndMedicationForPatientMutation>;
export type EndMedicationForPatientMutationOptions = Apollo.BaseMutationOptions<
  EndMedicationForPatientMutation,
  EndMedicationForPatientMutationVariables
>;
export const InactivateAllergyIntoleranceForPatientDocument = gql`
  mutation InactivateAllergyIntoleranceForPatient(
    $updateAllergyIntoleranceId: UUID!
  ) {
    updateAllergyIntolerance(
      id: $updateAllergyIntoleranceId
      input: { active: false }
    ) {
      id
    }
  }
`;
export type InactivateAllergyIntoleranceForPatientMutationFn =
  Apollo.MutationFunction<
    InactivateAllergyIntoleranceForPatientMutation,
    InactivateAllergyIntoleranceForPatientMutationVariables
  >;

/**
 * __useInactivateAllergyIntoleranceForPatientMutation__
 *
 * To run a mutation, you first call `useInactivateAllergyIntoleranceForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInactivateAllergyIntoleranceForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inactivateAllergyIntoleranceForPatientMutation, { data, loading, error }] = useInactivateAllergyIntoleranceForPatientMutation({
 *   variables: {
 *      updateAllergyIntoleranceId: // value for 'updateAllergyIntoleranceId'
 *   },
 * });
 */
export function useInactivateAllergyIntoleranceForPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InactivateAllergyIntoleranceForPatientMutation,
    InactivateAllergyIntoleranceForPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InactivateAllergyIntoleranceForPatientMutation,
    InactivateAllergyIntoleranceForPatientMutationVariables
  >(InactivateAllergyIntoleranceForPatientDocument, options);
}
export type InactivateAllergyIntoleranceForPatientMutationHookResult =
  ReturnType<typeof useInactivateAllergyIntoleranceForPatientMutation>;
export type InactivateAllergyIntoleranceForPatientMutationResult =
  Apollo.MutationResult<InactivateAllergyIntoleranceForPatientMutation>;
export type InactivateAllergyIntoleranceForPatientMutationOptions =
  Apollo.BaseMutationOptions<
    InactivateAllergyIntoleranceForPatientMutation,
    InactivateAllergyIntoleranceForPatientMutationVariables
  >;
export const RemoveConditionDocument = gql`
  mutation RemoveCondition($id: UUID!) {
    updateCondition(
      id: $id
      input: { resource: { clinicalStatus: INACTIVE, subject: {} } }
    ) {
      active
      id
      resource {
        code {
          text
        }
      }
    }
  }
`;
export type RemoveConditionMutationFn = Apollo.MutationFunction<
  RemoveConditionMutation,
  RemoveConditionMutationVariables
>;

/**
 * __useRemoveConditionMutation__
 *
 * To run a mutation, you first call `useRemoveConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConditionMutation, { data, loading, error }] = useRemoveConditionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveConditionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveConditionMutation,
    RemoveConditionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveConditionMutation,
    RemoveConditionMutationVariables
  >(RemoveConditionDocument, options);
}
export type RemoveConditionMutationHookResult = ReturnType<
  typeof useRemoveConditionMutation
>;
export type RemoveConditionMutationResult =
  Apollo.MutationResult<RemoveConditionMutation>;
export type RemoveConditionMutationOptions = Apollo.BaseMutationOptions<
  RemoveConditionMutation,
  RemoveConditionMutationVariables
>;
export const SupercedeHistoryConditionsObservationDocument = gql`
  mutation SupercedeHistoryConditionsObservation(
    $patientId: UUID!
    $programId: UUID!
    $conditions: [ObservationComponentInput!]!
    $id: UUID!
  ) {
    supercedeObservation(
      id: $id
      input: {
        patientId: $patientId
        programId: $programId
        resource: {
          code: {
            coding: [
              {
                code: "history_conditions"
                system: "https://custom.caire.health"
              }
            ]
          }
          status: FINAL
          component: $conditions
        }
      }
    ) {
      id
    }
  }
`;
export type SupercedeHistoryConditionsObservationMutationFn =
  Apollo.MutationFunction<
    SupercedeHistoryConditionsObservationMutation,
    SupercedeHistoryConditionsObservationMutationVariables
  >;

/**
 * __useSupercedeHistoryConditionsObservationMutation__
 *
 * To run a mutation, you first call `useSupercedeHistoryConditionsObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupercedeHistoryConditionsObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supercedeHistoryConditionsObservationMutation, { data, loading, error }] = useSupercedeHistoryConditionsObservationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      conditions: // value for 'conditions'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupercedeHistoryConditionsObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SupercedeHistoryConditionsObservationMutation,
    SupercedeHistoryConditionsObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SupercedeHistoryConditionsObservationMutation,
    SupercedeHistoryConditionsObservationMutationVariables
  >(SupercedeHistoryConditionsObservationDocument, options);
}
export type SupercedeHistoryConditionsObservationMutationHookResult =
  ReturnType<typeof useSupercedeHistoryConditionsObservationMutation>;
export type SupercedeHistoryConditionsObservationMutationResult =
  Apollo.MutationResult<SupercedeHistoryConditionsObservationMutation>;
export type SupercedeHistoryConditionsObservationMutationOptions =
  Apollo.BaseMutationOptions<
    SupercedeHistoryConditionsObservationMutation,
    SupercedeHistoryConditionsObservationMutationVariables
  >;
export const CancelAppointmentDocument = gql`
  mutation CancelAppointment($cancelAppointmentId: UUID!) {
    cancelAppointment(id: $cancelAppointmentId) {
      id
      status
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      cancelAppointmentId: // value for 'cancelAppointmentId'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const CancelAppointmentFromTouchpointDocument = gql`
  mutation CancelAppointmentFromTouchpoint($cancelAppointmentId: UUID!) {
    cancelAppointment(id: $cancelAppointmentId, input: { taskIgnore: true }) {
      id
      status
    }
  }
`;
export type CancelAppointmentFromTouchpointMutationFn = Apollo.MutationFunction<
  CancelAppointmentFromTouchpointMutation,
  CancelAppointmentFromTouchpointMutationVariables
>;

/**
 * __useCancelAppointmentFromTouchpointMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentFromTouchpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentFromTouchpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentFromTouchpointMutation, { data, loading, error }] = useCancelAppointmentFromTouchpointMutation({
 *   variables: {
 *      cancelAppointmentId: // value for 'cancelAppointmentId'
 *   },
 * });
 */
export function useCancelAppointmentFromTouchpointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentFromTouchpointMutation,
    CancelAppointmentFromTouchpointMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentFromTouchpointMutation,
    CancelAppointmentFromTouchpointMutationVariables
  >(CancelAppointmentFromTouchpointDocument, options);
}
export type CancelAppointmentFromTouchpointMutationHookResult = ReturnType<
  typeof useCancelAppointmentFromTouchpointMutation
>;
export type CancelAppointmentFromTouchpointMutationResult =
  Apollo.MutationResult<CancelAppointmentFromTouchpointMutation>;
export type CancelAppointmentFromTouchpointMutationOptions =
  Apollo.BaseMutationOptions<
    CancelAppointmentFromTouchpointMutation,
    CancelAppointmentFromTouchpointMutationVariables
  >;
export const CreateStaffDocument = gql`
  mutation CreateStaff($input: CreateStaffInput!) {
    createStaff(input: $input) {
      id
      createdAt
      status
      role
    }
  }
`;
export type CreateStaffMutationFn = Apollo.MutationFunction<
  CreateStaffMutation,
  CreateStaffMutationVariables
>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStaffMutation,
    CreateStaffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(
    CreateStaffDocument,
    options
  );
}
export type CreateStaffMutationHookResult = ReturnType<
  typeof useCreateStaffMutation
>;
export type CreateStaffMutationResult =
  Apollo.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = Apollo.BaseMutationOptions<
  CreateStaffMutation,
  CreateStaffMutationVariables
>;
export const LinkStaffAccountDocument = gql`
  mutation LinkStaffAccount {
    linkStaffAccount {
      id
    }
  }
`;
export type LinkStaffAccountMutationFn = Apollo.MutationFunction<
  LinkStaffAccountMutation,
  LinkStaffAccountMutationVariables
>;

/**
 * __useLinkStaffAccountMutation__
 *
 * To run a mutation, you first call `useLinkStaffAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkStaffAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkStaffAccountMutation, { data, loading, error }] = useLinkStaffAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useLinkStaffAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkStaffAccountMutation,
    LinkStaffAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkStaffAccountMutation,
    LinkStaffAccountMutationVariables
  >(LinkStaffAccountDocument, options);
}
export type LinkStaffAccountMutationHookResult = ReturnType<
  typeof useLinkStaffAccountMutation
>;
export type LinkStaffAccountMutationResult =
  Apollo.MutationResult<LinkStaffAccountMutation>;
export type LinkStaffAccountMutationOptions = Apollo.BaseMutationOptions<
  LinkStaffAccountMutation,
  LinkStaffAccountMutationVariables
>;
export const UpdateStaffDocument = gql`
  mutation UpdateStaff($updateStaffId: UUID!, $input: UpdateStaffInput!) {
    updateStaff(id: $updateStaffId, input: $input) {
      id
      updatedAt
      status
      role
    }
  }
`;
export type UpdateStaffMutationFn = Apollo.MutationFunction<
  UpdateStaffMutation,
  UpdateStaffMutationVariables
>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      updateStaffId: // value for 'updateStaffId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStaffMutation,
    UpdateStaffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(
    UpdateStaffDocument,
    options
  );
}
export type UpdateStaffMutationHookResult = ReturnType<
  typeof useUpdateStaffMutation
>;
export type UpdateStaffMutationResult =
  Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<
  UpdateStaffMutation,
  UpdateStaffMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  );
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>;
export type CreateTaskMutationResult =
  Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($taskId: UUID!, $input: UpdateTaskInput!) {
    updateTask(taskId: $taskId, input: $input) {
      id
      assigneeId
      assignee {
        ... on Patient {
          id
          firstName
          lastName
          __typename
        }
        ... on Staff {
          id
          firstName
          lastName
          __typename
        }
      }
      patient {
        id
        firstName
        lastName
        preferredName
        birthDate
      }
      program {
        id
      }
      reporterId
      patientId
      comments
      taskTemplateId
      taskTemplate {
        id
        name
      }
      name
      status
      programId
      taskDueAt
      eventDueAt
      taskExpireAt
      role
      taskConfig {
        type
      }
      category
      taskType
      priority
      description
      relatedObjectType
      relatedObjectId
      resultObjectType
      resultObjectId
      createdAt
      updatedAt
      legacyNotificationSchedule {
        schedules {
          sequentialOrder
          scheduledAt
          eventName
        }
      }
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options
  );
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
  Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateAppointmentStatusDocument = gql`
  mutation UpdateAppointmentStatus(
    $appointmentId: UUID!
    $status: AppointmentStatus!
  ) {
    updateAppointmentStatus(id: $appointmentId, status: $status) {
      id
      status
    }
  }
`;
export type UpdateAppointmentStatusMutationFn = Apollo.MutationFunction<
  UpdateAppointmentStatusMutation,
  UpdateAppointmentStatusMutationVariables
>;

/**
 * __useUpdateAppointmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentStatusMutation, { data, loading, error }] = useUpdateAppointmentStatusMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAppointmentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentStatusMutation,
    UpdateAppointmentStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentStatusMutation,
    UpdateAppointmentStatusMutationVariables
  >(UpdateAppointmentStatusDocument, options);
}
export type UpdateAppointmentStatusMutationHookResult = ReturnType<
  typeof useUpdateAppointmentStatusMutation
>;
export type UpdateAppointmentStatusMutationResult =
  Apollo.MutationResult<UpdateAppointmentStatusMutation>;
export type UpdateAppointmentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentStatusMutation,
  UpdateAppointmentStatusMutationVariables
>;
export const UpsertCronofyProfileDocument = gql`
  mutation UpsertCronofyProfile($input: UpsertCronofyProfileInput!) {
    upsertCronofyProfile(input: $input) {
      id
      staffId
      externalUserId
      primaryCalendarId
      createdAt
      updatedAt
    }
  }
`;
export type UpsertCronofyProfileMutationFn = Apollo.MutationFunction<
  UpsertCronofyProfileMutation,
  UpsertCronofyProfileMutationVariables
>;

/**
 * __useUpsertCronofyProfileMutation__
 *
 * To run a mutation, you first call `useUpsertCronofyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCronofyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCronofyProfileMutation, { data, loading, error }] = useUpsertCronofyProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCronofyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCronofyProfileMutation,
    UpsertCronofyProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertCronofyProfileMutation,
    UpsertCronofyProfileMutationVariables
  >(UpsertCronofyProfileDocument, options);
}
export type UpsertCronofyProfileMutationHookResult = ReturnType<
  typeof useUpsertCronofyProfileMutation
>;
export type UpsertCronofyProfileMutationResult =
  Apollo.MutationResult<UpsertCronofyProfileMutation>;
export type UpsertCronofyProfileMutationOptions = Apollo.BaseMutationOptions<
  UpsertCronofyProfileMutation,
  UpsertCronofyProfileMutationVariables
>;
export const CompleteEncounterDocument = gql`
  mutation CompleteEncounter($id: UUID!) {
    completeEncounter(id: $id) {
      ...EncounterDetail
    }
  }
  ${EncounterDetailFragmentDoc}
`;
export type CompleteEncounterMutationFn = Apollo.MutationFunction<
  CompleteEncounterMutation,
  CompleteEncounterMutationVariables
>;

/**
 * __useCompleteEncounterMutation__
 *
 * To run a mutation, you first call `useCompleteEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEncounterMutation, { data, loading, error }] = useCompleteEncounterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteEncounterMutation,
    CompleteEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteEncounterMutation,
    CompleteEncounterMutationVariables
  >(CompleteEncounterDocument, options);
}
export type CompleteEncounterMutationHookResult = ReturnType<
  typeof useCompleteEncounterMutation
>;
export type CompleteEncounterMutationResult =
  Apollo.MutationResult<CompleteEncounterMutation>;
export type CompleteEncounterMutationOptions = Apollo.BaseMutationOptions<
  CompleteEncounterMutation,
  CompleteEncounterMutationVariables
>;
export const CreateAsyncEncounterDocument = gql`
  mutation CreateAsyncEncounter(
    $programId: UUID!
    $staffId: UUID!
    $patientId: UUID!
    $clinicalNoteType: ClinicalNoteType!
  ) {
    createEncounter(
      input: {
        programId: $programId
        staffId: $staffId
        patientId: $patientId
        type: MESSAGE
        clinicalNoteType: $clinicalNoteType
      }
    ) {
      id
    }
  }
`;
export type CreateAsyncEncounterMutationFn = Apollo.MutationFunction<
  CreateAsyncEncounterMutation,
  CreateAsyncEncounterMutationVariables
>;

/**
 * __useCreateAsyncEncounterMutation__
 *
 * To run a mutation, you first call `useCreateAsyncEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAsyncEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAsyncEncounterMutation, { data, loading, error }] = useCreateAsyncEncounterMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      clinicalNoteType: // value for 'clinicalNoteType'
 *   },
 * });
 */
export function useCreateAsyncEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAsyncEncounterMutation,
    CreateAsyncEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAsyncEncounterMutation,
    CreateAsyncEncounterMutationVariables
  >(CreateAsyncEncounterDocument, options);
}
export type CreateAsyncEncounterMutationHookResult = ReturnType<
  typeof useCreateAsyncEncounterMutation
>;
export type CreateAsyncEncounterMutationResult =
  Apollo.MutationResult<CreateAsyncEncounterMutation>;
export type CreateAsyncEncounterMutationOptions = Apollo.BaseMutationOptions<
  CreateAsyncEncounterMutation,
  CreateAsyncEncounterMutationVariables
>;
export const CreateEncounterForAppointmentDocument = gql`
  mutation CreateEncounterForAppointment(
    $programId: UUID!
    $staffId: UUID!
    $patientId: UUID!
    $appointmentId: UUID!
    $clinicalNoteType: ClinicalNoteType!
  ) {
    createEncounter(
      input: {
        programId: $programId
        staffId: $staffId
        patientId: $patientId
        appointmentId: $appointmentId
        type: VIDEO
        clinicalNoteType: $clinicalNoteType
      }
    ) {
      id
    }
  }
`;
export type CreateEncounterForAppointmentMutationFn = Apollo.MutationFunction<
  CreateEncounterForAppointmentMutation,
  CreateEncounterForAppointmentMutationVariables
>;

/**
 * __useCreateEncounterForAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateEncounterForAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEncounterForAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEncounterForAppointmentMutation, { data, loading, error }] = useCreateEncounterForAppointmentMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      appointmentId: // value for 'appointmentId'
 *      clinicalNoteType: // value for 'clinicalNoteType'
 *   },
 * });
 */
export function useCreateEncounterForAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEncounterForAppointmentMutation,
    CreateEncounterForAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEncounterForAppointmentMutation,
    CreateEncounterForAppointmentMutationVariables
  >(CreateEncounterForAppointmentDocument, options);
}
export type CreateEncounterForAppointmentMutationHookResult = ReturnType<
  typeof useCreateEncounterForAppointmentMutation
>;
export type CreateEncounterForAppointmentMutationResult =
  Apollo.MutationResult<CreateEncounterForAppointmentMutation>;
export type CreateEncounterForAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEncounterForAppointmentMutation,
    CreateEncounterForAppointmentMutationVariables
  >;
export const UpdateHealthCoachAsyncEncounterDocument = gql`
  mutation UpdateHealthCoachAsyncEncounter($id: UUID!, $note: String!) {
    updateEncounter(
      id: $id
      input: { clinicalNote: { healthCoachNoteBody: { asyncNote: $note } } }
    ) {
      id
      status
    }
  }
`;
export type UpdateHealthCoachAsyncEncounterMutationFn = Apollo.MutationFunction<
  UpdateHealthCoachAsyncEncounterMutation,
  UpdateHealthCoachAsyncEncounterMutationVariables
>;

/**
 * __useUpdateHealthCoachAsyncEncounterMutation__
 *
 * To run a mutation, you first call `useUpdateHealthCoachAsyncEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthCoachAsyncEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthCoachAsyncEncounterMutation, { data, loading, error }] = useUpdateHealthCoachAsyncEncounterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateHealthCoachAsyncEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHealthCoachAsyncEncounterMutation,
    UpdateHealthCoachAsyncEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHealthCoachAsyncEncounterMutation,
    UpdateHealthCoachAsyncEncounterMutationVariables
  >(UpdateHealthCoachAsyncEncounterDocument, options);
}
export type UpdateHealthCoachAsyncEncounterMutationHookResult = ReturnType<
  typeof useUpdateHealthCoachAsyncEncounterMutation
>;
export type UpdateHealthCoachAsyncEncounterMutationResult =
  Apollo.MutationResult<UpdateHealthCoachAsyncEncounterMutation>;
export type UpdateHealthCoachAsyncEncounterMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateHealthCoachAsyncEncounterMutation,
    UpdateHealthCoachAsyncEncounterMutationVariables
  >;
export const UpdateHealthCoachVisitEncounterDocument = gql`
  mutation UpdateHealthCoachVisitEncounter(
    $id: UUID!
    $preSessionNotes: String!
    $generalSessionNotes: String!
    $healthCoachNotes: String!
    $postVisitSummary: String!
    $sessionNumber: String!
  ) {
    updateEncounter(
      id: $id
      input: {
        clinicalNote: {
          healthCoachNoteBody: {
            subjectiveObjective: {
              preSessionNotes: $preSessionNotes
              generalSessionNotes: $generalSessionNotes
              healthCoachNotes: $healthCoachNotes
            }
            plan: {
              sessionNumber: $sessionNumber
              postVisitSummary: $postVisitSummary
            }
          }
        }
      }
    ) {
      id
      status
    }
  }
`;
export type UpdateHealthCoachVisitEncounterMutationFn = Apollo.MutationFunction<
  UpdateHealthCoachVisitEncounterMutation,
  UpdateHealthCoachVisitEncounterMutationVariables
>;

/**
 * __useUpdateHealthCoachVisitEncounterMutation__
 *
 * To run a mutation, you first call `useUpdateHealthCoachVisitEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthCoachVisitEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthCoachVisitEncounterMutation, { data, loading, error }] = useUpdateHealthCoachVisitEncounterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      preSessionNotes: // value for 'preSessionNotes'
 *      generalSessionNotes: // value for 'generalSessionNotes'
 *      healthCoachNotes: // value for 'healthCoachNotes'
 *      postVisitSummary: // value for 'postVisitSummary'
 *      sessionNumber: // value for 'sessionNumber'
 *   },
 * });
 */
export function useUpdateHealthCoachVisitEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHealthCoachVisitEncounterMutation,
    UpdateHealthCoachVisitEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHealthCoachVisitEncounterMutation,
    UpdateHealthCoachVisitEncounterMutationVariables
  >(UpdateHealthCoachVisitEncounterDocument, options);
}
export type UpdateHealthCoachVisitEncounterMutationHookResult = ReturnType<
  typeof useUpdateHealthCoachVisitEncounterMutation
>;
export type UpdateHealthCoachVisitEncounterMutationResult =
  Apollo.MutationResult<UpdateHealthCoachVisitEncounterMutation>;
export type UpdateHealthCoachVisitEncounterMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateHealthCoachVisitEncounterMutation,
    UpdateHealthCoachVisitEncounterMutationVariables
  >;
export const UpdateHistoricalVisitEncounterDocument = gql`
  mutation UpdateHistoricalVisitEncounter(
    $id: UUID!
    $summaryNote: String!
    $goals: String!
    $nextSteps: String!
    $historyOfPresentIllness: String!
    $reviewOfSystems: String!
    $medicalHistory: String!
    $progress: String!
    $mentalStatus: String!
    $assessmentNote: String!
    $icd10Codes: [ICD10CodeInput!]!
    $planNote: String!
    $prescriptions: String!
    $labs: String!
    $referrals: String!
  ) {
    updateEncounter(
      id: $id
      input: {
        summary: { note: $summaryNote, goals: $goals, nextSteps: $nextSteps }
        clinicalNote: {
          historicalNoteBody: {
            subjectiveObjective: {
              historyOfPresentIllness: $historyOfPresentIllness
              reviewOfSystems: $reviewOfSystems
              medicalHistory: $medicalHistory
              progress: $progress
              mentalStatus: $mentalStatus
            }
            assessment: { note: $assessmentNote, icd10Codes: $icd10Codes }
            plan: {
              note: $planNote
              prescriptions: $prescriptions
              labs: $labs
              referrals: $referrals
            }
          }
        }
      }
    ) {
      id
      status
    }
  }
`;
export type UpdateHistoricalVisitEncounterMutationFn = Apollo.MutationFunction<
  UpdateHistoricalVisitEncounterMutation,
  UpdateHistoricalVisitEncounterMutationVariables
>;

/**
 * __useUpdateHistoricalVisitEncounterMutation__
 *
 * To run a mutation, you first call `useUpdateHistoricalVisitEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistoricalVisitEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHistoricalVisitEncounterMutation, { data, loading, error }] = useUpdateHistoricalVisitEncounterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      summaryNote: // value for 'summaryNote'
 *      goals: // value for 'goals'
 *      nextSteps: // value for 'nextSteps'
 *      historyOfPresentIllness: // value for 'historyOfPresentIllness'
 *      reviewOfSystems: // value for 'reviewOfSystems'
 *      medicalHistory: // value for 'medicalHistory'
 *      progress: // value for 'progress'
 *      mentalStatus: // value for 'mentalStatus'
 *      assessmentNote: // value for 'assessmentNote'
 *      icd10Codes: // value for 'icd10Codes'
 *      planNote: // value for 'planNote'
 *      prescriptions: // value for 'prescriptions'
 *      labs: // value for 'labs'
 *      referrals: // value for 'referrals'
 *   },
 * });
 */
export function useUpdateHistoricalVisitEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHistoricalVisitEncounterMutation,
    UpdateHistoricalVisitEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHistoricalVisitEncounterMutation,
    UpdateHistoricalVisitEncounterMutationVariables
  >(UpdateHistoricalVisitEncounterDocument, options);
}
export type UpdateHistoricalVisitEncounterMutationHookResult = ReturnType<
  typeof useUpdateHistoricalVisitEncounterMutation
>;
export type UpdateHistoricalVisitEncounterMutationResult =
  Apollo.MutationResult<UpdateHistoricalVisitEncounterMutation>;
export type UpdateHistoricalVisitEncounterMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateHistoricalVisitEncounterMutation,
    UpdateHistoricalVisitEncounterMutationVariables
  >;
export const UpdatePrescriberAsyncEncounterDocument = gql`
  mutation UpdatePrescriberAsyncEncounter($id: UUID!, $note: String!) {
    updateEncounter(
      id: $id
      input: { clinicalNote: { prescriberNoteBody: { asyncNote: $note } } }
    ) {
      id
      status
    }
  }
`;
export type UpdatePrescriberAsyncEncounterMutationFn = Apollo.MutationFunction<
  UpdatePrescriberAsyncEncounterMutation,
  UpdatePrescriberAsyncEncounterMutationVariables
>;

/**
 * __useUpdatePrescriberAsyncEncounterMutation__
 *
 * To run a mutation, you first call `useUpdatePrescriberAsyncEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrescriberAsyncEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrescriberAsyncEncounterMutation, { data, loading, error }] = useUpdatePrescriberAsyncEncounterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdatePrescriberAsyncEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrescriberAsyncEncounterMutation,
    UpdatePrescriberAsyncEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrescriberAsyncEncounterMutation,
    UpdatePrescriberAsyncEncounterMutationVariables
  >(UpdatePrescriberAsyncEncounterDocument, options);
}
export type UpdatePrescriberAsyncEncounterMutationHookResult = ReturnType<
  typeof useUpdatePrescriberAsyncEncounterMutation
>;
export type UpdatePrescriberAsyncEncounterMutationResult =
  Apollo.MutationResult<UpdatePrescriberAsyncEncounterMutation>;
export type UpdatePrescriberAsyncEncounterMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePrescriberAsyncEncounterMutation,
    UpdatePrescriberAsyncEncounterMutationVariables
  >;
export const UpdatePrescriberVisitEncounterDocument = gql`
  mutation UpdatePrescriberVisitEncounter(
    $id: UUID!
    $historyOfPresentIllness: String!
    $reviewOfSystems: String!
    $medicalHistory: String!
    $objective: String!
    $assessmentNote: String!
    $planNote: String!
    $prescriptions: String!
    $labs: String!
    $referrals: String!
    $healthScreeningCounselling: String!
    $postVisitSummary: String!
  ) {
    updateEncounter(
      id: $id
      input: {
        clinicalNote: {
          prescriberNoteBody: {
            subjectiveObjective: {
              historyOfPresentIllness: $historyOfPresentIllness
              reviewOfSystems: $reviewOfSystems
              medicalHistory: $medicalHistory
              objective: $objective
            }
            assessment: { notes: $assessmentNote }
            plan: {
              notes: $planNote
              prescriptions: $prescriptions
              labs: $labs
              referrals: $referrals
              healthScreeningCounselling: $healthScreeningCounselling
              postVisitSummary: $postVisitSummary
            }
          }
        }
      }
    ) {
      id
      status
    }
  }
`;
export type UpdatePrescriberVisitEncounterMutationFn = Apollo.MutationFunction<
  UpdatePrescriberVisitEncounterMutation,
  UpdatePrescriberVisitEncounterMutationVariables
>;

/**
 * __useUpdatePrescriberVisitEncounterMutation__
 *
 * To run a mutation, you first call `useUpdatePrescriberVisitEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrescriberVisitEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrescriberVisitEncounterMutation, { data, loading, error }] = useUpdatePrescriberVisitEncounterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      historyOfPresentIllness: // value for 'historyOfPresentIllness'
 *      reviewOfSystems: // value for 'reviewOfSystems'
 *      medicalHistory: // value for 'medicalHistory'
 *      objective: // value for 'objective'
 *      assessmentNote: // value for 'assessmentNote'
 *      planNote: // value for 'planNote'
 *      prescriptions: // value for 'prescriptions'
 *      labs: // value for 'labs'
 *      referrals: // value for 'referrals'
 *      healthScreeningCounselling: // value for 'healthScreeningCounselling'
 *      postVisitSummary: // value for 'postVisitSummary'
 *   },
 * });
 */
export function useUpdatePrescriberVisitEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrescriberVisitEncounterMutation,
    UpdatePrescriberVisitEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrescriberVisitEncounterMutation,
    UpdatePrescriberVisitEncounterMutationVariables
  >(UpdatePrescriberVisitEncounterDocument, options);
}
export type UpdatePrescriberVisitEncounterMutationHookResult = ReturnType<
  typeof useUpdatePrescriberVisitEncounterMutation
>;
export type UpdatePrescriberVisitEncounterMutationResult =
  Apollo.MutationResult<UpdatePrescriberVisitEncounterMutation>;
export type UpdatePrescriberVisitEncounterMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePrescriberVisitEncounterMutation,
    UpdatePrescriberVisitEncounterMutationVariables
  >;
export const ListAlertsByPatientDocument = gql`
  query ListAlertsByPatient(
    $filters: ListAlertFilters
    $pagination: PaginationParams
  ) {
    alerts(filters: $filters, pagination: $pagination) {
      items {
        priority
        name
        createdAt
        id
      }
    }
  }
`;

/**
 * __useListAlertsByPatientQuery__
 *
 * To run a query within a React component, call `useListAlertsByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAlertsByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlertsByPatientQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListAlertsByPatientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAlertsByPatientQuery,
    ListAlertsByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAlertsByPatientQuery,
    ListAlertsByPatientQueryVariables
  >(ListAlertsByPatientDocument, options);
}
export function useListAlertsByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAlertsByPatientQuery,
    ListAlertsByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAlertsByPatientQuery,
    ListAlertsByPatientQueryVariables
  >(ListAlertsByPatientDocument, options);
}
export type ListAlertsByPatientQueryHookResult = ReturnType<
  typeof useListAlertsByPatientQuery
>;
export type ListAlertsByPatientLazyQueryHookResult = ReturnType<
  typeof useListAlertsByPatientLazyQuery
>;
export type ListAlertsByPatientQueryResult = Apollo.QueryResult<
  ListAlertsByPatientQuery,
  ListAlertsByPatientQueryVariables
>;
export const ListMonitorsDocument = gql`
  query ListMonitors(
    $filters: ListMonitorFilters
    $pagination: PaginationParams
    $sorting: [SortMonitorParam!]
  ) {
    monitors(filters: $filters, pagination: $pagination, sorting: $sorting) {
      totalCount
      items {
        id
        programId
        careJourneyId
        patientId
        type
        scope
        name
        audience
        staffClinicalMessageTemplate
        staffNonclinicalMessageTemplate
        patientMessageTemplate
        priority
        muted
      }
    }
  }
`;

/**
 * __useListMonitorsQuery__
 *
 * To run a query within a React component, call `useListMonitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMonitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMonitorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useListMonitorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMonitorsQuery,
    ListMonitorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMonitorsQuery, ListMonitorsQueryVariables>(
    ListMonitorsDocument,
    options
  );
}
export function useListMonitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMonitorsQuery,
    ListMonitorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMonitorsQuery, ListMonitorsQueryVariables>(
    ListMonitorsDocument,
    options
  );
}
export type ListMonitorsQueryHookResult = ReturnType<
  typeof useListMonitorsQuery
>;
export type ListMonitorsLazyQueryHookResult = ReturnType<
  typeof useListMonitorsLazyQuery
>;
export type ListMonitorsQueryResult = Apollo.QueryResult<
  ListMonitorsQuery,
  ListMonitorsQueryVariables
>;
export const MonitorDocument = gql`
  query Monitor($monitorId: UUID!) {
    monitor(id: $monitorId) {
      id
      tenantId
      programId
      careJourneyId
      patientId
      type
      scope
      name
      audience
      staffClinicalMessageTemplate
      staffNonclinicalMessageTemplate
      patientMessageTemplate
      priority
      muted
      rules {
        ...CompoundRule
        ...SimpleRule
      }
    }
  }
  ${CompoundRuleFragmentDoc}
  ${SimpleRuleFragmentDoc}
`;

/**
 * __useMonitorQuery__
 *
 * To run a query within a React component, call `useMonitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorQuery({
 *   variables: {
 *      monitorId: // value for 'monitorId'
 *   },
 * });
 */
export function useMonitorQuery(
  baseOptions: Apollo.QueryHookOptions<MonitorQuery, MonitorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MonitorQuery, MonitorQueryVariables>(
    MonitorDocument,
    options
  );
}
export function useMonitorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MonitorQuery, MonitorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MonitorQuery, MonitorQueryVariables>(
    MonitorDocument,
    options
  );
}
export type MonitorQueryHookResult = ReturnType<typeof useMonitorQuery>;
export type MonitorLazyQueryHookResult = ReturnType<typeof useMonitorLazyQuery>;
export type MonitorQueryResult = Apollo.QueryResult<
  MonitorQuery,
  MonitorQueryVariables
>;
export const CareJourneyTemplateDocument = gql`
  query CareJourneyTemplate($id: UUID!) {
    careJourneyTemplate(id: $id) {
      ...CareJourneyTemplateSummary
    }
  }
  ${CareJourneyTemplateSummaryFragmentDoc}
`;

/**
 * __useCareJourneyTemplateQuery__
 *
 * To run a query within a React component, call `useCareJourneyTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneyTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneyTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareJourneyTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareJourneyTemplateQuery,
    CareJourneyTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CareJourneyTemplateQuery,
    CareJourneyTemplateQueryVariables
  >(CareJourneyTemplateDocument, options);
}
export function useCareJourneyTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareJourneyTemplateQuery,
    CareJourneyTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareJourneyTemplateQuery,
    CareJourneyTemplateQueryVariables
  >(CareJourneyTemplateDocument, options);
}
export type CareJourneyTemplateQueryHookResult = ReturnType<
  typeof useCareJourneyTemplateQuery
>;
export type CareJourneyTemplateLazyQueryHookResult = ReturnType<
  typeof useCareJourneyTemplateLazyQuery
>;
export type CareJourneyTemplateQueryResult = Apollo.QueryResult<
  CareJourneyTemplateQuery,
  CareJourneyTemplateQueryVariables
>;
export const CareJourneyTemplatesDocument = gql`
  query CareJourneyTemplates($filters: CareJourneyTemplateFilters!) {
    careJourneyTemplates(filters: $filters) {
      name
      id
      description
      type
      modality
    }
  }
`;

/**
 * __useCareJourneyTemplatesQuery__
 *
 * To run a query within a React component, call `useCareJourneyTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneyTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneyTemplatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCareJourneyTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareJourneyTemplatesQuery,
    CareJourneyTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CareJourneyTemplatesQuery,
    CareJourneyTemplatesQueryVariables
  >(CareJourneyTemplatesDocument, options);
}
export function useCareJourneyTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareJourneyTemplatesQuery,
    CareJourneyTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareJourneyTemplatesQuery,
    CareJourneyTemplatesQueryVariables
  >(CareJourneyTemplatesDocument, options);
}
export type CareJourneyTemplatesQueryHookResult = ReturnType<
  typeof useCareJourneyTemplatesQuery
>;
export type CareJourneyTemplatesLazyQueryHookResult = ReturnType<
  typeof useCareJourneyTemplatesLazyQuery
>;
export type CareJourneyTemplatesQueryResult = Apollo.QueryResult<
  CareJourneyTemplatesQuery,
  CareJourneyTemplatesQueryVariables
>;
export const CareJourneyTemplatesTableDocument = gql`
  query CareJourneyTemplatesTable {
    careJourneyTemplates(filters: { blueprint: false }) {
      id
      name
      publishedVersion {
        id
      }
      draftVersion {
        id
      }
      type
    }
  }
`;

/**
 * __useCareJourneyTemplatesTableQuery__
 *
 * To run a query within a React component, call `useCareJourneyTemplatesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneyTemplatesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneyTemplatesTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareJourneyTemplatesTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CareJourneyTemplatesTableQuery,
    CareJourneyTemplatesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CareJourneyTemplatesTableQuery,
    CareJourneyTemplatesTableQueryVariables
  >(CareJourneyTemplatesTableDocument, options);
}
export function useCareJourneyTemplatesTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareJourneyTemplatesTableQuery,
    CareJourneyTemplatesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareJourneyTemplatesTableQuery,
    CareJourneyTemplatesTableQueryVariables
  >(CareJourneyTemplatesTableDocument, options);
}
export type CareJourneyTemplatesTableQueryHookResult = ReturnType<
  typeof useCareJourneyTemplatesTableQuery
>;
export type CareJourneyTemplatesTableLazyQueryHookResult = ReturnType<
  typeof useCareJourneyTemplatesTableLazyQuery
>;
export type CareJourneyTemplatesTableQueryResult = Apollo.QueryResult<
  CareJourneyTemplatesTableQuery,
  CareJourneyTemplatesTableQueryVariables
>;
export const EditableTouchpointTemplateDocument = gql`
  query EditableTouchpointTemplate($id: UUID!) {
    sequentialTouchpointTemplate(id: $id) {
      ...EditableTouchpointDetail
    }
  }
  ${EditableTouchpointDetailFragmentDoc}
`;

/**
 * __useEditableTouchpointTemplateQuery__
 *
 * To run a query within a React component, call `useEditableTouchpointTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditableTouchpointTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditableTouchpointTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditableTouchpointTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    EditableTouchpointTemplateQuery,
    EditableTouchpointTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EditableTouchpointTemplateQuery,
    EditableTouchpointTemplateQueryVariables
  >(EditableTouchpointTemplateDocument, options);
}
export function useEditableTouchpointTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditableTouchpointTemplateQuery,
    EditableTouchpointTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditableTouchpointTemplateQuery,
    EditableTouchpointTemplateQueryVariables
  >(EditableTouchpointTemplateDocument, options);
}
export type EditableTouchpointTemplateQueryHookResult = ReturnType<
  typeof useEditableTouchpointTemplateQuery
>;
export type EditableTouchpointTemplateLazyQueryHookResult = ReturnType<
  typeof useEditableTouchpointTemplateLazyQuery
>;
export type EditableTouchpointTemplateQueryResult = Apollo.QueryResult<
  EditableTouchpointTemplateQuery,
  EditableTouchpointTemplateQueryVariables
>;
export const Icd10AutocompleteDocument = gql`
  query Icd10Autocomplete($query: String!) {
    icd10Autocomplete(query: $query) {
      code
      name
    }
  }
`;

/**
 * __useIcd10AutocompleteQuery__
 *
 * To run a query within a React component, call `useIcd10AutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcd10AutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcd10AutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useIcd10AutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Icd10AutocompleteQuery,
    Icd10AutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Icd10AutocompleteQuery,
    Icd10AutocompleteQueryVariables
  >(Icd10AutocompleteDocument, options);
}
export function useIcd10AutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Icd10AutocompleteQuery,
    Icd10AutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Icd10AutocompleteQuery,
    Icd10AutocompleteQueryVariables
  >(Icd10AutocompleteDocument, options);
}
export type Icd10AutocompleteQueryHookResult = ReturnType<
  typeof useIcd10AutocompleteQuery
>;
export type Icd10AutocompleteLazyQueryHookResult = ReturnType<
  typeof useIcd10AutocompleteLazyQuery
>;
export type Icd10AutocompleteQueryResult = Apollo.QueryResult<
  Icd10AutocompleteQuery,
  Icd10AutocompleteQueryVariables
>;
export const ObservationDefinitionAutocompleteDocument = gql`
  query ObservationDefinitionAutocomplete($query: String!, $programId: UUID) {
    observationDefinitionAutocomplete(query: $query, programId: $programId) {
      slug
      resource {
        dataConfig
        title
      }
    }
  }
`;

/**
 * __useObservationDefinitionAutocompleteQuery__
 *
 * To run a query within a React component, call `useObservationDefinitionAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useObservationDefinitionAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObservationDefinitionAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useObservationDefinitionAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    ObservationDefinitionAutocompleteQuery,
    ObservationDefinitionAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ObservationDefinitionAutocompleteQuery,
    ObservationDefinitionAutocompleteQueryVariables
  >(ObservationDefinitionAutocompleteDocument, options);
}
export function useObservationDefinitionAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ObservationDefinitionAutocompleteQuery,
    ObservationDefinitionAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ObservationDefinitionAutocompleteQuery,
    ObservationDefinitionAutocompleteQueryVariables
  >(ObservationDefinitionAutocompleteDocument, options);
}
export type ObservationDefinitionAutocompleteQueryHookResult = ReturnType<
  typeof useObservationDefinitionAutocompleteQuery
>;
export type ObservationDefinitionAutocompleteLazyQueryHookResult = ReturnType<
  typeof useObservationDefinitionAutocompleteLazyQuery
>;
export type ObservationDefinitionAutocompleteQueryResult = Apollo.QueryResult<
  ObservationDefinitionAutocompleteQuery,
  ObservationDefinitionAutocompleteQueryVariables
>;
export const ListSpecialtiesDocument = gql`
  query ListSpecialties {
    specialties(filters: {}) {
      id
      name
    }
  }
`;

/**
 * __useListSpecialtiesQuery__
 *
 * To run a query within a React component, call `useListSpecialtiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSpecialtiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSpecialtiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSpecialtiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListSpecialtiesQuery,
    ListSpecialtiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSpecialtiesQuery, ListSpecialtiesQueryVariables>(
    ListSpecialtiesDocument,
    options
  );
}
export function useListSpecialtiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSpecialtiesQuery,
    ListSpecialtiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListSpecialtiesQuery,
    ListSpecialtiesQueryVariables
  >(ListSpecialtiesDocument, options);
}
export type ListSpecialtiesQueryHookResult = ReturnType<
  typeof useListSpecialtiesQuery
>;
export type ListSpecialtiesLazyQueryHookResult = ReturnType<
  typeof useListSpecialtiesLazyQuery
>;
export type ListSpecialtiesQueryResult = Apollo.QueryResult<
  ListSpecialtiesQuery,
  ListSpecialtiesQueryVariables
>;
export const CareJourneyDocument = gql`
  query CareJourney($careJourneyId: UUID!) {
    careJourney(id: $careJourneyId) {
      id
      patientId
      programId
      sequentialTouchpoints {
        id
        task {
          id
          resultObject {
            ... on FormResponse {
              id
            }
          }
        }
        tasks {
          id
          resultObject {
            ... on FormResponse {
              id
            }
          }
        }
        sequentialTouchpointTemplate {
          category
          taskConfig {
            relatedObjectId
          }
        }
      }
    }
  }
`;

/**
 * __useCareJourneyQuery__
 *
 * To run a query within a React component, call `useCareJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneyQuery({
 *   variables: {
 *      careJourneyId: // value for 'careJourneyId'
 *   },
 * });
 */
export function useCareJourneyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareJourneyQuery,
    CareJourneyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareJourneyQuery, CareJourneyQueryVariables>(
    CareJourneyDocument,
    options
  );
}
export function useCareJourneyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareJourneyQuery,
    CareJourneyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareJourneyQuery, CareJourneyQueryVariables>(
    CareJourneyDocument,
    options
  );
}
export type CareJourneyQueryHookResult = ReturnType<typeof useCareJourneyQuery>;
export type CareJourneyLazyQueryHookResult = ReturnType<
  typeof useCareJourneyLazyQuery
>;
export type CareJourneyQueryResult = Apollo.QueryResult<
  CareJourneyQuery,
  CareJourneyQueryVariables
>;
export const CareJourneysDocument = gql`
  query CareJourneys($filters: FilterCareJourneyParams!) {
    careJourneys(filters: $filters) {
      id
      programId
      status
      previousCareJourneyId
      note
      offboardedAt
      retiredAt
      createdAt
      careJourneyTemplateVersion {
        id
        duration
        careJourneyTemplate {
          id
          name
          slug
        }
      }
      sequentialTouchpoints {
        ...TouchpointDetail
      }
    }
  }
  ${TouchpointDetailFragmentDoc}
`;

/**
 * __useCareJourneysQuery__
 *
 * To run a query within a React component, call `useCareJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneysQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCareJourneysQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareJourneysQuery,
    CareJourneysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareJourneysQuery, CareJourneysQueryVariables>(
    CareJourneysDocument,
    options
  );
}
export function useCareJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareJourneysQuery,
    CareJourneysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareJourneysQuery, CareJourneysQueryVariables>(
    CareJourneysDocument,
    options
  );
}
export type CareJourneysQueryHookResult = ReturnType<
  typeof useCareJourneysQuery
>;
export type CareJourneysLazyQueryHookResult = ReturnType<
  typeof useCareJourneysLazyQuery
>;
export type CareJourneysQueryResult = Apollo.QueryResult<
  CareJourneysQuery,
  CareJourneysQueryVariables
>;
export const SequentialTouchpointTemplatesDocument = gql`
  query SequentialTouchpointTemplates(
    $filters: FilterSequentialTouchpointTemplateParams!
  ) {
    sequentialTouchpointTemplates(filters: $filters) {
      id
      name
      position
      programId
      category
    }
  }
`;

/**
 * __useSequentialTouchpointTemplatesQuery__
 *
 * To run a query within a React component, call `useSequentialTouchpointTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSequentialTouchpointTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSequentialTouchpointTemplatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSequentialTouchpointTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SequentialTouchpointTemplatesQuery,
    SequentialTouchpointTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SequentialTouchpointTemplatesQuery,
    SequentialTouchpointTemplatesQueryVariables
  >(SequentialTouchpointTemplatesDocument, options);
}
export function useSequentialTouchpointTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SequentialTouchpointTemplatesQuery,
    SequentialTouchpointTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SequentialTouchpointTemplatesQuery,
    SequentialTouchpointTemplatesQueryVariables
  >(SequentialTouchpointTemplatesDocument, options);
}
export type SequentialTouchpointTemplatesQueryHookResult = ReturnType<
  typeof useSequentialTouchpointTemplatesQuery
>;
export type SequentialTouchpointTemplatesLazyQueryHookResult = ReturnType<
  typeof useSequentialTouchpointTemplatesLazyQuery
>;
export type SequentialTouchpointTemplatesQueryResult = Apollo.QueryResult<
  SequentialTouchpointTemplatesQuery,
  SequentialTouchpointTemplatesQueryVariables
>;
export const ListFormsDocument = gql`
  query ListForms($filters: FormFilters!) {
    forms(filters: $filters) {
      id
      programId
      slug
      name
      description
      publishedVersion {
        id
        instructions
        publishedAt
        createdAt
        updatedAt
        config
        programId
      }
      draftVersion {
        id
        instructions
        publishedAt
        createdAt
        updatedAt
        config
        programId
      }
      archivedAt
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useListFormsQuery__
 *
 * To run a query within a React component, call `useListFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFormsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListFormsQuery(
  baseOptions: Apollo.QueryHookOptions<ListFormsQuery, ListFormsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFormsQuery, ListFormsQueryVariables>(
    ListFormsDocument,
    options
  );
}
export function useListFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFormsQuery,
    ListFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFormsQuery, ListFormsQueryVariables>(
    ListFormsDocument,
    options
  );
}
export type ListFormsQueryHookResult = ReturnType<typeof useListFormsQuery>;
export type ListFormsLazyQueryHookResult = ReturnType<
  typeof useListFormsLazyQuery
>;
export type ListFormsQueryResult = Apollo.QueryResult<
  ListFormsQuery,
  ListFormsQueryVariables
>;
export const AgentConversationMessagesDocument = gql`
  query AgentConversationMessages(
    $conversationId: UUID!
    $sorting: [SortMessageParam!]
    $pagination: PaginationParams
  ) {
    agentConversationMessages(
      conversationId: $conversationId
      sorting: $sorting
      pagination: $pagination
    ) {
      totalCount
      items {
        body
        id
        recipientType
        createdAt
      }
    }
  }
`;

/**
 * __useAgentConversationMessagesQuery__
 *
 * To run a query within a React component, call `useAgentConversationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentConversationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentConversationMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      sorting: // value for 'sorting'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAgentConversationMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgentConversationMessagesQuery,
    AgentConversationMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgentConversationMessagesQuery,
    AgentConversationMessagesQueryVariables
  >(AgentConversationMessagesDocument, options);
}
export function useAgentConversationMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentConversationMessagesQuery,
    AgentConversationMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgentConversationMessagesQuery,
    AgentConversationMessagesQueryVariables
  >(AgentConversationMessagesDocument, options);
}
export type AgentConversationMessagesQueryHookResult = ReturnType<
  typeof useAgentConversationMessagesQuery
>;
export type AgentConversationMessagesLazyQueryHookResult = ReturnType<
  typeof useAgentConversationMessagesLazyQuery
>;
export type AgentConversationMessagesQueryResult = Apollo.QueryResult<
  AgentConversationMessagesQuery,
  AgentConversationMessagesQueryVariables
>;
export const GetAgentConversationsDocument = gql`
  query getAgentConversations(
    $filters: AgentConversationFilters
    $sorting: [SortConversationParam!]
  ) {
    agentConversations(filters: $filters, sorting: $sorting) {
      totalCount
      items {
        id
        messages {
          id
          body
          createdAt
          conversationId
          senderType
        }
      }
    }
  }
`;

/**
 * __useGetAgentConversationsQuery__
 *
 * To run a query within a React component, call `useGetAgentConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentConversationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetAgentConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAgentConversationsQuery,
    GetAgentConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAgentConversationsQuery,
    GetAgentConversationsQueryVariables
  >(GetAgentConversationsDocument, options);
}
export function useGetAgentConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgentConversationsQuery,
    GetAgentConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAgentConversationsQuery,
    GetAgentConversationsQueryVariables
  >(GetAgentConversationsDocument, options);
}
export type GetAgentConversationsQueryHookResult = ReturnType<
  typeof useGetAgentConversationsQuery
>;
export type GetAgentConversationsLazyQueryHookResult = ReturnType<
  typeof useGetAgentConversationsLazyQuery
>;
export type GetAgentConversationsQueryResult = Apollo.QueryResult<
  GetAgentConversationsQuery,
  GetAgentConversationsQueryVariables
>;
export const GetConversationByIdDocument = gql`
  query GetConversationById($conversationId: UUID!) {
    conversation(id: $conversationId) {
      id
      externalId
      subject
    }
  }
`;

/**
 * __useGetConversationByIdQuery__
 *
 * To run a query within a React component, call `useGetConversationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationByIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationByIdQuery,
    GetConversationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationByIdQuery,
    GetConversationByIdQueryVariables
  >(GetConversationByIdDocument, options);
}
export function useGetConversationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationByIdQuery,
    GetConversationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationByIdQuery,
    GetConversationByIdQueryVariables
  >(GetConversationByIdDocument, options);
}
export type GetConversationByIdQueryHookResult = ReturnType<
  typeof useGetConversationByIdQuery
>;
export type GetConversationByIdLazyQueryHookResult = ReturnType<
  typeof useGetConversationByIdLazyQuery
>;
export type GetConversationByIdQueryResult = Apollo.QueryResult<
  GetConversationByIdQuery,
  GetConversationByIdQueryVariables
>;
export const FormDestinationsDocument = gql`
  query FormDestinations($filters: FormDestinationFilters!) {
    formDestinations(filters: $filters) {
      path
      title
      description
      dataConfig
      components {
        name
        title
        description
        dataConfig
        calculationConfig
      }
    }
  }
`;

/**
 * __useFormDestinationsQuery__
 *
 * To run a query within a React component, call `useFormDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormDestinationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFormDestinationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormDestinationsQuery,
    FormDestinationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormDestinationsQuery, FormDestinationsQueryVariables>(
    FormDestinationsDocument,
    options
  );
}
export function useFormDestinationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormDestinationsQuery,
    FormDestinationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FormDestinationsQuery,
    FormDestinationsQueryVariables
  >(FormDestinationsDocument, options);
}
export type FormDestinationsQueryHookResult = ReturnType<
  typeof useFormDestinationsQuery
>;
export type FormDestinationsLazyQueryHookResult = ReturnType<
  typeof useFormDestinationsLazyQuery
>;
export type FormDestinationsQueryResult = Apollo.QueryResult<
  FormDestinationsQuery,
  FormDestinationsQueryVariables
>;
export const FormDestinationAutocompleteDocument = gql`
  query FormDestinationAutocomplete($query: String!, $programId: UUID) {
    formDestinationAutocomplete(query: $query, programId: $programId) {
      dataConfig
      path
      title
      components {
        name
        title
        description
        dataConfig
      }
    }
  }
`;

/**
 * __useFormDestinationAutocompleteQuery__
 *
 * To run a query within a React component, call `useFormDestinationAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormDestinationAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormDestinationAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useFormDestinationAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormDestinationAutocompleteQuery,
    FormDestinationAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FormDestinationAutocompleteQuery,
    FormDestinationAutocompleteQueryVariables
  >(FormDestinationAutocompleteDocument, options);
}
export function useFormDestinationAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormDestinationAutocompleteQuery,
    FormDestinationAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FormDestinationAutocompleteQuery,
    FormDestinationAutocompleteQueryVariables
  >(FormDestinationAutocompleteDocument, options);
}
export type FormDestinationAutocompleteQueryHookResult = ReturnType<
  typeof useFormDestinationAutocompleteQuery
>;
export type FormDestinationAutocompleteLazyQueryHookResult = ReturnType<
  typeof useFormDestinationAutocompleteLazyQuery
>;
export type FormDestinationAutocompleteQueryResult = Apollo.QueryResult<
  FormDestinationAutocompleteQuery,
  FormDestinationAutocompleteQueryVariables
>;
export const GetFormDraftByIdDocument = gql`
  query GetFormDraftById($formId: UUID!) {
    form(id: $formId) {
      id
      programId
      slug
      name
      description
      publishedVersion {
        id
      }
      draftVersion {
        id
        config
      }
      archivedAt
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetFormDraftByIdQuery__
 *
 * To run a query within a React component, call `useGetFormDraftByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDraftByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDraftByIdQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGetFormDraftByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormDraftByIdQuery,
    GetFormDraftByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormDraftByIdQuery, GetFormDraftByIdQueryVariables>(
    GetFormDraftByIdDocument,
    options
  );
}
export function useGetFormDraftByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormDraftByIdQuery,
    GetFormDraftByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormDraftByIdQuery,
    GetFormDraftByIdQueryVariables
  >(GetFormDraftByIdDocument, options);
}
export type GetFormDraftByIdQueryHookResult = ReturnType<
  typeof useGetFormDraftByIdQuery
>;
export type GetFormDraftByIdLazyQueryHookResult = ReturnType<
  typeof useGetFormDraftByIdLazyQuery
>;
export type GetFormDraftByIdQueryResult = Apollo.QueryResult<
  GetFormDraftByIdQuery,
  GetFormDraftByIdQueryVariables
>;
export const GetFormResponseByTaskIdDocument = gql`
  query GetFormResponseByTaskId($taskId: UUID!) {
    formResponseByTaskId(taskId: $taskId) {
      id
      formVersion {
        form {
          name
        }
      }
      task {
        name
        status
      }
      interpretedResponses {
        questions {
          question
          response
          displayResponse
          destination
          validationSuccess
          validationErrors
          components {
            question
            response
            displayResponse
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormResponseByTaskIdQuery__
 *
 * To run a query within a React component, call `useGetFormResponseByTaskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormResponseByTaskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormResponseByTaskIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetFormResponseByTaskIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >(GetFormResponseByTaskIdDocument, options);
}
export function useGetFormResponseByTaskIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >(GetFormResponseByTaskIdDocument, options);
}
export type GetFormResponseByTaskIdQueryHookResult = ReturnType<
  typeof useGetFormResponseByTaskIdQuery
>;
export type GetFormResponseByTaskIdLazyQueryHookResult = ReturnType<
  typeof useGetFormResponseByTaskIdLazyQuery
>;
export type GetFormResponseByTaskIdQueryResult = Apollo.QueryResult<
  GetFormResponseByTaskIdQuery,
  GetFormResponseByTaskIdQueryVariables
>;
export const ListFormResponsesDocument = gql`
  query ListFormResponses($filters: FormResponseFilters!) {
    formResponses(filters: $filters) {
      id
      patientId
      formVersion {
        form {
          id
          name
        }
      }
      task {
        name
        status
        id
        taskDueAt
        relatedObjectId
      }
    }
  }
`;

/**
 * __useListFormResponsesQuery__
 *
 * To run a query within a React component, call `useListFormResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFormResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFormResponsesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListFormResponsesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListFormResponsesQuery,
    ListFormResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListFormResponsesQuery,
    ListFormResponsesQueryVariables
  >(ListFormResponsesDocument, options);
}
export function useListFormResponsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFormResponsesQuery,
    ListFormResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListFormResponsesQuery,
    ListFormResponsesQueryVariables
  >(ListFormResponsesDocument, options);
}
export type ListFormResponsesQueryHookResult = ReturnType<
  typeof useListFormResponsesQuery
>;
export type ListFormResponsesLazyQueryHookResult = ReturnType<
  typeof useListFormResponsesLazyQuery
>;
export type ListFormResponsesQueryResult = Apollo.QueryResult<
  ListFormResponsesQuery,
  ListFormResponsesQueryVariables
>;
export const AllergyIntolerancesDocument = gql`
  query AllergyIntolerances($filters: AllergyIntoleranceFilters!) {
    allergyIntolerances(filters: $filters) {
      id
      resource {
        code {
          text
        }
      }
    }
  }
`;

/**
 * __useAllergyIntolerancesQuery__
 *
 * To run a query within a React component, call `useAllergyIntolerancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllergyIntolerancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllergyIntolerancesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllergyIntolerancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllergyIntolerancesQuery,
    AllergyIntolerancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllergyIntolerancesQuery,
    AllergyIntolerancesQueryVariables
  >(AllergyIntolerancesDocument, options);
}
export function useAllergyIntolerancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllergyIntolerancesQuery,
    AllergyIntolerancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllergyIntolerancesQuery,
    AllergyIntolerancesQueryVariables
  >(AllergyIntolerancesDocument, options);
}
export type AllergyIntolerancesQueryHookResult = ReturnType<
  typeof useAllergyIntolerancesQuery
>;
export type AllergyIntolerancesLazyQueryHookResult = ReturnType<
  typeof useAllergyIntolerancesLazyQuery
>;
export type AllergyIntolerancesQueryResult = Apollo.QueryResult<
  AllergyIntolerancesQuery,
  AllergyIntolerancesQueryVariables
>;
export const FormResponsesDocument = gql`
  query FormResponses($filters: FormResponseFilters!) {
    formResponses(filters: $filters) {
      id
      submittedAt
      formVersion {
        form {
          name
        }
      }
      task {
        id
      }
    }
  }
`;

/**
 * __useFormResponsesQuery__
 *
 * To run a query within a React component, call `useFormResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormResponsesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFormResponsesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormResponsesQuery,
    FormResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormResponsesQuery, FormResponsesQueryVariables>(
    FormResponsesDocument,
    options
  );
}
export function useFormResponsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormResponsesQuery,
    FormResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormResponsesQuery, FormResponsesQueryVariables>(
    FormResponsesDocument,
    options
  );
}
export type FormResponsesQueryHookResult = ReturnType<
  typeof useFormResponsesQuery
>;
export type FormResponsesLazyQueryHookResult = ReturnType<
  typeof useFormResponsesLazyQuery
>;
export type FormResponsesQueryResult = Apollo.QueryResult<
  FormResponsesQuery,
  FormResponsesQueryVariables
>;
export const FullPatientProfileDocument = gql`
  query FullPatientProfile($patientId: UUID!) {
    patient(id: $patientId) {
      ...CommonPatientFields
      enrollments {
        enrollmentStatus
      }
      careJourneys {
        status
        careJourneyTemplateVersion {
          careJourneyTemplate {
            name
          }
        }
      }
    }
  }
  ${CommonPatientFieldsFragmentDoc}
`;

/**
 * __useFullPatientProfileQuery__
 *
 * To run a query within a React component, call `useFullPatientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullPatientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullPatientProfileQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useFullPatientProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FullPatientProfileQuery,
    FullPatientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FullPatientProfileQuery,
    FullPatientProfileQueryVariables
  >(FullPatientProfileDocument, options);
}
export function useFullPatientProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullPatientProfileQuery,
    FullPatientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FullPatientProfileQuery,
    FullPatientProfileQueryVariables
  >(FullPatientProfileDocument, options);
}
export type FullPatientProfileQueryHookResult = ReturnType<
  typeof useFullPatientProfileQuery
>;
export type FullPatientProfileLazyQueryHookResult = ReturnType<
  typeof useFullPatientProfileLazyQuery
>;
export type FullPatientProfileQueryResult = Apollo.QueryResult<
  FullPatientProfileQuery,
  FullPatientProfileQueryVariables
>;
export const HistoryConditionsDocument = gql`
  query HistoryConditions {
    observationDefinitions(filters: { slug: "history-conditions" }) {
      resource {
        dataConfig
      }
    }
  }
`;

/**
 * __useHistoryConditionsQuery__
 *
 * To run a query within a React component, call `useHistoryConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHistoryConditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HistoryConditionsQuery,
    HistoryConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HistoryConditionsQuery,
    HistoryConditionsQueryVariables
  >(HistoryConditionsDocument, options);
}
export function useHistoryConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HistoryConditionsQuery,
    HistoryConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HistoryConditionsQuery,
    HistoryConditionsQueryVariables
  >(HistoryConditionsDocument, options);
}
export type HistoryConditionsQueryHookResult = ReturnType<
  typeof useHistoryConditionsQuery
>;
export type HistoryConditionsLazyQueryHookResult = ReturnType<
  typeof useHistoryConditionsLazyQuery
>;
export type HistoryConditionsQueryResult = Apollo.QueryResult<
  HistoryConditionsQuery,
  HistoryConditionsQueryVariables
>;
export const LatestPrescriptionDocument = gql`
  query LatestPrescription($patientId: UUID!) {
    latestObservation(
      filters: {
        coding: [
          { code: "prescriptions", system: "https://custom.caire.health" }
        ]
        patientId: $patientId
      }
    ) {
      id
      resource {
        value {
          valueString
        }
      }
    }
  }
`;

/**
 * __useLatestPrescriptionQuery__
 *
 * To run a query within a React component, call `useLatestPrescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestPrescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestPrescriptionQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useLatestPrescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestPrescriptionQuery,
    LatestPrescriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestPrescriptionQuery,
    LatestPrescriptionQueryVariables
  >(LatestPrescriptionDocument, options);
}
export function useLatestPrescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestPrescriptionQuery,
    LatestPrescriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestPrescriptionQuery,
    LatestPrescriptionQueryVariables
  >(LatestPrescriptionDocument, options);
}
export type LatestPrescriptionQueryHookResult = ReturnType<
  typeof useLatestPrescriptionQuery
>;
export type LatestPrescriptionLazyQueryHookResult = ReturnType<
  typeof useLatestPrescriptionLazyQuery
>;
export type LatestPrescriptionQueryResult = Apollo.QueryResult<
  LatestPrescriptionQuery,
  LatestPrescriptionQueryVariables
>;
export const LatestProblemsObservationsDocument = gql`
  query LatestProblemsObservations($patientId: UUID!) {
    latestObservation(
      filters: {
        coding: [
          { code: "history_conditions", system: "https://custom.caire.health" }
        ]
        patientId: $patientId
      }
    ) {
      id
      resource {
        component {
          value {
            valueString
          }
        }
      }
    }
  }
`;

/**
 * __useLatestProblemsObservationsQuery__
 *
 * To run a query within a React component, call `useLatestProblemsObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestProblemsObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestProblemsObservationsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useLatestProblemsObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestProblemsObservationsQuery,
    LatestProblemsObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestProblemsObservationsQuery,
    LatestProblemsObservationsQueryVariables
  >(LatestProblemsObservationsDocument, options);
}
export function useLatestProblemsObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestProblemsObservationsQuery,
    LatestProblemsObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestProblemsObservationsQuery,
    LatestProblemsObservationsQueryVariables
  >(LatestProblemsObservationsDocument, options);
}
export type LatestProblemsObservationsQueryHookResult = ReturnType<
  typeof useLatestProblemsObservationsQuery
>;
export type LatestProblemsObservationsLazyQueryHookResult = ReturnType<
  typeof useLatestProblemsObservationsLazyQuery
>;
export type LatestProblemsObservationsQueryResult = Apollo.QueryResult<
  LatestProblemsObservationsQuery,
  LatestProblemsObservationsQueryVariables
>;
export const LatestProfileObservationsDocument = gql`
  query LatestProfileObservations($patientId: UUID!) {
    pharmacyObservation: latestObservation(
      filters: {
        coding: [{ code: "pharmacy", system: "https://custom.caire.health" }]
        patientId: $patientId
      }
    ) {
      ...StringObservation
    }
    externalProvider: latestObservation(
      filters: {
        coding: [
          {
            code: "existing-pcp-details"
            system: "https://custom.caire.health"
          }
        ]
        patientId: $patientId
      }
    ) {
      ...StringObservation
    }
    heightObservation: latestObservation(
      filters: {
        coding: [{ code: "height", system: "https://custom.caire.health" }]
        patientId: $patientId
      }
    ) {
      ...IntegerObservation
    }
    weightObservation: latestObservation(
      filters: {
        coding: [{ code: "weight", system: "https://custom.caire.health" }]
        patientId: $patientId
      }
    ) {
      ...IntegerObservation
    }
    menopauseRatingScaleObservation: latestObservation(
      filters: {
        coding: [
          {
            code: "menopause-rating-scale"
            system: "https://custom.caire.health"
          }
        ]
        patientId: $patientId
      }
    ) {
      ...StringObservation
    }
    menopauseStageObservation: latestObservation(
      filters: {
        coding: [
          { code: "menopause-stage", system: "https://custom.caire.health" }
        ]
        patientId: $patientId
      }
    ) {
      ...StringObservation
    }
    providerNoteObservation: latestObservation(
      filters: {
        coding: [
          { code: "provider-note", system: "https://custom.caire.health" }
        ]
        patientId: $patientId
      }
    ) {
      ...StringObservation
    }
  }
  ${StringObservationFragmentDoc}
  ${IntegerObservationFragmentDoc}
`;

/**
 * __useLatestProfileObservationsQuery__
 *
 * To run a query within a React component, call `useLatestProfileObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestProfileObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestProfileObservationsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useLatestProfileObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestProfileObservationsQuery,
    LatestProfileObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestProfileObservationsQuery,
    LatestProfileObservationsQueryVariables
  >(LatestProfileObservationsDocument, options);
}
export function useLatestProfileObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestProfileObservationsQuery,
    LatestProfileObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestProfileObservationsQuery,
    LatestProfileObservationsQueryVariables
  >(LatestProfileObservationsDocument, options);
}
export type LatestProfileObservationsQueryHookResult = ReturnType<
  typeof useLatestProfileObservationsQuery
>;
export type LatestProfileObservationsLazyQueryHookResult = ReturnType<
  typeof useLatestProfileObservationsLazyQuery
>;
export type LatestProfileObservationsQueryResult = Apollo.QueryResult<
  LatestProfileObservationsQuery,
  LatestProfileObservationsQueryVariables
>;
export const ListConditionsDocument = gql`
  query ListConditions($patientId: UUID!) {
    conditions(filters: { patientId: $patientId, active: true }) {
      id
      resource {
        code {
          text
        }
      }
    }
  }
`;

/**
 * __useListConditionsQuery__
 *
 * To run a query within a React component, call `useListConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConditionsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useListConditionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListConditionsQuery,
    ListConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListConditionsQuery, ListConditionsQueryVariables>(
    ListConditionsDocument,
    options
  );
}
export function useListConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListConditionsQuery,
    ListConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListConditionsQuery, ListConditionsQueryVariables>(
    ListConditionsDocument,
    options
  );
}
export type ListConditionsQueryHookResult = ReturnType<
  typeof useListConditionsQuery
>;
export type ListConditionsLazyQueryHookResult = ReturnType<
  typeof useListConditionsLazyQuery
>;
export type ListConditionsQueryResult = Apollo.QueryResult<
  ListConditionsQuery,
  ListConditionsQueryVariables
>;
export const ListPatientsDocument = gql`
  query ListPatients(
    $pagination: PaginationParams
    $sorting: [SortPatientParam!]
    $filters: FilterPatientParams
  ) {
    patients(pagination: $pagination, sorting: $sorting, filters: $filters) {
      items {
        id
        firstName
        lastName
        preferredName
        birthDate
        addresses {
          state
        }
        careJourneys {
          id
          programId
          status
          careJourneyTemplateVersion {
            careJourneyTemplate {
              name
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useListPatientsQuery__
 *
 * To run a query within a React component, call `useListPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListPatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListPatientsQuery,
    ListPatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPatientsQuery, ListPatientsQueryVariables>(
    ListPatientsDocument,
    options
  );
}
export function useListPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPatientsQuery,
    ListPatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPatientsQuery, ListPatientsQueryVariables>(
    ListPatientsDocument,
    options
  );
}
export type ListPatientsQueryHookResult = ReturnType<
  typeof useListPatientsQuery
>;
export type ListPatientsLazyQueryHookResult = ReturnType<
  typeof useListPatientsLazyQuery
>;
export type ListPatientsQueryResult = Apollo.QueryResult<
  ListPatientsQuery,
  ListPatientsQueryVariables
>;
export const ListPatientsWithAlertsDocument = gql`
  query ListPatientsWithAlerts(
    $pagination: PaginationParams
    $filters: ListAlertFilters
  ) {
    alertCountsByPatient(pagination: $pagination) {
      items {
        patient {
          birthDate
          firstName
          id
          lastName
          preferredName
        }
        lowCount
        mediumCount
        highCount
      }
      totalCount
    }
  }
`;

/**
 * __useListPatientsWithAlertsQuery__
 *
 * To run a query within a React component, call `useListPatientsWithAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientsWithAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientsWithAlertsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListPatientsWithAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListPatientsWithAlertsQuery,
    ListPatientsWithAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListPatientsWithAlertsQuery,
    ListPatientsWithAlertsQueryVariables
  >(ListPatientsWithAlertsDocument, options);
}
export function useListPatientsWithAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPatientsWithAlertsQuery,
    ListPatientsWithAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListPatientsWithAlertsQuery,
    ListPatientsWithAlertsQueryVariables
  >(ListPatientsWithAlertsDocument, options);
}
export type ListPatientsWithAlertsQueryHookResult = ReturnType<
  typeof useListPatientsWithAlertsQuery
>;
export type ListPatientsWithAlertsLazyQueryHookResult = ReturnType<
  typeof useListPatientsWithAlertsLazyQuery
>;
export type ListPatientsWithAlertsQueryResult = Apollo.QueryResult<
  ListPatientsWithAlertsQuery,
  ListPatientsWithAlertsQueryVariables
>;
export const MedicationStatementsDocument = gql`
  query MedicationStatements($patientId: UUID!) {
    medicationStatements(filters: { patientId: $patientId, active: true }) {
      id
      resource {
        medication {
          concept {
            text
          }
        }
      }
    }
  }
`;

/**
 * __useMedicationStatementsQuery__
 *
 * To run a query within a React component, call `useMedicationStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationStatementsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useMedicationStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedicationStatementsQuery,
    MedicationStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedicationStatementsQuery,
    MedicationStatementsQueryVariables
  >(MedicationStatementsDocument, options);
}
export function useMedicationStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedicationStatementsQuery,
    MedicationStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedicationStatementsQuery,
    MedicationStatementsQueryVariables
  >(MedicationStatementsDocument, options);
}
export type MedicationStatementsQueryHookResult = ReturnType<
  typeof useMedicationStatementsQuery
>;
export type MedicationStatementsLazyQueryHookResult = ReturnType<
  typeof useMedicationStatementsLazyQuery
>;
export type MedicationStatementsQueryResult = Apollo.QueryResult<
  MedicationStatementsQuery,
  MedicationStatementsQueryVariables
>;
export const MenopauseStagesDocument = gql`
  query MenopauseStages {
    observationDefinitions(filters: { slug: "menopause-stage" }) {
      resource {
        dataConfig
      }
    }
  }
`;

/**
 * __useMenopauseStagesQuery__
 *
 * To run a query within a React component, call `useMenopauseStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenopauseStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenopauseStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenopauseStagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MenopauseStagesQuery,
    MenopauseStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MenopauseStagesQuery, MenopauseStagesQueryVariables>(
    MenopauseStagesDocument,
    options
  );
}
export function useMenopauseStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MenopauseStagesQuery,
    MenopauseStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MenopauseStagesQuery,
    MenopauseStagesQueryVariables
  >(MenopauseStagesDocument, options);
}
export type MenopauseStagesQueryHookResult = ReturnType<
  typeof useMenopauseStagesQuery
>;
export type MenopauseStagesLazyQueryHookResult = ReturnType<
  typeof useMenopauseStagesLazyQuery
>;
export type MenopauseStagesQueryResult = Apollo.QueryResult<
  MenopauseStagesQuery,
  MenopauseStagesQueryVariables
>;
export const PatientDocument = gql`
  query Patient($patientId: UUID!) {
    patient(id: $patientId) {
      ...CommonPatientFields
    }
  }
  ${CommonPatientFieldsFragmentDoc}
`;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientQuery(
  baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientQuery, PatientQueryVariables>(
    PatientDocument,
    options
  );
}
export function usePatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(
    PatientDocument,
    options
  );
}
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientQueryResult = Apollo.QueryResult<
  PatientQuery,
  PatientQueryVariables
>;
export const PatientCareJourneysDocument = gql`
  query PatientCareJourneys($patientId: UUID!) {
    patient(id: $patientId) {
      id
      firstName
      lastName
      preferredName
      birthDate
      careJourneys {
        id
        careJourneyTemplateVersion {
          careJourneyTemplate {
            id
            name
          }
        }
        status
      }
    }
  }
`;

/**
 * __usePatientCareJourneysQuery__
 *
 * To run a query within a React component, call `usePatientCareJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientCareJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientCareJourneysQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientCareJourneysQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientCareJourneysQuery,
    PatientCareJourneysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PatientCareJourneysQuery,
    PatientCareJourneysQueryVariables
  >(PatientCareJourneysDocument, options);
}
export function usePatientCareJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientCareJourneysQuery,
    PatientCareJourneysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientCareJourneysQuery,
    PatientCareJourneysQueryVariables
  >(PatientCareJourneysDocument, options);
}
export type PatientCareJourneysQueryHookResult = ReturnType<
  typeof usePatientCareJourneysQuery
>;
export type PatientCareJourneysLazyQueryHookResult = ReturnType<
  typeof usePatientCareJourneysLazyQuery
>;
export type PatientCareJourneysQueryResult = Apollo.QueryResult<
  PatientCareJourneysQuery,
  PatientCareJourneysQueryVariables
>;
export const PatientExportDocument = gql`
  query PatientExport($patientId: UUID!) {
    patient(id: $patientId) {
      firstName
      lastName
      birthDate
      createdAt
      careJourneys {
        status
        careJourneyTemplateVersion {
          careJourneyTemplate {
            name
          }
        }
      }
      emailAddresses {
        value
      }
      phoneNumbers {
        value
      }
    }
    encounters(filters: { patientId: $patientId, status: COMPLETED }) {
      id
      clinicalNoteType
      status
      startedAt
      staff {
        firstName
        lastName
        preferredName
      }
      appointment {
        id
        status
        appointmentType {
          id
          displayName
        }
        startTime
      }
      clinicalNote {
        body {
          ... on HealthCoachNote {
            asyncNote
            plan {
              postVisitSummary
            }
          }
          ... on PrescriberNote {
            asyncNote
            plan {
              postVisitSummary
            }
          }
        }
      }
    }
    formResponses(filters: { isSubmitted: true, patientId: $patientId }) {
      id
      createdAt
      task {
        name
      }
      interpretedResponses {
        questions {
          question
          response
          displayResponse
          destination
          validationSuccess
          validationErrors
          components {
            question
            response
            displayResponse
          }
        }
      }
    }
  }
`;

/**
 * __usePatientExportQuery__
 *
 * To run a query within a React component, call `usePatientExportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientExportQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientExportQuery,
    PatientExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientExportQuery, PatientExportQueryVariables>(
    PatientExportDocument,
    options
  );
}
export function usePatientExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientExportQuery,
    PatientExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientExportQuery, PatientExportQueryVariables>(
    PatientExportDocument,
    options
  );
}
export type PatientExportQueryHookResult = ReturnType<
  typeof usePatientExportQuery
>;
export type PatientExportLazyQueryHookResult = ReturnType<
  typeof usePatientExportLazyQuery
>;
export type PatientExportQueryResult = Apollo.QueryResult<
  PatientExportQuery,
  PatientExportQueryVariables
>;
export const PatientHistoryConditionsDocument = gql`
  query PatientHistoryConditions($patientId: UUID!) {
    latestObservation(
      filters: {
        patientId: $patientId
        coding: [
          { code: "history_conditions", system: "https://custom.caire.health" }
        ]
      }
    ) {
      id
      resource {
        component {
          value {
            valueString
          }
        }
      }
    }
  }
`;

/**
 * __usePatientHistoryConditionsQuery__
 *
 * To run a query within a React component, call `usePatientHistoryConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientHistoryConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientHistoryConditionsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientHistoryConditionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientHistoryConditionsQuery,
    PatientHistoryConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PatientHistoryConditionsQuery,
    PatientHistoryConditionsQueryVariables
  >(PatientHistoryConditionsDocument, options);
}
export function usePatientHistoryConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientHistoryConditionsQuery,
    PatientHistoryConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientHistoryConditionsQuery,
    PatientHistoryConditionsQueryVariables
  >(PatientHistoryConditionsDocument, options);
}
export type PatientHistoryConditionsQueryHookResult = ReturnType<
  typeof usePatientHistoryConditionsQuery
>;
export type PatientHistoryConditionsLazyQueryHookResult = ReturnType<
  typeof usePatientHistoryConditionsLazyQuery
>;
export type PatientHistoryConditionsQueryResult = Apollo.QueryResult<
  PatientHistoryConditionsQuery,
  PatientHistoryConditionsQueryVariables
>;
export const GetAppointmentByIdDocument = gql`
  query GetAppointmentById($appointmentId: UUID!) {
    appointment(id: $appointmentId) {
      id
      startTime
      description
      patient {
        firstName
        lastName
        id
        birthDate
      }
      appointmentType {
        name
      }
      staff {
        firstName
        lastName
        professionalLicenses {
          type
        }
      }
      timezone
    }
  }
`;

/**
 * __useGetAppointmentByIdQuery__
 *
 * To run a query within a React component, call `useGetAppointmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentByIdQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetAppointmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentByIdQuery,
    GetAppointmentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentByIdQuery,
    GetAppointmentByIdQueryVariables
  >(GetAppointmentByIdDocument, options);
}
export function useGetAppointmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentByIdQuery,
    GetAppointmentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentByIdQuery,
    GetAppointmentByIdQueryVariables
  >(GetAppointmentByIdDocument, options);
}
export type GetAppointmentByIdQueryHookResult = ReturnType<
  typeof useGetAppointmentByIdQuery
>;
export type GetAppointmentByIdLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentByIdLazyQuery
>;
export type GetAppointmentByIdQueryResult = Apollo.QueryResult<
  GetAppointmentByIdQuery,
  GetAppointmentByIdQueryVariables
>;
export const ListAppointmentTypesDocument = gql`
  query ListAppointmentTypes($input: ListAppointmentTypesInput) {
    appointmentTypes(input: $input) {
      displayName
      id
      name
      slug
      descriptionTemplate
      programId
    }
  }
`;

/**
 * __useListAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useListAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAppointmentTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAppointmentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAppointmentTypesQuery,
    ListAppointmentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAppointmentTypesQuery,
    ListAppointmentTypesQueryVariables
  >(ListAppointmentTypesDocument, options);
}
export function useListAppointmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAppointmentTypesQuery,
    ListAppointmentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAppointmentTypesQuery,
    ListAppointmentTypesQueryVariables
  >(ListAppointmentTypesDocument, options);
}
export type ListAppointmentTypesQueryHookResult = ReturnType<
  typeof useListAppointmentTypesQuery
>;
export type ListAppointmentTypesLazyQueryHookResult = ReturnType<
  typeof useListAppointmentTypesLazyQuery
>;
export type ListAppointmentTypesQueryResult = Apollo.QueryResult<
  ListAppointmentTypesQuery,
  ListAppointmentTypesQueryVariables
>;
export const CronofyProfileDocument = gql`
  query CronofyProfile($staffId: UUID!) {
    cronofyProfile(staffId: $staffId) {
      id
      staffId
      externalUserId
      primaryCalendarId
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useCronofyProfileQuery__
 *
 * To run a query within a React component, call `useCronofyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCronofyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCronofyProfileQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useCronofyProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    CronofyProfileQuery,
    CronofyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CronofyProfileQuery, CronofyProfileQueryVariables>(
    CronofyProfileDocument,
    options
  );
}
export function useCronofyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CronofyProfileQuery,
    CronofyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CronofyProfileQuery, CronofyProfileQueryVariables>(
    CronofyProfileDocument,
    options
  );
}
export type CronofyProfileQueryHookResult = ReturnType<
  typeof useCronofyProfileQuery
>;
export type CronofyProfileLazyQueryHookResult = ReturnType<
  typeof useCronofyProfileLazyQuery
>;
export type CronofyProfileQueryResult = Apollo.QueryResult<
  CronofyProfileQuery,
  CronofyProfileQueryVariables
>;
export const ListFilteredStaffsDocument = gql`
  query ListFilteredStaffs($input: ListStaffsInput) {
    staffs(input: $input) {
      id
      firstName
      lastName
      emailAddress {
        value
        use
      }
      profileImageUrl
      role
    }
  }
`;

/**
 * __useListFilteredStaffsQuery__
 *
 * To run a query within a React component, call `useListFilteredStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFilteredStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFilteredStaffsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListFilteredStaffsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFilteredStaffsQuery,
    ListFilteredStaffsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListFilteredStaffsQuery,
    ListFilteredStaffsQueryVariables
  >(ListFilteredStaffsDocument, options);
}
export function useListFilteredStaffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFilteredStaffsQuery,
    ListFilteredStaffsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListFilteredStaffsQuery,
    ListFilteredStaffsQueryVariables
  >(ListFilteredStaffsDocument, options);
}
export type ListFilteredStaffsQueryHookResult = ReturnType<
  typeof useListFilteredStaffsQuery
>;
export type ListFilteredStaffsLazyQueryHookResult = ReturnType<
  typeof useListFilteredStaffsLazyQuery
>;
export type ListFilteredStaffsQueryResult = Apollo.QueryResult<
  ListFilteredStaffsQuery,
  ListFilteredStaffsQueryVariables
>;
export const ListStaffWithProgramsDocument = gql`
  query ListStaffWithPrograms {
    staffs {
      id
      createdAt
      updatedAt
      firstName
      lastName
      emailAddress {
        value
        use
      }
      profileImageUrl
      role
      status
      programs {
        programId
      }
    }
    programs {
      name
      slug
      id
    }
  }
`;

/**
 * __useListStaffWithProgramsQuery__
 *
 * To run a query within a React component, call `useListStaffWithProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStaffWithProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStaffWithProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListStaffWithProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListStaffWithProgramsQuery,
    ListStaffWithProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListStaffWithProgramsQuery,
    ListStaffWithProgramsQueryVariables
  >(ListStaffWithProgramsDocument, options);
}
export function useListStaffWithProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListStaffWithProgramsQuery,
    ListStaffWithProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListStaffWithProgramsQuery,
    ListStaffWithProgramsQueryVariables
  >(ListStaffWithProgramsDocument, options);
}
export type ListStaffWithProgramsQueryHookResult = ReturnType<
  typeof useListStaffWithProgramsQuery
>;
export type ListStaffWithProgramsLazyQueryHookResult = ReturnType<
  typeof useListStaffWithProgramsLazyQuery
>;
export type ListStaffWithProgramsQueryResult = Apollo.QueryResult<
  ListStaffWithProgramsQuery,
  ListStaffWithProgramsQueryVariables
>;
export const StaffDocument = gql`
  query Staff($staffId: UUID!) {
    staff(id: $staffId) {
      id
      createdAt
      updatedAt
      firstName
      lastName
      bio
      preferredName
      genderIdentity {
        value
        text
      }
      pronouns {
        value
        text
      }
      emailAddress {
        value
        use
      }
      profileImageUrl
      role
      status
      npiNumber
      deaNumber
      programs {
        programId
      }
      professionalLicenses {
        id
        createdAt
        updatedAt
        type
        licenseState
        licenseNumber
        status
        expirationDate
        description
      }
    }
  }
`;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useStaffQuery(
  baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options
  );
}
export function useStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(
    StaffDocument,
    options
  );
}
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = Apollo.QueryResult<
  StaffQuery,
  StaffQueryVariables
>;
export const WhoAmIDocument = gql`
  query WhoAmI {
    staffWhoAmI {
      id
      authId
      firstName
      lastName
      preferredName
      emailAddress {
        value
        use
      }
      profileImageUrl
      role
      status
      programs {
        programId
        program {
          name
          slug
          config {
            roleNameMapping
          }
        }
      }
    }
  }
`;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
  baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options
  );
}
export function useWhoAmILazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options
  );
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<
  WhoAmIQuery,
  WhoAmIQueryVariables
>;
export const TaskTemplatesDocument = gql`
  query TaskTemplates($filters: FilterTaskTemplateParams!) {
    taskTemplates(filters: $filters) {
      id
      name
      programId
      slug
      role
      category
      priority
      description
      relatedObjectType
      relatedObjectId
      resultObjectType
    }
  }
`;

/**
 * __useTaskTemplatesQuery__
 *
 * To run a query within a React component, call `useTaskTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTemplatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTaskTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskTemplatesQuery,
    TaskTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskTemplatesQuery, TaskTemplatesQueryVariables>(
    TaskTemplatesDocument,
    options
  );
}
export function useTaskTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskTemplatesQuery,
    TaskTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskTemplatesQuery, TaskTemplatesQueryVariables>(
    TaskTemplatesDocument,
    options
  );
}
export type TaskTemplatesQueryHookResult = ReturnType<
  typeof useTaskTemplatesQuery
>;
export type TaskTemplatesLazyQueryHookResult = ReturnType<
  typeof useTaskTemplatesLazyQuery
>;
export type TaskTemplatesQueryResult = Apollo.QueryResult<
  TaskTemplatesQuery,
  TaskTemplatesQueryVariables
>;
export const GetTaskDocument = gql`
  query GetTask($id: UUID!) {
    task(id: $id) {
      id
      assigneeId
      assignee {
        ... on Patient {
          id
          firstName
          lastName
          __typename
        }
        ... on Staff {
          id
          firstName
          lastName
          __typename
        }
      }
      patient {
        id
        firstName
        lastName
        preferredName
        birthDate
      }
      program {
        id
      }
      reporterId
      patientId
      comments
      taskTemplateId
      taskTemplate {
        id
        name
      }
      name
      status
      programId
      taskDueAt
      eventDueAt
      taskExpireAt
      role
      taskConfig {
        type
      }
      category
      taskType
      priority
      description
      relatedObjectType
      relatedObjectId
      resultObjectType
      resultObjectId
      createdAt
      updatedAt
      legacyNotificationSchedule {
        schedules {
          sequentialOrder
          scheduledAt
          eventName
        }
      }
    }
  }
`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  );
}
export function useGetTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  );
}
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<
  GetTaskQuery,
  GetTaskQueryVariables
>;
export const TimelineTouchpointsDocument = gql`
  query TimelineTouchpoints(
    $pastTouchpointsFilters: FilterSequentialTouchpointParams!
    $pastTouchpointsPagination: PaginationParams
    $pastTouchpointsSorting: [SortTouchpointParam!]
    $currentTouchpointsFilters: FilterSequentialTouchpointParams!
    $currentTouchpointsPagination: PaginationParams
    $currentTouchpointsSorting: [SortTouchpointParam!]
    $incomingTouchpointsFilters: FilterSequentialTouchpointParams!
    $incomingTouchpointsPagination: PaginationParams
    $incomingTouchpointsSorting: [SortTouchpointParam!]
  ) {
    pastTouchpoints: sequentialTouchpoints(
      filters: $pastTouchpointsFilters
      pagination: $pastTouchpointsPagination
      sorting: $pastTouchpointsSorting
    ) {
      items {
        ...TouchpointDetail
      }
      totalCount
    }
    currentTouchpoints: sequentialTouchpoints(
      filters: $currentTouchpointsFilters
      pagination: $currentTouchpointsPagination
      sorting: $currentTouchpointsSorting
    ) {
      items {
        ...TouchpointDetail
      }
      totalCount
    }
    incomingTouchpoints: sequentialTouchpoints(
      filters: $incomingTouchpointsFilters
      pagination: $incomingTouchpointsPagination
      sorting: $incomingTouchpointsSorting
    ) {
      items {
        ...TouchpointDetail
      }
      totalCount
    }
  }
  ${TouchpointDetailFragmentDoc}
`;

/**
 * __useTimelineTouchpointsQuery__
 *
 * To run a query within a React component, call `useTimelineTouchpointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineTouchpointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineTouchpointsQuery({
 *   variables: {
 *      pastTouchpointsFilters: // value for 'pastTouchpointsFilters'
 *      pastTouchpointsPagination: // value for 'pastTouchpointsPagination'
 *      pastTouchpointsSorting: // value for 'pastTouchpointsSorting'
 *      currentTouchpointsFilters: // value for 'currentTouchpointsFilters'
 *      currentTouchpointsPagination: // value for 'currentTouchpointsPagination'
 *      currentTouchpointsSorting: // value for 'currentTouchpointsSorting'
 *      incomingTouchpointsFilters: // value for 'incomingTouchpointsFilters'
 *      incomingTouchpointsPagination: // value for 'incomingTouchpointsPagination'
 *      incomingTouchpointsSorting: // value for 'incomingTouchpointsSorting'
 *   },
 * });
 */
export function useTimelineTouchpointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TimelineTouchpointsQuery,
    TimelineTouchpointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TimelineTouchpointsQuery,
    TimelineTouchpointsQueryVariables
  >(TimelineTouchpointsDocument, options);
}
export function useTimelineTouchpointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimelineTouchpointsQuery,
    TimelineTouchpointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimelineTouchpointsQuery,
    TimelineTouchpointsQueryVariables
  >(TimelineTouchpointsDocument, options);
}
export type TimelineTouchpointsQueryHookResult = ReturnType<
  typeof useTimelineTouchpointsQuery
>;
export type TimelineTouchpointsLazyQueryHookResult = ReturnType<
  typeof useTimelineTouchpointsLazyQuery
>;
export type TimelineTouchpointsQueryResult = Apollo.QueryResult<
  TimelineTouchpointsQuery,
  TimelineTouchpointsQueryVariables
>;
export const TouchpointsTasksDocument = gql`
  query TouchpointsTasks($filters: FilterSequentialTouchpointParams!) {
    sequentialTouchpoints(
      filters: $filters
      pagination: { pageNumber: 1, pageSize: 999 }
    ) {
      items {
        tasks {
          id
          taskType
          status
          taskDueAt
        }
      }
    }
  }
`;

/**
 * __useTouchpointsTasksQuery__
 *
 * To run a query within a React component, call `useTouchpointsTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTouchpointsTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTouchpointsTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTouchpointsTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    TouchpointsTasksQuery,
    TouchpointsTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TouchpointsTasksQuery, TouchpointsTasksQueryVariables>(
    TouchpointsTasksDocument,
    options
  );
}
export function useTouchpointsTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TouchpointsTasksQuery,
    TouchpointsTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TouchpointsTasksQuery,
    TouchpointsTasksQueryVariables
  >(TouchpointsTasksDocument, options);
}
export type TouchpointsTasksQueryHookResult = ReturnType<
  typeof useTouchpointsTasksQuery
>;
export type TouchpointsTasksLazyQueryHookResult = ReturnType<
  typeof useTouchpointsTasksLazyQuery
>;
export type TouchpointsTasksQueryResult = Apollo.QueryResult<
  TouchpointsTasksQuery,
  TouchpointsTasksQueryVariables
>;
export const EncounterDetailsDocument = gql`
  query EncounterDetails($encounterId: UUID!) {
    encounter(id: $encounterId) {
      ...EncounterDetail
    }
  }
  ${EncounterDetailFragmentDoc}
`;

/**
 * __useEncounterDetailsQuery__
 *
 * To run a query within a React component, call `useEncounterDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncounterDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncounterDetailsQuery({
 *   variables: {
 *      encounterId: // value for 'encounterId'
 *   },
 * });
 */
export function useEncounterDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EncounterDetailsQuery,
    EncounterDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EncounterDetailsQuery, EncounterDetailsQueryVariables>(
    EncounterDetailsDocument,
    options
  );
}
export function useEncounterDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EncounterDetailsQuery,
    EncounterDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EncounterDetailsQuery,
    EncounterDetailsQueryVariables
  >(EncounterDetailsDocument, options);
}
export type EncounterDetailsQueryHookResult = ReturnType<
  typeof useEncounterDetailsQuery
>;
export type EncounterDetailsLazyQueryHookResult = ReturnType<
  typeof useEncounterDetailsLazyQuery
>;
export type EncounterDetailsQueryResult = Apollo.QueryResult<
  EncounterDetailsQuery,
  EncounterDetailsQueryVariables
>;
export const EncountersIndexDocument = gql`
  query EncountersIndex($patientId: UUID!, $programId: UUID!) {
    encounters(filters: { patientId: $patientId, programId: $programId }) {
      ...EncounterIndexItem
    }
  }
  ${EncounterIndexItemFragmentDoc}
`;

/**
 * __useEncountersIndexQuery__
 *
 * To run a query within a React component, call `useEncountersIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncountersIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncountersIndexQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEncountersIndexQuery(
  baseOptions: Apollo.QueryHookOptions<
    EncountersIndexQuery,
    EncountersIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EncountersIndexQuery, EncountersIndexQueryVariables>(
    EncountersIndexDocument,
    options
  );
}
export function useEncountersIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EncountersIndexQuery,
    EncountersIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EncountersIndexQuery,
    EncountersIndexQueryVariables
  >(EncountersIndexDocument, options);
}
export type EncountersIndexQueryHookResult = ReturnType<
  typeof useEncountersIndexQuery
>;
export type EncountersIndexLazyQueryHookResult = ReturnType<
  typeof useEncountersIndexLazyQuery
>;
export type EncountersIndexQueryResult = Apollo.QueryResult<
  EncountersIndexQuery,
  EncountersIndexQueryVariables
>;
export const UpcomingVisitsDocument = gql`
  query UpcomingVisits($patientId: UUID!, $programId: UUID!) {
    appointments(
      input: { patientId: $patientId, programId: $programId, status: [BOOKED] }
    ) {
      ...VisitSummaryWithEncounter
    }
  }
  ${VisitSummaryWithEncounterFragmentDoc}
`;

/**
 * __useUpcomingVisitsQuery__
 *
 * To run a query within a React component, call `useUpcomingVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingVisitsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useUpcomingVisitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpcomingVisitsQuery,
    UpcomingVisitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpcomingVisitsQuery, UpcomingVisitsQueryVariables>(
    UpcomingVisitsDocument,
    options
  );
}
export function useUpcomingVisitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingVisitsQuery,
    UpcomingVisitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpcomingVisitsQuery, UpcomingVisitsQueryVariables>(
    UpcomingVisitsDocument,
    options
  );
}
export type UpcomingVisitsQueryHookResult = ReturnType<
  typeof useUpcomingVisitsQuery
>;
export type UpcomingVisitsLazyQueryHookResult = ReturnType<
  typeof useUpcomingVisitsLazyQuery
>;
export type UpcomingVisitsQueryResult = Apollo.QueryResult<
  UpcomingVisitsQuery,
  UpcomingVisitsQueryVariables
>;
